import {
  FormControl,
  InputBase,
  InputLabel,
  alpha,
  styled,
  Paper,
  Button,
} from "@mui/material";

export const StyledFormControl = styled(FormControl)`
 
`;

export const StyledPaper = styled(Paper)`
margin: 20px;
`;

export const StyledFormInputLabel = styled(InputLabel)({
  fontFamily: "Roboto",
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "28px",
  color: "#3A3A3A",
});

export const StyledFormButton = styled(Button)`
  margin-right: 20px; 
  width: 250px; 
  margin: 20px;
`

export const StyledFormInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    margin: "22px 0 14px",
  },
  "& .MuiInputBase-input": {
    borderRadius: 12,
    position: "relative",

    border: "1px solid",

    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      borderRadius: 12
    },
    "&:hover": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      borderRadius: 12
    },
  },
}));
