import React, { useEffect, useMemo, useState } from "react";
import { Paper, Divider } from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  stop_base_url,
  schedule_base_url,
  route_base_url,
  vehicle_base_url,
} from "../utils";
import axios from "axios";
import StopScheduleFilter from "./StopScheduleFilter";
import StopSchedulesSearch from "./StopSchedulesSearch";
import StopSchedulesTable from "./StopSchedulesTable";
import { ConstructionOutlined } from "@mui/icons-material";
import DashboardHeader from "../../../components/header/DashboardHeader";
import config from "../../../config.json";

// Main component for Stop Schedule Details
function StopScheduleDetails() {
  const [stopSchedulesData, setStopSchedulesData] = useState([]);
  const [updatedSchedules, setUpdatedSchedules] = useState([]);
  const [routesData, setRoutesData] = useState([]);
  const [vehiclesData, setVehiclesData] = useState([]);
  const [searchSchedules, setSearchSchedules] = useState("");
  const [filterSchedules, setFilterSchedules] = useState({
    repitation: "",
    vehicles: [],
    routes: "",
  });

  const location = useLocation();
  const { id } = location.state || {};

  useEffect(() => {
    const fetchStopSchedules = async () => {
      if (!id) return;
      try {
        const response = await axios.get(`${stop_base_url}/id`, {
          params: { id },
        });
        setStopSchedulesData(response.data);
      } catch (error) {
        console.error("Error fetching stop schedules:", error);
      }
    };

    fetchStopSchedules();
  }, [id]);

  useEffect(() => {
    const fetchAllData = async () => {
      if (!stopSchedulesData || !stopSchedulesData.stopSchedule) return;

      const scheduleIds = stopSchedulesData.stopSchedule.map(
        (stop) => stop.scheduleId
      );

      const fetchScheduleData = async (scheduleId) => {
        const scheduleRes = await axios.get(`${config.baseURL}/schedule/id`, {
          params: { scheduleId },
          withCredentials: true,
        });
        const routeRes = await axios.get(`${route_base_url}/id`, {
          params: { id: scheduleRes.data.routeId },
          withCredentials: true,
        });

        const vehiclePromises = scheduleRes.data.vehicleSchedule.map(
          (scheduleVehicle) =>
            axios.get(`${vehicle_base_url}/id`, {
              params: {
                vehicleId: scheduleVehicle.vehicleId,
                organizationId: 17,
              },
              withCredentials: true,
            })
        );
        const vehicleResponses = await Promise.all(vehiclePromises);
        const vehicles = vehicleResponses.map((res) => res.data.vehicle);

        return enhanceStopSchedules(
          stopSchedulesData.stopSchedule.find(
            (stop) => stop.scheduleId === scheduleId
          ),
          scheduleRes.data,
          routeRes.data,
          vehicles
        );
      };

      const enhancedDataPromises = scheduleIds.map(fetchScheduleData);
      const enhancedData = await Promise.all(enhancedDataPromises);

      setUpdatedSchedules(enhancedData.flat());
    };

    if (stopSchedulesData) {
      fetchAllData();
    }
  }, [stopSchedulesData]);

  const enhanceStopSchedules = (
    stopData,
    scheduleData,
    routeData,
    vehicleData
  ) => {
    return [
      {
        ...stopData,
        vehicles: vehicleData.map((vehicle) => vehicle.name).join(", "),
        repitation: scheduleData.repitation,
        routes: routeData.name,
      },
    ];
  };

  useEffect(() => {
    const fetchRoutesAndVehicles = async () => {
      try {
        const [routesRes, vehiclesRes] = await Promise.all([
          axios.get(route_base_url),
          axios.get(vehicle_base_url),
        ]);
        setRoutesData(routesRes.data);
        setVehiclesData(vehiclesRes.data.vehicles);
      } catch (error) {
        console.error("Error fetching routes or vehicles:", error);
      }
    };

    fetchRoutesAndVehicles();
  }, []);
  // Compute filtered stops data based on search input and selected filters
  const filteredSchedulesData = useMemo(() => {
    console.log("Updated schedules data:", updatedSchedules);
    // Filter by search input
    const searchFilteredData = updatedSchedules.filter((schedule) => {
      console.log("Schedule:", schedule);
      return (
        schedule.vehicles
          .toString()
          .toLowerCase()
          .includes(searchSchedules.toLowerCase()) ||
        schedule.routes.toLowerCase().includes(searchSchedules.toLowerCase()) ||
        schedule.repitation
          .toLowerCase()
          .includes(searchSchedules.toLowerCase())
      );
    });
    console.log("Search filtered schedules data:", searchFilteredData);
    // Filter by selected stop name
    const selectedRepetition = filterSchedules.repitation
      ? updatedSchedules.filter(
          (schedule) => schedule.repitation === filterSchedules.repitation
        )
      : searchFilteredData;

    // Filter by selected route name
    const selectedVehicle = filterSchedules.vehicles
      ? updatedSchedules.filter(
          (schedule) => schedule.vehicles.includes(filterSchedules.vehicles)
        )
      : selectedRepetition;
    const selectedRoute = filterSchedules.routes
      ? updatedSchedules.filter(
          (schedule) => schedule.routes === filterSchedules.routes
        )
      : selectedVehicle;

    console.log("Filtered schedules data:", selectedRoute);
    return selectedRoute;
  }, [updatedSchedules, searchSchedules, filterSchedules]);

  //onChange for search Input change
  const handleSearchChange = (event) => {
    const value = event.target.value;
    console.log("Search schedules:", value);
    setSearchSchedules(value);
  };

  // onChange for repetiton select filter
  const handleRepetitionChange = (event) => {
    const selectedRepitation = event.target.value;
    console.log("selected repitation", selectedRepitation);
    setFilterSchedules({ ...filterSchedules, repitation: selectedRepitation });
  };

  // onChange for vehicle select filter
  const handleVehicleChange = (event) => {
    const selectedVehicle = event.target.value;
    console.log("Selected vehicle:", selectedVehicle);
    setFilterSchedules({ ...filterSchedules, vehicles: selectedVehicle });
  };

  // onChange for route select filter
  const handleRouteChange = (event) => {
    const selectedRoute = event.target.value;
    console.log("Selected route:", selectedRoute);
    setFilterSchedules({ ...filterSchedules, routes: selectedRoute });
  };

  return (
    <>
      <DashboardHeader title="Stop Schedule Details" />
      <Paper style={{ margin: "20px", height: "100%" }}>
        <StopsSchedulesHeader
          stopId={stopSchedulesData.stopId}
          stopName={stopSchedulesData.name}
        />
        <Divider />
        <div className="filter_search_wrapper">
          <StopSchedulesSearch handleSearchChange={handleSearchChange} />
          <StopScheduleFilter
            handleRepetitionChange={handleRepetitionChange}
            handleVehicleChange={handleVehicleChange}
            filterSchedules={filterSchedules}
            routesData={routesData}
            vehiclesData={vehiclesData}
            handleRouteChange={handleRouteChange}
          />
        </div>
        <StopSchedulesTable
          filteredSchedulesData={filteredSchedulesData}
          updatedSchedules={updatedSchedules}
        />
      </Paper>
    </>
  );
}

const StopsSchedulesHeader = ({ stopName, stopId }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "15px",
        paddingBottom: "20px",
        fontSize: "17px",
      }}
    >
      <div>
        <span style={{ paddingLeft: "22px", fontWeight: "bold" }}>
          Stop Name :{" "}
        </span>
        <span>{stopName}</span>
      </div>
      <div style={{ paddingRight: "20px" }}>
        <span style={{ paddingRight: "10px", fontWeight: "bold" }}>
          Stop ID :
        </span>
        <span>{stopId}</span>
      </div>
    </div>
  );
};

export default StopScheduleDetails;
