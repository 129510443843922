import React, { useState, useCallback, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "50vh",
};

const center = { lat: 13.1986, lng: 77.7066 }; // Default center (near Bangalore)

const libraries = ["places"];

function Map({ stops, routes }) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyD7rqUpTzUpEbxm-Xc7ikltFJGzOhd92Qk",
    libraries,
  });

  const [map, setMap] = useState(null);
  const [directions, setDirections] = useState([]);

  const onLoad = useCallback(
    (map) => {
      const bounds = new window.google.maps.LatLngBounds();
      stops.forEach((stop) => {
        bounds.extend({
          lat: parseFloat(stop.latitude),
          lng: parseFloat(stop.longitude),
        });
      });
      map.fitBounds(bounds);
      setMap(map);
    },
    [stops]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (isLoaded && routes.length > 0 && map) {
      const directionsService = new window.google.maps.DirectionsService();

      const fetchDirections = async () => {
        const directionsPromises = routes.map((route) => {
          return new Promise((resolve, reject) => {
            const waypoints = route.routeCoordinates
              .slice(1, -1)
              .map((coord) => ({
                location: new window.google.maps.LatLng(
                  parseFloat(coord.latitude),
                  parseFloat(coord.longitude)
                ),
                stopover: true,
              }));

            const origin = new window.google.maps.LatLng(
              parseFloat(route.routeCoordinates[0].latitude),
              parseFloat(route.routeCoordinates[0].longitude)
            );
            const destination = new window.google.maps.LatLng(
              parseFloat(
                route.routeCoordinates[route.routeCoordinates.length - 1]
                  .latitude
              ),
              parseFloat(
                route.routeCoordinates[route.routeCoordinates.length - 1]
                  .longitude
              )
            );

            directionsService.route(
              {
                origin: origin,
                destination: destination,
                waypoints: waypoints,
                travelMode: window.google.maps.TravelMode.DRIVING,
              },
              (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                  resolve(result);
                } else {
                  reject(`error fetching directions ${result}`);
                }
              }
            );
          });
        });

        try {
          const results = await Promise.all(directionsPromises);
          setDirections(results);
        } catch (error) {
          console.error(error);
        }
      };

      fetchDirections();
    }
  }, [isLoaded, routes, map]);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {stops.map((stop) => (
        <Marker
          key={stop.id}
          position={{
            lat: parseFloat(stop.latitude),
            lng: parseFloat(stop.longitude),
          }}
          title={stop.name}
        />
      ))}

      {directions.map((direction, index) => (
        <DirectionsRenderer
          key={index}
          directions={direction}
          options={{
            suppressMarkers: true,
            polylineOptions: {
              strokeColor: getRandomColor(),
              strokeOpacity: 0.8,
              strokeWeight: 4,
            },
          }}
        />
      ))}
    </GoogleMap>
  );
}

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export default React.memo(Map);
