function sarchInTheTable(sarchStr, tableData) {
  console.log(sarchStr, tableData);
  return tableData.filter(item => {
    for (let key in item) {
      console.log(key, item[key]);
      if (item[key]?.toString().toLowerCase().includes(sarchStr.toLowerCase())) {
        return true;
      }
    }
    return false;
  });
}

export default sarchInTheTable;