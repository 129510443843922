import { Box, Grid, Paper } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import SearchStops from "./SearchStops";
import "./stops.css";
import StopsFilter from "./StopsFilter";
import axios from "axios";
import StopsTable from "./StopsTable";
import AddUpdateStops from "./AddUpdateStops/AddUpdateStops";
import DashboardHeader from "../../components/header/DashboardHeader";
import config from "../../config.json";
import { useNavigate } from "react-router";
import { usePermission } from "../../hooks/usePermission";

const Stops = () => {
  const [stopsData, setStopsData] = useState([]); 
  const [searchInput, setSearchInput] = useState("");
  const [isAddUpdateDialogOpen, setIsAddUpdateDialogOpen] = useState(false);
  const [routesData, setRoutesData] = useState([]);
  const [routesByCoordinates, setRoutesByCoordinates] = useState([]);
  const [updatedStopsData, setUpdatedStopsData] = useState([]);
  const [selectedRouteFilter, setSelectedRouteFilter] = useState({ name: "" });
  const [selectedStopForUpdate, setSelectedStopForUpdate] = useState(null);
  const [selectedStopNameFilter, setSelectedStopNameFilter] = useState({ name: "" });
  const [isLoading, setIsLoading] = useState(true);
  
  const navigate = useNavigate();
  const { hasPermission } = usePermission();

  // Fetch routes by coordinates for a single stop
  const getRouteByStopCoordinates = async (lat, lng, orgId) => {
    try {
      const response = await axios.get(`${config.baseURL}/route/coordinates/`, {
        params: {
          longitude: lng,
          latitude: lat,
          organizationId: orgId,
        }
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching routes by coordinates:", error);
      return [];
    }
  };

  // Initial data fetching
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsLoading(true);
        
        // Fetch stops
        const stopsResponse = await axios.get(
          `${config.baseURL}/stop/organization`, 
          { withCredentials: true }
        );
        const stops = stopsResponse.data;

        // Fetch routes for each stop
        const stopsWithRoutes = await Promise.all(
          stops.map(async (stop) => {
            const routes = await getRouteByStopCoordinates(
              stop.latitude,
              stop.longitude,
              stop.organizationId
            );
            return { ...stop, routes };
          })
        );

        setStopsData(stopsWithRoutes);
        
        // Update stops data with route names
        const updatedStops = stopsWithRoutes.map(stop => ({
          ...stop,
          routeName: stop.routes?.[0]?.name || ""
        }));
        
        setUpdatedStopsData(updatedStops);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching initial data:", error);
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []); // Empty dependency array - runs once on mount

  // Filtered data computation
  const filteredStopsData = useMemo(() => {
    let filteredData = updatedStopsData;

    // Search filter
    if (searchInput) {
      filteredData = filteredData.filter(stop => 
        stop.stopId.toLowerCase().includes(searchInput.toLowerCase()) ||
        stop.name.toLowerCase().includes(searchInput.toLowerCase()) ||
        stop.routeName.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    // Stop name filter
    if (selectedStopNameFilter.name && selectedStopNameFilter.name !== "All") {
      filteredData = filteredData.filter(stop => 
        stop.name === selectedStopNameFilter.name
      );
    }

    // Route filter
    if (selectedRouteFilter.name && selectedRouteFilter.name !== "All") {
      filteredData = filteredData.filter(stop => 
        stop.routeName === selectedRouteFilter.name
      );
    }

    return filteredData;
  }, [updatedStopsData, searchInput, selectedStopNameFilter, selectedRouteFilter]);

  // Event handlers
  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleRouteSelectChange = (event) => {
    setSelectedRouteFilter({ name: event.target.value });
  };

  const handleStopNameSelectChange = (event) => {
    setSelectedStopNameFilter({ name: event.target.value });
  };

  const handleAddUpdateDialogOpen = () => {
    navigate("/stop/add");
  };

  const handleAddUpdateDialogClose = () => {
    setIsAddUpdateDialogOpen(false);
  };

  const handleSelectedStopChange = (data) => {
    setSelectedStopForUpdate(data);
  };

  if (isLoading) {
    return <div>Loading...</div>; // Add proper loading component
  }

  return (
    <>
      <DashboardHeader title="Stops" />
      <Paper style={{ marginLeft: "32px", marginRight: "32px" }}>
        <StopsHeader
          stopCount={stopsData.length}
          onAddUpdateDialogOpen={handleAddUpdateDialogOpen}
          updatedStopsData={updatedStopsData}
          navigate={navigate}
        />

        <div className="search-filter-wrapper">
          <SearchStops onSearchChange={handleSearchChange} />
          <StopsFilter
            stopsData={stopsData}
            routesData={routesData}
            selectedRouteFilter={selectedRouteFilter}
            selectedStopNameFilter={selectedStopNameFilter}
            onRouteSelectChange={handleRouteSelectChange}
            onStopNameSelectChange={handleStopNameSelectChange}
          />
        </div>

        <StopsTable
          stopsData={stopsData}
          setStopsData={setStopsData}
          filteredStopsData={filteredStopsData}
          onAddUpdateDialogOpen={handleAddUpdateDialogOpen}
          onSelectedStopDataChange={handleSelectedStopChange}
          routesByCoordinates={routesByCoordinates}
          updatedStopsData={updatedStopsData}
          searchInput={searchInput}
          selectedRouteFilter={selectedRouteFilter}
        />

        <AddUpdateStops
          open={isAddUpdateDialogOpen}
          onClose={handleAddUpdateDialogClose}
          selectedStopForUpdate={selectedStopForUpdate}
          routesData={routesData}
        />
      </Paper>
    </>
  );
};

const StopsHeader = ({
  stopCount,
  onAddUpdateDialogOpen,
  updatedStopsData,
  navigate,
}) => {
  const { hasPermission } = usePermission();

  return (
    <>
      <div className="stop-header">
        <div>
          {/* <div className="stop-para">Manage All Stops in One Place.</div> */}
          {/* <div>
          <span className="stop-count">{stopCount} Stops</span>
        </div> */}
        </div>
        <div className="addbutton_container">
          <button
            variant="contained"
            className="customButton_add"
            style={{
              backgroundColor: "rgba(32, 168, 224, 1)",
              color: "rgba(255, 255, 255, 1)",
              marginLeft: "320px",
            }}
            sx={{ border: "2px solid black", fontWeight: "45px" }}
            onClick={() =>
              navigate("/stop/all/map", {
                state: { allStopsData: updatedStopsData },
              })
            }
          >
            {" "}
            View All Stops On Map
          </button>
          {hasPermission("/api/stop", "POST") && (
            <button
              variant="contained"
              className="customButton_add"
              style={{
                background: "rgba(249, 231, 25, 1)",
                color: "rgba(31, 36, 46, 1)",
              }}
              onClick={onAddUpdateDialogOpen}
            >
              + Add Stop
            </button>
          )}
        </div>
      </div>
      <Box className="Headercard" sx={{ marginTop: "16px" }}>
        <Grid container spacing={3}>
          <Grid item xs>
            <Box className="Headercard_Total">
              <div className="Headercard_Total_text">
                <div className="org-para">
                  Total <br />
                  Stops
                </div>
                <div>
                  <span className="org-count">{stopCount}</span>
                </div>
              </div>
            </Box>
            <Box className="Headercard">
              <Grid container spacing={3}>
                <Grid item xs>
                  <Box className="Headercard_Total">
                    <div className="Headercard_Total_text">
                      <div className="org-para">
                        Total <br />
                        Stops
                      </div>
                      <div>
                        <span className="org-count">{stopCount}</span>
                      </div>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs>
                  <Box className="Headercard_Active">
                    <div className="Headercard_Total_text">
                      <div className="org-para">
                        Connected <br />
                        Routes
                      </div>
                      <div>
                        <span className="org-count">{stopCount}</span>
                      </div>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Stops;
