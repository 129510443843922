import React, { useCallback, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material/styles";
import Pagination from "../../components/pagination/Pagination";
import "./role.css";
import { Button, Checkbox, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";

const columns = [
  { label: "Access Name", id: "Access Name" },
  { label: "Description", id: "Description" },
  { label: "Assign", id: "routeName" },
];

function RoleAccessTable({
  getAllTheAvailableFeatures,
  selectedRoleData,
  assignFeatureToTheRole,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [availableFeatures, setAvailableFeatures] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const styles = {
    header: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      fontSize: "20px",
      fontWeight: "500",
    },
    descriptionCell: { paddingLeft: theme.spacing(2) },
  };

  useEffect(() => {
    getAllTheAvailableFeatures()
      .then((data) => {
        const featuresWithSelection = data.map((feature) => ({
          ...feature,
          selected: selectedRoleData.features.some(
            (f) => f.feature.id === feature.id
          ),
        }));
        setAvailableFeatures(featuresWithSelection);
      })
      .catch((error) => console.error("Error fetching features:", error));
  }, [getAllTheAvailableFeatures, selectedRoleData]);

  const totalCount = availableFeatures.length;
  const totalPages = Math.ceil(totalCount / itemsPerPage) || 1;

  const getCurrentPageFeatures = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return availableFeatures.slice(startIndex, endIndex);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const toggleFeatureSelection = (id) => {
    setAvailableFeatures((prevFeatures) =>
      prevFeatures.map((feature) =>
        feature.id === id
          ? { ...feature, selected: !feature.selected }
          : feature
      )
    );
  };

  const resetAllCheckboxes = () => {
    setAvailableFeatures((prevFeatures) =>
      prevFeatures.map((feature) => ({ ...feature, selected: false }))
    );
  };

  const assignFeatures = useCallback(async () => {
    setLoading(true);
    setError(null);
    const selectedFeatureIds = availableFeatures
      .filter((feature) => feature.selected)
      .map((feature) => feature.id);
    const roleId = selectedRoleData.id;
    try {
      const result = await assignFeatureToTheRole(roleId, selectedFeatureIds);
      console.log("Feature assigned to role successfully:", result);
      navigate("/role");
    } catch (error) {
      console.error("Error assigning feature to role:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [
    availableFeatures,
    selectedRoleData.id,
    assignFeatureToTheRole,
    navigate,
  ]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell align="left" style={styles.header} key={column.id}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ padding: "0px" }} className="TableBody-root">
            {getCurrentPageFeatures().map((feature) => (
              <TableRow
                className="MuiTableRow-root"
                style={{ color: "black" }}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                key={feature.id}
              >
                <TableCell align="justify">{feature.name}</TableCell>
                <TableCell align="left" style={styles.descriptionCell}>
                  {feature.description}
                </TableCell>
                <TableCell align="justify">
                  <Checkbox
                    checked={feature.selected}
                    onChange={() => toggleFeatureSelection(feature.id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      <Divider style={{ paddingTop: "15px" }} />
      <div
        style={{
          marginTop: "24px",
          textAlign: "right",
          marginRight: "40px",
          gap: "16px",
          paddingBottom: "24px",
        }}
      >
        <Button
          className="cancel_btn"
          color="primary"
          onClick={resetAllCheckboxes}
          disabled={loading}
          style={{
            marginRight: "50px",
            width: "229px",
            height: "70px",
            border: "1px solid black",
            fontSize: "27px",
            textTransform: "none",
            background: "#F2F2F3",
            borderRadius: "16px",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className="save_btn"
          onClick={assignFeatures}
          disabled={loading}
          color="primary"
          style={{
            marginRight: "50px",
            width: "229px",
            height: "70px",
            textTransform: "none",
            fontSize: "27px",
            background: "#F9E719",
            borderRadius: "16px",
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
}

export default RoleAccessTable;
