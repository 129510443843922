import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config.json";
import { useNavigate, useParams } from "react-router";
import DashboardHeader from "../../components/header/DashboardHeader";
import StarIcon from "../../components/starIcon/StarIcon";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { Navigate } from "react-router";
import { width } from "@fortawesome/free-brands-svg-icons/fa42Group";
import { usePermission } from "../../hooks/usePermission";

// import { makeStyles } from '@mui/styles';

let currentUser;

/**
 * Fetches user data by user ID.
 *
 * This function sends an HTTP GET request to retrieve user information
 * from the specified API endpoint using the provided user ID. The request
 * includes credentials (cookies, authorization headers, etc.) and uses
 * Axios for making the HTTP request.
 *
 * @async
 * @function getUserById
 * @param {number|string} id - The ID of the user to fetch.
 * @returns {Promise<Object>} A promise that resolves to the user data object.
 * @throws Will throw an error if the request fails.
 *
 * @example
 * // Example usage of getUserById function
 * getUserById(1)
 *   .then(userData => {
 *     console.log(userData);
 *   })
 *   .catch(error => {
 *     console.error("Error fetching user data:", error);
 *   });
 */
async function getUserById(id) {
  try {
    const response = await axios.get(`${config.baseURL}/user/${id}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.log("Error in fetching user data with id", error);
    throw error;
  }
}

/**
 * Adds a new user.
 *
 * This function sends an HTTP POST request to create a new user
 * with the provided user data. The request includes credentials
 * (cookies, authorization headers, etc.) and uses Axios for making
 * the HTTP request.
 *
 * @async
 * @function addUser
 * @param {Object} user - The user data to create a new user.
 * @param {string} user.name - The name of the user.
 * @param {string} user.email - The email of the user.
 * @param {string} user.password - The password of the user.
 * @param {string} user.userName - The username of the user.
 * @param {number} user.organizationId - The ID of the organization the user belongs to.
 * @param {number} user.roleId - The ID of the role assigned to the user.
 * @returns {Promise<Object>} A promise that resolves to the newly created user data object.
 * @throws Will throw an error if the request fails.
 *
 * @example
 * // Example usage of addUser function
 * addUser({
 *   name: "champu",
 *   email: "champu@gmail.com",
 *   password: "123",
 *   userName: "champu",
 *   organizationId: 1,
 *   roleId: 1
 * }).then(response => {
 *   console.log(response);
 * }).catch(err => console.log(err));
 */
async function addUser(user) {
  try {
    const response = await axios.post(`${config.baseURL}/user/`, user, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("error creating the user: ", error);
    throw error;
  }
}
/**
 * Updates user information.
 *
 * This function sends an HTTP PUT request to update user information
 * with the provided user data. The request includes credentials
 * (cookies, authorization headers, etc.) and uses Axios for making
 * the HTTP request.
 *
 * @async
 * @function updateUser
 * @param {Object} user - The user data to update.
 * @param {number} user.userId - The ID of the user to update.
 * @param {string} [user.name] - The updated name of the user (optional).
 * @param {string} [user.email] - The updated email of the user (optional).
 * @param {number} [user.roleId] - The updated ID of the role assigned to the user (optional).
 * @returns {Promise<Object>} A promise that resolves to the updated user data object.
 * @throws Will throw an error if the request fails.
 *
 * @example
 * // Example usage of updateUser function
 * updateUser({
 *   userId: 4,
 *   name: "allu",
 *   email: "allu@gmail.com",
 *   roleId: 1
 * }).then(response => {
 *   console.log(response);
 * }).catch(err => console.log(err));
 */
async function updateUser(user) {
  try {
    const response = await axios.put(`${config.baseURL}/user/`, user, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error in updating user", error);
    throw error;
  }
}

async function updatePassword(id, confirmPass) {
  try {
    const response = await axios.patch(
      `${config.baseURL}/user/password`,
      {
        userId: id,
        newPassword: confirmPass,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
const AddUser = () => {
  // const params = useParams();
  // console.log('Params:', params); // Add this line
  const location = useLocation();
  const { id } = location.state || {};
  const Navigate = useNavigate();

  // const { Id } = params;
  const [userData, setUserData] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  // State variables to track visibility of password fields
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);
  const [errors, setErrors] = useState({});

  const { hasPermission } = usePermission();

  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    password: "",
    userName: "",
    lastName: "",
    // roleId: ""
  });

  const [pass, setPass] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  // useEffect(() => {
  //   console.log('ID:', Id); // Add this line
  //   if (Id) {
  //     getUserById(Id)
  //       .then((response) => {
  //         console.log(response);
  //         currentUser = response.user;
  //         setUserData(currentUser);
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // }, [Id]);
  // addUser({
  //   "name": "champu",
  //   "email": "champu@gmail.com",
  //   "password": "123",
  //   "userName": "champu",
  //   "organizationId": 1,
  //   "roleId": 1
  // }).then(response => {
  //   console.log(response)
  // }).catch(err => console.log(err));

  // updateUser({
  //   userId: 4,
  //   name:"allu",
  //   email:"allu@gmail.com",
  //   roleId:1
  // }).then(response => {
  //   console.log(response);
  // }).catch(err => console.log(err))

  const gridContainerStyle = {
    width: "70%",
    paddingTop: "20px",
    marginLeft: "5%",
    // marginTop:"30px"
  };
  const selectStyles = {
    borderRadius: "16px",
    height: "60px",
    marginTop: "5px",
    // paddingTop:"15px",
    // border:"2px solid black",
  };
  const togglePasswordFields = () => {
    setShowPasswordFields(!showPasswordFields);
  };
  useEffect(() => {
    if (id) {
      console.log("user id", id);
      setUpdateMode(true);
      getUserById(id)
        .then((response) => {
          if (response && response.user) {
            setUserData(response.user);
            console.log(response);
            console.log("userData", userData);
            // Split name into first name and last name
            const nameParts = response.user.name.split(" ");
            setFormData({
              firstName: nameParts[0] || "", // First name
              lastName: nameParts.slice(1).join(" ") || "", // Last name
              email: response.user.email,
              userName: response.user.userName,
              roleId: response.user.roleId,
            });
          }
        })
        .catch((error) => console.error("Error fetching user data:", error));
    }
  }, []);

  useEffect(() => {
    axios
      .get("http://localhost:3000/api/role/organization", {
        withCredentials: true,
      })
      .then((res) => {
        console.log("all roles data", res.data.roles);
        setAllRoles(res.data.roles);
      })
      .catch((error) => {
        console.log("errorin fetching roles", error);
      });
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handlePassChange = (e) => {
    const { name, value } = e.target;
    setPass((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRoleChange = (e) => {
    const selectedRoleName = e.target.value;
    console.log("Selected Role Name:", selectedRoleName);
    const selectedRoleObject = allRoles.find(
      (role) => role.name === selectedRoleName
    );
    console.log("Selected Role Object:", selectedRoleObject);
    if (selectedRoleObject) {
      setFormData((prevData) => ({
        ...prevData,
        roleId: selectedRoleObject.id,
      }));
      console.log("Selected Role ID:", selectedRoleObject.id);
    } else {
      console.error("Role not found for name:", selectedRoleName);
    }
  };
  // Concatenate first name and last name here
  const fullName =
    formData.firstName + (formData.lastName ? " " + formData.lastName : "");

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log(id);
    const errors = {};
    if (id) {
      // setFormData({
      //   firstName: userData.name,
      //   email: userData.email,
      //   userName: userData.userName,
      //   roleId: userData.roleId // Assuming roleId is also available in the user data
      // });

      const updateUserData = {
        userId: userData.id,
        name: fullName,
        email: formData.email,
        roleId: formData.roleId,
      };
      const updateUserResponse = await updateUser(updateUserData);
      console.log("user has been updated successfully", updateUserResponse);
      if (
        pass.confirmPassword !== "" &&
        pass.newPassword !== "" &&
        pass.confirmPassword === pass.newPassword
      ) {
        const resp = await updatePassword(id, pass.confirmPassword);
        if (resp) {
          console.log("password has been updated successfully", resp);
          console.log(resp.data);
        }
      }
      setFormData({
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        password: "",
        roleId: "", // Reset roleId if necessary
      });
      Navigate("/Users");
    } else {
      const newUser = {
        name: fullName,
        email: formData.email,
        password: formData.password,
        userName: formData.userName,
        organizationId: 1,
        roleId: formData.roleId,
      };
      try {
        const userResponse = await addUser(newUser);
        console.log("added user Data", userResponse);
        console.log("form data", formData);
        console.log("user created usccessfully");
        // Clear the form fields after successfully adding the user
        setFormData({
          firstName: "",
          lastName: "",
          userName: "",
          email: "",
          password: "",
          roleId: "", // Reset roleId if necessary
        });
        Navigate("/Users");
      } catch (error) {
        console.log("error in adding the user", error);
      }
    }
  };
  const handleCancel = () => {
    // Clear form fields

    setFormData({
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      roleId: "",
    });
    Navigate("/Users");
  };
  const findRoleNameById = (roleId) => {
    const role = allRoles.find((role) => role.id === roleId);
    return role ? role.name : "";
  };
  // const selectLabel = userData && userData.roleId ? findRoleNameById(userData.roleId) : "Select Role";

  // const selectLabel = updateMode ? userData.user.role : "Select Role";

  return (
    <>
      <DashboardHeader title="Users > Update" />
      <Paper style={{ margin: "20px", borderRadius: "20px" }}>
        <Box component="form" autoComplete="off">
          <div className="form_header">
            {" "}
            <div className="org-form-heading form_title">
              {updateMode ? (
                <h1 className="add_user_heading">
                  Update User Details : {userData.userName}
                </h1>
              ) : (
                <h1 className="add_user_heading">Add User</h1>
              )}
            </div>
          </div>

          <div className="form-container">
            <Grid item xs={12} sm={6}>
              <InputLabel
                htmlFor="First Name"
                sx={{
                  fontWeight: "400",
                  size: "24px",
                  paddingBottom: "8px",
                  color: "black",
                }}
              >
                First Name
                <StarIcon />
              </InputLabel>
              <TextField
                placeholder="Type First Name Here"
                name="firstName"
                type="text"
                value={formData.firstName}
                onChange={handleChange}
                className="user_input_fields"
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "16px",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderWidth: "2px",
                      borderColor: "rgba(24, 48, 89, 1)",
                    },
                  },
                }}
              />
            </Grid>
            {/* </div> */}
            <Grid item xs={12} sm={6}>
              <InputLabel
                htmlFor="lastName"
                sx={{
                  fontWeight: "400",
                  size: "24px",
                  paddingBottom: "8px",
                  color: "black",
                }}
              >
                Last Name
                <StarIcon />
              </InputLabel>
              <TextField
                placeholder="Type Last Name Here"
                name="lastName"
                type="text"
                value={formData.lastName}
                onChange={handleChange}
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "16px",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderWidth: "2px",
                      borderColor: "#183059",
                    },
                  },
                }}
              />
            </Grid>
          </div>

          {!updateMode && (
            <>
              <div className="form-container">
                <Grid item xs={12} sm={6}>
                  <InputLabel
                    htmlFor="lastName"
                    sx={{
                      fontWeight: "400",
                      size: "24px",
                      paddingBottom: "8px",
                      color: "black",
                    }}
                  >
                    User Name
                    <StarIcon />
                  </InputLabel>
                  <TextField
                    placeholder="Type Username Here"
                    type="text"
                    name="userName"
                    // value={userData.userName}
                    // onChange={handleChange}
                    value={formData.userName}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: "16px",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderWidth: "2px",
                          borderColor: "#183059", // Thicker border
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  {updateMode ? null : (
                    <>
                      <InputLabel
                        htmlFor="password"
                        sx={{
                          fontWeight: "400",
                          size: "24px",
                          paddingBottom: "8px",
                          color: "black",
                        }}
                      >
                        Password
                        <StarIcon />
                      </InputLabel>
                      <TextField
                        placeholder="Type Your Password"
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        value={formData.password}
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                          style: {
                            borderRadius: "16px",
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderWidth: "2px",
                              borderColor: "#183059",
                            },
                          },
                        }}
                      />
                    </>
                  )}
                </Grid>
              </div>

              <Grid item xs={12} sm={6}>
                <div style={{ width: "49%" }}>
                  <InputLabel
                    htmlFor="roles"
                    sx={{
                      fontWeight: "400",
                      size: "24px",
                      paddingBottom: "8px",
                      color: "black",
                    }}
                  >
                    Role
                  </InputLabel>
                  <FormControl fullWidth>
                    <Select
                      value={findRoleNameById(formData.roleId)}
                      onChange={handleRoleChange}
                      displayEmpty
                      style={selectStyles}
                      inputLabelProps={{
                        style: {
                          borderRadius: "16px",
                          // paddingBottom:"10px",
                          borderWidth: "2px",
                          borderColor: "rgba(24, 48, 89, 1)",
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        <span style={{ color: "rgba(200, 203, 208, 1)" }}>
                          Role
                        </span>
                      </MenuItem>
                      {allRoles.map((role) => (
                        <MenuItem key={role.id} value={role.name}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </>
          )}

          {updateMode ? (
            <>
              <div className="form-container">
                <Grid item xs={12} sm={6}>
                  <InputLabel
                    htmlFor="email"
                    sx={{
                      fontWeight: "400",
                      size: "24px",
                      paddingBottom: "8px",
                      color: "black",
                    }}
                  >
                    Email
                    <StarIcon />
                  </InputLabel>
                  <TextField
                    placeholder="Type Your Email Address "
                    type="text"
                    name="userName"
                    // value={userData.userName}
                    // onChange={handleChange}
                    value={formData.userName}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: "16px",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderWidth: "2px",
                          borderColor: "#183059", // Thicker border
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel
                    htmlFor="roles"
                    sx={{
                      fontWeight: "400",
                      size: "24px",
                      color: "rgba(17, 18, 20, 1)",
                    }}
                  >
                    Role
                  </InputLabel>
                  <FormControl fullWidth>
                    <Select
                      value={findRoleNameById(formData.roleId)}
                      onChange={handleRoleChange}
                      displayEmpty
                      style={selectStyles}
                      sx={{
                        borderRadius: "16px",
                        borderColor: "#183059",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#183059",
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select a role
                      </MenuItem>
                      {allRoles.map((role) => (
                        <MenuItem key={role.id} value={role.name}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </div>
            </>
          ) : (
            <Grid item xs={12}>
              <div style={{ width: "49%", marginTop: "20px" }}>
                <InputLabel
                  htmlFor="email"
                  sx={{
                    fontWeight: "400",
                    size: "24px",
                    paddingBottom: "8px",
                    color: "black",
                  }}
                >
                  Email
                  <StarIcon />
                </InputLabel>
                <TextField
                  placeholder="Type Your Email Address "
                  type="text"
                  name="email"
                  autoComplete="new-email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "16px",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderWidth: "2px",
                        borderColor: "#183059",
                      },
                    },
                  }}
                />
              </div>
            </Grid>
          )}
          {/* <Grid item xs={12}>
            <InputLabel htmlFor="email" sx={{ fontWeight: "400",size:"24px", paddingBottom: "8px" ,color:"black" }}>Email</InputLabel>
              <TextField
                label="Type Your Email Address "
                type="text"
                name="email"
                autoComplete='new-email'
                
                value={formData.email}
              onChange={handleChange}
                fullWidth
                InputProps={{
                  style: {
                   borderRadius:"16px"
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: 'black', // Thicker border
                    },
                  },
                }}
              />
            </Grid> */}
          {/* <Grid item xs={12}>
            {updateMode ? null : (
              <>
                <InputLabel
                  htmlFor="password"
                  sx={{
                    fontWeight: "400",
                    size: "24px",
                    paddingBottom: "8px",
                    color: "black",
                  }}
                >
                  Password
                </InputLabel>
                <TextField
                  label="Type Your Password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  value={formData.password}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "16px",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderWidth: "2px",
                        borderColor: "#183059",
                      },
                    },
                  }}
                />
              </>
            )}
          </Grid> */}

          {id && (
            <>
              <Grid item xs={12}>
                {hasPermission("/api/user/password", "PATCH") && (
                  <Button
                    style={{
                      textTransform: "none",
                      fontWeight: "400",
                      fontSize: "24px",
                      color: "#20A8E0",
                      marginLeft: "-10px",
                    }}
                    onClick={togglePasswordFields}
                  >
                    Reset Password
                    {showPasswordFields ? (
                      <KeyboardDoubleArrowUpIcon />
                    ) : (
                      <KeyboardDoubleArrowDownIcon />
                    )}
                    {/* <KeyboardDoubleArrowDownIcon /> */}
                  </Button>
                )}
              </Grid>
              {showPasswordFields && (
                <>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor="new-password"
                      sx={{
                        fontWeight: "400",
                        size: "24px",
                        paddingBottom: "8px",
                        color: "black",
                      }}
                    >
                      New Password<StarIcon />

                    </InputLabel>
                    <TextField
                      placeholder="Type Your New Password Here"
                      name="newPassword"
                      type="password"
                      autoComplete="new-password"
                      value={pass.newPassword}
                      // value={formData.password}
                      onChange={handlePassChange}
                      fullWidth
                      InputProps={{
                        style: {
                          borderRadius: "16px",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderWidth: "2px",
                            borderColor: "#183059",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <div style={{ marginTop: "16px" }}>
                    <Grid item xs={12}>
                      <InputLabel
                        htmlFor="confirmPassword"
                        sx={{
                          fontWeight: "400",
                          size: "24px",
                          paddingBottom: "8px",
                          color: "black",
                        }}
                      >
                        Confirm Password<StarIcon />

                      </InputLabel>
                      <TextField
                        placeholder="Confirm Your New Password Here"
                        name="confirmPassword"
                        type="password"
                        autoComplete="new-password"
                        fullWidth
                        onChange={handlePassChange}
                        value={pass.confirmPassword}
                        InputProps={{
                          style: {
                            borderRadius: "16px",
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderWidth: "2px",
                              borderColor: "#183059",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </div>
                </>
              )}
            </>
          )}
        </Box>
        <div className="addbutton_container"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "50px",
            marginTop: "80px",

            marginBottom: "30px",
            paddingBottom:'56px ',


          }}
        >
          <Grid item xs={12} sm={6}>
            <button
              type="submit"
              variant="contained"
              className='customButton_add'
              onClick={handleCancel}
              style={{background:'rgba(242, 242, 243, 1)'}}
             
            >
              Cancel
            </button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <button
            className='customButton_add'
              type="submit"
              variant="contained"
              color="primary"
            
              onClick={submitHandler}
            >
              Submit
            </button>
          </Grid>
        </div>
      </Paper>
    </>
  );
};

export default AddUser;
