import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import config from "../config.json";
const useFetch = (url) => {
  console.log('i am here')
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState("");
  const [error, setError] = useState(null);

  const fetchEntities = async (url) => {
    try {
      const res = await axios.get(`${config.baseURL}/organization`, { withCredentials: true });
      console.log(res,'-------------------------');
      setData(res.data.organization);
      setLoading(false);
      return { data };
    } catch (err) {
      setLoading(false);
      setError(err);
      console.log("Error in fetching API data", err);
      return { err };
    }
  };
// no use
  // useEffect(() => {
  //   setLoading(true);
  //   setData(null);
  //   setError(null);
  //   fetchEntities(url);
  // }, [url]);

  // data and fetchentities are same thing , loading is redendent
  return { data, loading, error, fetchEntities };
};

export default useFetch;
