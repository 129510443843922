import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { InputAdornment, TextField } from "@mui/material";

const StopSchedulesSearch = ({handleSearchChange}) => {
  return (
    <div className="schedule-searchbar">
       {/* TextField component for input */}
      <TextField
        size="small"
        id="search-schedules"
        label="Search"
        onChange={handleSearchChange}
        variant="outlined"  // Set variant to outlined
        // Add an icon to the end of the input field
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CircleIcon />  
            </InputAdornment>
          ),
        }}
        //  input label styles
        InputLabelProps={{
          style: { color: 'black', fontWeight: 'bold' },
        }}
      />
    </div>
  );
};

export default StopSchedulesSearch;
