import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuProps,
  TextField, Button, Paper, Grid, Select, MenuItem, FormControl,
   InputLabel } from '@mui/material';
import Map from './Map';  // Importing the Map component
import Checkbox from '@mui/material/Checkbox';
// import { tableData } from '../tableData';
// import StopScheduleDetails from '../StopsScheduleDetails/StopScheduleDetails';
import  {useNavigate } from 'react-router-dom';
import { stop_base_url } from '../utils';
import axios from 'axios';
import { route_base_url } from '../utils';
import config from "../../../config.json"
// import StopsTable from '../StopsTable';

function AddUpdateStops({ open, onClose,selectedStopForUpdate,routesData}) {
  // const [routesData, setRoutesData]=useState([])
  const [selectedRoute,setSelectedRoute]=useState('');  // State for storing the selected route ID
  const [latitude, setLatitude] = useState(''); // State for storing the latitude of the stop
  const [longitude, setLongitude] = useState(''); // State for storing the longitude of the stop
  const navigate = useNavigate();
  const [manualLatitude, setManualLatitude] = useState(''); // State for storing manually entered latitude
  const [manualLongitude, setManualLongitude] = useState(''); // State for storing manually entered longitude

  const [isChecked, setIsChecked] = useState(false); // State for checkbox status
  const [formData,setFormData]=useState({
    stopId:"",
    name:"",
    routeName:"",
    longitude:"",
    latitude:""
  })

  // Function to close the dialog
  const dialogClose = () => {
    onClose();
  };

  // Function to handle route change in dropdown
  const handleRouteChange = (event) => {
    const selectedRouteName = event.target.value;
    const selectedRouteObject = routesData.find(route => route.name === selectedRouteName);

  // const handleRouteChange = (event) => {
  //   const selectedRouteName = event.target.value;
  //   const selectedRouteObject = routesData.find(
  //     (route) => route.name === selectedRouteName
  //   );

    if (selectedRouteObject) {
      setSelectedRoute(selectedRouteObject.id); // Store only the ID of the selected route
      setIsChecked(true); // Select the checkbox when dropdown item is clicked
      console.log("this id coming from selected route id",selectedRouteObject)
    } else {
      setSelectedRoute(''); // If no route is found, set it to an empty string or handle accordingly
      setIsChecked(false); // Deselect the checkbox
    //   setFormData(prevState => ({
    //     ...prevState,
    //     routeName: selectedRouteName
    //   }));
    }
    // Update the formData state with the selected route name
  };

   // Function to handle click event on the map  to get lat and lng
  const handleMapClick = (lat, lng) => {
    const formattedLat = lat.toFixed(6);
    const formattedLng = lng.toFixed(6);
    
    setLatitude(formattedLat);
    setLongitude(formattedLng);
    
    // Update formData state with new latitude and longitude
    setFormData(prevState => ({
      ...prevState,
      latitude: formattedLat,
      longitude: formattedLng
    }));
  };
   // Function to handle manual latitude input change
  const handleManualLatitudeChange = (e) => {
    const value = e.target.value;
    setManualLatitude(value);
    setLatitude(value); // Update latitude state as well
    // setFormData(prevState => ({...prevState, latitude: value}));
    
  };

   // Function to handle manual longitude input change
  const handleManualLongitudeChange = (e) => {
    const value = e.target.value;
    setManualLongitude(value);
    setLongitude(value); // Update longitude state as well
    // setFormData(prevState => ({...prevState, longitude: value}));
    
  };

  // useEffect to populate form data if a stop is selected for update
  useEffect(() => {
    if (selectedStopForUpdate && selectedStopForUpdate.id) {
      console.log("this data coming from props",selectedStopForUpdate);
      setFormData({
        stopId: selectedStopForUpdate.stopId || '',
        name: selectedStopForUpdate.name || '',
        // route: selectedStop.routes || '',
        routeName: selectedStopForUpdate.routeName || '', 
        longitude: selectedStopForUpdate.longitude || '',
        latitude: selectedStopForUpdate.latitude || '',
      });
      setSelectedRoute(selectedStopForUpdate.routeName || '');
      setLatitude(selectedStopForUpdate.latitude || '');
      setLongitude(selectedStopForUpdate.longitude || '');
    }
  }, [selectedStopForUpdate]);
  
  // Function to handle form field changes
  const changeHandler=(e)=>{
    setFormData({...formData,[e.target.id]:e.target.value})
  }


   // Function to handle form submission
  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("Selected Route ID:", selectedRoute);
  
    if (selectedStopForUpdate && selectedStopForUpdate.id) {
      console.log("selectedStopForUpdate", selectedStopForUpdate);
       // Logic for updating existing stop
      const updatedStop = {
        id: selectedStopForUpdate.id,
        stopId: formData.stopId,
        name: formData.name,
        longitude: formData.longitude,
        latitude: formData.latitude,
        organizationId: selectedStopForUpdate.organizationId
      };
  
      try {
        const response = await axios.put(`${config.baseURL}/stop`, updatedStop, { withCredentials: true });
        
  
        if (response.status === 200) {
          console.log('Stop updated successfully:', response.data);
        }
  
        if (selectedStopForUpdate.routeName) {
          try {
             // Fetch existing route using selectedRoute's name
          const existingRoute = routesData.find(route => route.name === selectedStopForUpdate.routeName);
          const routeToUpdateId = existingRoute ? existingRoute.id : existingRoute.id;  // Use selectedRoute if available, otherwise use existingRoute's ID


          if (existingRoute && Array.isArray(existingRoute.routeCoordinates)) {
          const updatedCoordinates = [
            ...existingRoute.routeCoordinates,
            {
              longitude: formData.longitude,
              latitude: formData.latitude
            }
          ];
  
          //  updating route coordinates along with existing route coordinates
              const updateRoutesResponse = await axios.patch(`${route_base_url}/coordinates/`, {
                id:  routeToUpdateId, 
                routeType: "BI_DIRECTIONAL",
                routeCoordinates: updatedCoordinates
              });
  
              if (updateRoutesResponse.status === 200) {
                console.log('Routes updated successfully:', updateRoutesResponse.data);
              } else {
                console.log('Failed to update routes:');
              }
            } else {
              console.log('Failed to fetch existing route coordinates:');
            }
          } catch (error) {
            console.error('Error updating routes:', error);
          }
        }
      } catch (error) {
        console.error('Error updating stop:', error);
      }
    } else {
      const newStop = {                // Logic for adding a new stop
        stopId: formData.stopId,
        name: formData.name,
        longitude: formData.longitude,
        latitude: formData.latitude,
        organizationId: 1
      };
  
      try {
        let postResponse;
        let updateRoutesResponse;
        let existingRoute
  
        // Call POST API to add new stop
        postResponse = await axios.post(`${stop_base_url}`, newStop, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log('response', postResponse);
        if (postResponse.status === 201) {
          console.log('Stop added successfully:', postResponse.data);
  
          // Fetch existing route coordinates
          existingRoute = routesData.find(route => route.id === selectedRoute);
          
          console.log("this is existing routes data",existingRoute.routeCoordinates)
          
          if (existingRoute) {
            const updatedCoordinates = [
              ...existingRoute.routeCoordinates,
              {
                longitude: formData.longitude,
                latitude: formData.latitude
              }
            ];
            //  updating route coordinates along with existing route coordinates
      
            updateRoutesResponse = await axios.patch(`${route_base_url}/coordinates/`, {
              id: selectedRoute,
              routeType: "BI_DIRECTIONAL",
              routeCoordinates: updatedCoordinates
            });
  
            if (updateRoutesResponse.status === 200) {
              console.log('Routes updated successfully:', updateRoutesResponse.data);
            } else {
              console.log('Failed to update routes:', updateRoutesResponse.data);
            }
          } else {
            console.log('Failed to fetch existing route coordinates:', existingRoute.routeCoordinates);
          }
        } else {
          console.log('Failed to add stop:', postResponse.data);
        }
      } catch (error) {
        console.error('Error performing operations:', error);
      }
    }
    console.log(formData);
    dialogClose();
  };
  
 
  const dialogStyle = {
  width: '60%', 
  maxWidth: '800px',
	display:"flex",
	position: 'absolute',
	height:"90vh",
  right: '0',
	overflowY: 'auto',
	backgroundColor:"#F3F3F3"

  };
  const paperStyle = {
    overflowY: 'auto', 
    maxHeight: 'calc(100vh - 100px)',
    padding: '20px'
  };
  const inputStyle = {
    borderRadius:"16px" 
  };
  const dropdownStyle={
    width:"340px",
    marginTop:"45px",
  }
  const stopDetailsBtn={
    width:"250px",
    marginLeft:"400px",
    cursor:"pointer",
    marginTop:"-70px",
  }
  const cancelBtnStyle={
    color:"black",
    width:"340px",
    display:"flex",
    left:"-10px",
    marginTop:"30px",
    height:"45px",
    border:"1.5px solid black",
    borderRadius:"16px"
  }
  const submitBtnStyle={
    color:"white",
    backgroundColor:"black",
    width:"340px",
    display:"flex",
    right:"-10px",
    marginTop:"30px",
    height:"45px",
    borderRadius:"16px"

  }

  // Function to handle view stop schedule button click
  const handleViewStopSchedule = () => {
    // Check if a stop is selected for update
    if (selectedStopForUpdate && selectedStopForUpdate.id) {
      // Navigate to StopSchedulesDetails page and pass the stop ID in state
      navigate('/Stop/Schedule/Details', { state: { id: selectedStopForUpdate.id } });
    }
  };
  
  return (
    <Dialog fullWidth open={open} onClose={dialogClose}  PaperProps={{ style: dialogStyle }}>
      <DialogTitle>Add/Update Stop Details</DialogTitle>
      <DialogContent style={{paperStyle}}>
        <Paper elevation={3} style={{ padding: '20px' }}>
			<Grid>
      <Map onMapClick={handleMapClick}  // map component for displaying the map 

         manualLatitude={manualLatitude} 
         manualLongitude={manualLongitude} 
      />
				
			</Grid>
           <div style={{display:"flex", justifyContent:"space-evenly",gap:"20px"}}>
              <TextField
                autoFocus
                margin="dense"
                id="stopId"
                label="Stop ID"
                type="text"
                fullWidth
                variant="outlined"
                onChange={changeHandler}
                value={formData.stopId}
              />
              <TextField
                margin="dense"
                id="name"
                label="Stop Name"
                type="text"
                fullWidth
                variant="outlined"
                style={inputStyle}
                onChange={changeHandler}
                value={formData.name}
              />
           </div>
           <div style={dropdownStyle}>
           <FormControl fullWidth variant="outlined" style={{height:"50px"}} >
                <InputLabel id="route-label"  >Route Name</InputLabel>
                <Select
                  labelId="route-label"
                  id="route"
                  label="Route Name"
                  fullWidth 
                  value={formData.routeName ? formData.routeName : selectedRoute} // Use formData.routeName if available, otherwise use selectedRoute
                  onChange={(event) => {
                    handleRouteChange(event);
                    setFormData(prevState => ({
                      ...prevState,
                      routeName: event.target.value // Update formData's routeName
                    }));
                  }}
                   MenuProps={{
                     anchorOrigin: {
                     vertical: "bottom",
                     horizontal: "left"
                     },
                         transformOrigin: {
                              vertical: "top",
                               horizontal: "left"
                               },
                               getContentAnchorEl: null
                              }}
                              
                      >
                       <MenuItem value="">
                              None
                        </MenuItem>
                          {routesData.map((route) => (
                         <MenuItem key={route.id} value={route.name}>
                          <Checkbox
                      checked={formData.routeName === route.name}
                      style={{ marginRight: 8 }}
                    />
                          {route.name}
                        </MenuItem>
                               ))}
                </Select>
              </FormControl>
              {selectedStopForUpdate && selectedStopForUpdate.id && (
              <Button
                variant="contained"
                color="primary"
                style={stopDetailsBtn}
                onClick={handleViewStopSchedule}
              >
                View Stop Schedule
              </Button>
            )}
           </div>
           <div style={{display:"flex", justifyContent:"space-evenly",gap:"20px",marginTop:"45px"}}>
             <TextField
                margin="dense"
                id="longitude"
                // label="Longitude"
                type="text"
                fullWidth
                variant="outlined"
                value={longitude}
                onChange={handleManualLongitudeChange}
                 
              />
              <TextField
                margin="dense"
                id="latitude"
                // label="Latitude"
                type="text"
                fullWidth
                variant="outlined"
                value={latitude}
                onChange={handleManualLatitudeChange}
                
              />
           </div>
           <DialogActions>
             <Button onClick={dialogClose} color="primary" style={cancelBtnStyle}>
              Cancel
             </Button>
            <Button onClick={submitHandler} color="primary" style={submitBtnStyle}>
            Submit
            </Button>
            </DialogActions>
        </Paper>
      </DialogContent>
    </Dialog>
  );
}

export default AddUpdateStops;