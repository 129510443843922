import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config.json";
import DashboardHeader from "../../components/header/DashboardHeader";
import RoleAccessHeader from "./RoleAccessHeader";
import { Paper } from "@mui/material";
import RoleAccessTable from "./RoleAccessTable";
import { useLocation, useNavigate } from "react-router-dom";

const RoleAccess = () => {
  const navigate = useNavigate();
  const [selectedRoleData, setSelectedRoleData] = useState([]);
  const [getAllRolesData, setGetAllRolesData] = useState([]);
  const [totalFeatures, setTotalFeatures] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const id = location.state?.id;

  // API calls wrapped in try-catch with proper error handling
  const getRoleById = async (id) => {
    try {
      const response = await axios.get(`${config.baseURL}/role/${id}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/");
        return [];
      }
      console.error("Error fetching the role by id:", error);
      throw error;
    }
  };

  const getAllTheAvailableFeatures = async () => {
    try {
      const response = await axios.get(`${config.baseURL}/feature/`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching available features:", error);
      throw error;
    }
  };

  const assignFeatureToTheRole = async (roleId, featureIds) => {
    try {
      const response = await axios.post(`${config.baseURL}/role/feature`, {
        roleId,
        featureId: featureIds,
      });
      return response.data;
    } catch (error) {
      console.error("Error assigning features to role:", error);
      throw error;
    }
  };

  const getAllRoles = async () => {
    try {
      const response = await axios.get(`${config.baseURL}/role/organization/`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching roles:", error);
      throw error;
    }
  };

  // Initial data fetching
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsLoading(true);
        const [featuresData, rolesData] = await Promise.all([
          getAllTheAvailableFeatures(),
          getAllRoles(),
        ]);

        setTotalFeatures(featuresData);
        setGetAllRolesData(rolesData.roles);

        if (id) {
          const roleData = await getRoleById(id);
          setSelectedRoleData(roleData.role);
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, [id, navigate]); // Only re-run if id or navigate changes

  if (isLoading) {
    return <div>Loading...</div>; // Consider using a proper loading component
  }

  return (
    <>
      <DashboardHeader title="Role Access Assignment" />
      <Paper
        sx={{
          margin: "20px",
          height: "100%",
          borderRadius: "20px",
          padding: "20px",
        }}
      >
        <RoleAccessHeader
          selectedRoleData={selectedRoleData}
          getAllRolesData={getAllRolesData}
          totalFeatures={totalFeatures.length}
        />
        <div style={{ marginTop: "30px" }}>
          <RoleAccessTable
            getAllTheAvailableFeatures={getAllTheAvailableFeatures}
            getAllRoles={getAllRoles}
            selectedRoleData={selectedRoleData}
            assignFeatureToTheRole={assignFeatureToTheRole}
          />
        </div>
      </Paper>
    </>
  );
};

export default RoleAccess;
