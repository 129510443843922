import CircleIcon from "@mui/icons-material/Circle";
import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import "./Organization.css";

const SearchOrganization = ({ handleSearch }) => {
  return (
    <div className="o-searchbar shared-bg">
      <TextField 
        size="small"
        id="search-organization"
        placeholder="Search "
        variant="outlined"
        onChange={handleSearch}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 17.5C13.4183 17.5 17 13.9183 17 9.5C17 5.08172 13.4183 1.5 9 1.5C4.58172 1.5 1 5.08172 1 9.5C1 13.9183 4.58172 17.5 9 17.5Z" stroke="#183059" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.9984 19.4999L14.6484 15.1499" stroke="#183059" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default SearchOrganization;
