import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { vehicleStatus } from "../organization/utils";

export default function VehicleFilter({ vehicleData, filters, setFilters }) {
  const vehicleRouteOptions = Array.from(
    new Set(
      vehicleData?.map(
        (vehicle) =>
          vehicle.route && {
            id: vehicle.route?.id,
            name: vehicle.route?.name,
          }
      )
    )
  );

  const handleFilterRoute = (event) => {
    let clonedFilters = { ...filters };
    console.log("clonedFilter", clonedFilters);
    clonedFilters.route = event.target.value;
    setFilters(clonedFilters);
  };

  const handleFilterVehicleStatus = (event) => {
    setFilters({ ...filters, status: event.target.value });
  };

  return (
    <Box
      sx={{ minWidth: 500 }}
      display="flex"
      gap={2}
      alignItems="center"
      justifyContent="space-around"
    >
      <FormControl fullWidth size="small"style={{background:"rgba(242, 242, 243, 1)"}}>
        <InputLabel id="vehicle-route-dropdown">Route</InputLabel>
        <Select
          labelId="vehicle-route-dropdown"
          id="vehicle-route-dropdown"
          value={filters.route?.name}
          label="Vehicles"
          onChange={handleFilterRoute}
        >
          {vehicleRouteOptions?.map((vehicleroute) => (
            <MenuItem key={vehicleroute?.id} value={vehicleroute?.id}>
              {vehicleroute?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth size="small"style={{background:"rgba(242, 242, 243, 1)"}}>
        <InputLabel id="vehicle-status-dropdown">Status</InputLabel>
        <Select
          labelId="vehicle-status-dropdown"
          id="vehicle-status-dropdown"
          value={filters.vehicleStatus}
          label="Status"
          onChange={handleFilterVehicleStatus}
        >
          {Object.values(vehicleStatus)?.map((vehicleStatus) => (
            <MenuItem key={vehicleStatus} value={vehicleStatus}>
              {vehicleStatus}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
