import { useEffect, useState } from "react";
import axios from "axios";

const useDelete = (url) => {

  const deleteEntity = async (id) => {
    let responseData, error, loading;
    const deleteUrl = `${url}/${id}`
    try {
      loading = true;
      const response = await axios.delete(deleteUrl);
      console.log("responsedel", response);
      if (response.status === 201 || response.status === 200) {
        responseData = response;
      } else {
        error = "error";
      }
      loading = false;
    } catch (err) {
      console.log("error in deleting organization", err);
      error = err;
      loading = false;
    }
    return { responseData, loading, error };
  };

  return { deleteEntity };
};



export default useDelete;
