import React from 'react'

const Tracking = () => {
  return (
    <div>
      <h1>Tracking page</h1>
    </div>
  )
}

export default Tracking
