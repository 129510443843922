import React, { useEffect, useState } from 'react'
import { Add } from '@mui/icons-material'
import { FilterComponent, SearchComponent } from '../../config/Pageitems';
import './User.css'
import axios from 'axios'
import config from '../../config.json' 
import sortByParam from '../../hooks/SortByParam';
import sarchInTheTable from '../../hooks/SarchInTable'
import filterBy from '../../hooks/FilterBy';
import DashboardHeader from '../../components/header/DashboardHeader';
import UserHeader from './UserHeader'
import { Paper } from '@mui/material';
import UsersTable from './UsersTable';

let userList = [];
let displayUserList = []

async function getAllUsers(){
  try {
    const response = await axios.get(`${config.baseURL}/user/organization/17`, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.log("Error in fetching user data: ", error);
    throw error
  }
}

async function deleteUser(id) {
  try {
    const response = await axios.delete(`${config.baseURL}/user/${id}`, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.log("Error in Deleting user data: ", error);
    throw error
  }
}

function userListToDisplayUserList(userList) {
  return userList.map(user => ({
    name: user.name,
    userName: user.userName,
    email: user.email,
    id: user.id,
    role: user.roles ? user.roles.name : ''// Assuming each user has a single role object with a `name` property
  }));
}

const Users = () => {
  const [currentUserList,setCurrentUserList]=useState([]);
  const [currentUserListToDisplay,setCurrentUserListToDisplay]=useState([]);
  const [searchInput,setSearchInput]=useState("");
  const [selectRoleForFilter,setSelectRoleForFilter]=useState("");
  const [sortParam, setSortParam] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  


  const handleSearch=(e)=>{
    setSearchInput(e.target.value)
    console.log("search input",searchInput)
  }
  const handleFilter=(e)=>{
    const selectedValue=e.target.value
    setSelectRoleForFilter(selectedValue==='All' ? '':selectedValue);
    console.log("selected role filter",selectRoleForFilter)
  }
  const handleSortChange = (param) => {
    // If the currently sorted parameter is the same as the clicked one, toggle direction
    const newSortDirection = sortParam === param ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
    setSortDirection(newSortDirection);
    setSortParam(param);
  };

  useEffect(() => {
    getAllUsers().then((response)=>{
      userList=response.users
      setCurrentUserList(userList)
      console.log("users response",userList)
      displayUserList=userListToDisplayUserList(userList)
      console.log("display user list",displayUserList)
      setCurrentUserListToDisplay(displayUserList)
      // Apply sorting
      console.log(sortParam,"----",sortDirection);
      let sortedFeatures = sortByParam(sortParam, displayUserList);
      console.log(sortedFeatures,"----",sortDirection);
     if (sortDirection === 'desc') {
       // If sorting direction is descending, reverse the sorted array
       sortedFeatures = sortedFeatures.reverse();
     }
     setCurrentUserListToDisplay(sortedFeatures);
     if(searchInput){
      const searchResult = sarchInTheTable(searchInput, displayUserList);
      setCurrentUserListToDisplay(searchResult)
        console.log("search result", searchResult);
     }
     if(selectRoleForFilter){
      const filteredList = filterBy("role", selectRoleForFilter, displayUserList);
      console.log(filteredList);
      setCurrentUserListToDisplay(filteredList)
     }
    })  
  }, [searchInput,selectRoleForFilter,sortParam,sortDirection]);
  
  // deleteUser(2).then(response => console.log(response)).catch(err => console.log(err))
  return (
    <>
    <DashboardHeader title="Users"/>
    <Paper style={{ margin: "20px",borderRadius:"20px" }}>
    
    <UserHeader 
     handleSearch={handleSearch}
     handleFilter={handleFilter}
     currentUserListToDisplay={displayUserList}
     displayUserList={displayUserList.length}
    />
    <UsersTable 
    currentUserListToDisplay={currentUserListToDisplay}
    deleteUser={deleteUser}
    onSortChange={handleSortChange}
       sortParam={sortParam}
       sortDirection={sortDirection}
       setCurrentUserListToDisplay={setCurrentUserListToDisplay}
    />

    </Paper>
    
    </>
  )
}

export default Users
