import React, { useEffect, useState } from 'react'
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material/styles";
import Pagination from '../../../components/pagination/Pagination';
import './role.css'
import { Button, Checkbox, Divider } from '@mui/material';

// Function to create a new row data object
function createData(id, name, description, selected) {
  return { id, name, description, selected };
}

// Define columns for the table
const columns = [
  { label: "Access Name", id: "Access Name" },
  { label: "Description", id: "Description" },
  { label: "Assign", id: "routeName" },
];

// Convert rows to the required format
const getRows = (rows) =>
  rows.map((item) =>
    createData(
      item.id,
      item.name,
      item.description,
      item.selected
    )
  );

function FeatureAssignmentTable({ getAllTheAvailableFeatures, selectedOrganizationFeatures, assignFeatureToOrganization }) {
  const theme = useTheme();
  const [availableFeatures, setAvailableFeatures] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false); // State to track loading state
  const [error, setError] = useState(null); // State to track error state

  const styles = {
    header: { backgroundColor: theme.palette.primary.main, color: "white", fontSize: "20px", fontWeight: "500" },
    descriptionCell: { paddingLeft: theme.spacing(2) }
  };

  useEffect(() => {
    getAllTheAvailableFeatures()
      .then(data => {
        // Mark features that are already assigned to the selected role
        const featuresWithSelection = data.map(feature => ({
          ...feature,
          selected: selectedOrganizationFeatures.features.some(f => {
            console.log(`Comparing feature id ${f.feature.id} with ${feature.id}`);
            return f.feature.id === feature.id;
          })
        }));
        setAvailableFeatures(featuresWithSelection);
      })
      .catch(error => console.error('Error fetching features:', error));
  }, [getAllTheAvailableFeatures, selectedOrganizationFeatures]);

  // calculating pagination 
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  const totalCount = parseInt(availableFeatures?.length);
  const totalPages = Math.ceil(totalCount / itemsPerPage) || 1;

  // Get the current page of features
  const currentFeatures = availableFeatures.slice(startIndex, endIndex);

  // handle pagination page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handler for checkbox change
  const toggleFeatureSelection = (id) => {
    // Update the state of availableFeatures to toggle the selected status of the feature
    setAvailableFeatures(prevFeatures => {
      return prevFeatures.map(feature => {
        if (feature.id === id) {
          return { ...feature, selected: !feature.selected };
        }
        return feature;
      });
    });
  };

  const assignFeatures = async () => {
    setLoading(true); // Set loading state to true
    setError(null); // Clear previous errors
    const selectedFeatureIds = currentFeatures
      .filter(feature => feature.selected)
      .map(feature => feature.id);
    const organizationId = selectedOrganizationFeatures.organizationId;
    try {
      const result = await assignFeatureToOrganization(organizationId, selectedFeatureIds);
      console.log('Feature assigned to role successfully:', result);
      setAvailableFeatures(prevFeatures => prevFeatures.map(feature => ({ ...feature, selected: false })));
    } catch (error) {
      console.error('Error assigning feature to role:', error);
      setError(error.message); // Set error message
    } finally {
      setLoading(false); // Set loading state to false
    }
  };

  const resetAllCheckboxes = () => {
    // Reset all checkboxes to unchecked
    setAvailableFeatures(prevFeatures =>
      prevFeatures.map(feature => ({ ...feature, selected: false }))
    );
  };

  return (
    <>
      <TableContainer component={Paper} >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  align="left"
                  style={styles.header}
                  key={column.id}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ padding: "0px" }} className='TableBody-root'>
            {getRows(currentFeatures).map((row) => (
              <TableRow
                className="MuiTableRow-root"
                style={{ color: "black" }}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                key={row.id}
              >
                <TableCell align='justify' >{row.name}</TableCell>
                <TableCell align='left' style={styles.descriptionCell}  >{row.description}</TableCell>
                <TableCell align='justify' >
                  <Checkbox
                    checked={row.selected}
                    onChange={() => toggleFeatureSelection(row.id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      <Divider style={{ paddingTop: "15px" }} />
      <div style={{ marginTop: '24px', textAlign: 'right', marginRight: '40px', gap: "16px", paddingBottom: "24px" }}>
        <Button
          className="cancel_btn"
          color='primary'
          onClick={resetAllCheckboxes}
          disabled={loading}
          style={{
            marginRight: '50px',
            width: "229px",
            height: "70px",
            border: "1px solid black",
            fontSize: "27px",
            textTransform: "none",
            background: "#F2F2F3",
            borderRadius: "16px"
          }}>Cancel</Button>
        <Button variant="contained"
          className='save_btn'
          onClick={assignFeatures}
          disabled={loading} color="primary"
          style={{
            marginRight: '50px', width: "229px", height: "70px",
            textTransform: "none",
            fontSize: "27px", background: "#F9E719",
            borderRadius: "16px"
          }}>Save</Button>
      </div>
    </>
  )
}

export default FeatureAssignmentTable
