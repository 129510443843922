import React, { useState } from 'react'
import './feature.css'
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, TextField, TextareaAutosize } from '@mui/material'
import { styled } from '@mui/system';
import AddIcon from "@mui/icons-material/Add";
//import { makeStyles } from '@mui/styles'; // updated to @mui/styles for MUI v5


// const useStyles = makeStyles((theme) => ({
// 	roundedTextField: {
// 	  '& .MuiOutlinedInput-root': {
// 		borderRadius: '16px', // Adjust the radius as needed
// 	  },
// 	  '& .MuiSelect-root': {
// 		borderRadius: '16px', // Adjust the radius as needed
// 	  },
// 	},
// 	paper: {
// 	  background: '#B7B7B7',
// 	  color: 'black',
// 	  padding: theme.spacing(2),
// 	  borderRadius: theme.spacing(1),
// 	  width: '100%',
// 	  marginTop: '30px',
// 	},
// 	select: {
//         borderRadius: '16px',
//         '& .MuiOutlinedInput-notchedOutline': {
//             borderColor: 'black',
//             borderWidth:"2px"
//         },
//     },
//   }));

function FeatureForm({createFeature,addFeature}) {
 // const classes = useStyles();

  const [formData, setFormData] = useState({
    name: '',
    apiPath: '',
    description: '',
    action: '',
  });
  const [errors, setErrors] = useState({});
  const [selectedAction, setSelectedAction] = useState("")
  // Handler function to update the selected action
const handleActionChange = (e) => {
  setSelectedAction(e.target.value);
};

  // handle form dta change
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Clear the error for the current field
    const updatedErrors = { ...errors };
    delete updatedErrors[name];
    setErrors(updatedErrors);

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // submit handler for creating the feature
  // submit handler for creating the feature
  const handleSubmit = async (e) => {
    console.log('Form submitted'); // Check if form submission is triggered
    e.preventDefault();

    const errors = {};
    if (!formData.name.trim()) {
      errors.name = 'Please enter a name.';
    }
    if (!formData.apiPath.trim()) {
      errors.apiPath = 'Please enter an API path.';
    }
    if (!selectedAction) {
      errors.action = 'Please select an action.';
    }
    setErrors(errors);

    // If there are validation errors, do not proceed with submission
    // if (Object.keys(errors).length > 0) {
    //   return;
    // }
   
  
    
    // Check if any errors exist
   
  
    const newFeature = {
      name: formData.name,
      description: formData.description,
      api_path: formData.apiPath,
      action: selectedAction,
    };
    try {
      const result = await createFeature(newFeature);
      console.log('Feature created successfully:', result);
      
      // Reset form fields
      addFeature(result)
      setFormData({
        name: '',
        apiPath: '',
        description: '',
        action: '',
      });
  
    } catch (error) {
      console.error('Error creating feature:', error);
    }
  };
  const selectStyles={
		color:"black",
		fontWeight: 'bold',
		width:"400px",
		// border:"2px solid black ",
		BorderStyle:"none",
		height:"55px",
    
		borderRadius:"16px",
    marginRight:"14px",
    borderWidth:"2px solid black"
	  }
    const [totalFeatures, setTotalFeatures] = useState(0);
  return (
	<>


<Box className="Headercard" sx={{width:'33%'}}>
    <Grid container spacing={3}>
      <Grid item xs>
        <Box className="Headercard_Total">
        <div className="Headercard_Total_text">
        <div className="org-para">
          Total <br/>Features
        </div>
        <div >
          <span className="org-count">
          {totalFeatures} 
          </span>
        </div>
      </div>
        </Box>
      </Grid>
      </Grid>
      </Box>

  
  <div className='form_header'>Add Features</div>
	
	<Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
		className='field_container'
		
      >
        <TextField
          id="outlined-required"
          label="Name"
          name="name"
          value={formData.name}
          error={errors.name}
          helperText={errors.name}
          onChange={handleChange}
		  className='fields'
      InputLabelProps={{
        style: { color: 'black',fontFamily:"ROboto", fontWeight: '400',paddingLeft:"24px" }, // Custom styles for input label
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderWidth: '2px',
            borderColor: 'black', // Thicker border
          },
          '&:hover fieldset': {
            borderWidth: '2px',
          },
          '&.Mui-focused fieldset': {
            borderWidth: '2px',
          },
        },
      }}
        />
        <TextField
          id="outlined-required-2"
          label="API Path"
          name="apiPath" 
          value={formData.apiPath}
          error={errors.apiPath}
          helperText={errors.apiPath}
          onChange={handleChange}
		  className='fields'
      InputLabelProps={{
        style: { color: 'black',fontFamily:"ROboto", fontWeight: '400',paddingLeft:"24px" }, // Custom styles for input label
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderWidth: '2px',
            borderColor: 'black', // Thicker border
          },
          '&:hover fieldset': {
            borderWidth: '2px',
          },
          '&.Mui-focused fieldset': {
            borderWidth: '2px',
          },
        },
      }}
        /> 
      </Box>
      <Button
		  variant="contained"
		  className="add-access-btn"
      style={{position:"absolute",right:"70px",top:"270px"}}
      onClick={handleSubmit}
		>
		  <AddIcon /> Add Access
		</Button>
	  <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '50ch' },
        }}
        noValidate
        autoComplete="off"
		className='discriptionfield_container'
      >
        <TextareaAutosize
          minRows={4}
          name="description"
          placeholder="Description"
          
          value={formData.description}
          onChange={handleChange}
		  className='textarea'
      InputLabelProps={{
        style: { paddingLeft:"24px" }, // Custom styles for input label
      }}
      sx={{
        fontFamily: 'roboto', // Change the font family as needed
        fontSize: '24px', // Optionally adjust font size
        fontWeight:"400",
        // marginLeft:"24px"
        // Add any other custom styles here
    }}
          
        />
        <FormControl fullWidth sx={{ mt: 2 }} style={{width:"400px"}}>
        <InputLabel
    id="operation-label"
    sx={{ color: 'black', fontFamily: "ROboto", fontWeight: '400' }}
  >
    Operation
  </InputLabel>
          <Select
            labelId="operation-label"
            id="operation"
           // className={classes.roundedTextField} // Apply custom CSS class
            label="Operation"
            name="action" 
            style={selectStyles}
            error={errors.apiPath}
            helperText={errors.apiPath}
            value={selectedAction}
            onChange={handleActionChange}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderWidth: '2px',
                  borderColor: 'black', // Thicker border
                  paddingLeft:"24px"
                },
              },
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value='All'>All</MenuItem> 
            <MenuItem value="GET">GET</MenuItem>
            <MenuItem value="POST">POST</MenuItem>
            <MenuItem value="PUT">PUT</MenuItem>
            <MenuItem value="DELETE">DELETE</MenuItem> 
            <MenuItem value="PATCH">PATCH</MenuItem>   
          </Select>
        </FormControl>
      </Box>
	</>
  )
}

export default FeatureForm