import { Box, Container, Divider, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React from 'react'
import './role.css'


function FeatureAssignmentHeader({selectedOrganizationFeature,getAllRolesData,totalFeatures}) {
	console.log("Selected Role Data:", selectedOrganizationFeature);
	console.log("selectedOrganizationFeatures", selectedOrganizationFeature);
	// const selectStyle={
	// 	borderRadius:"16px"
	// }
  return (
	<>
	    
		<Box className="role_info_container">
			
			<div className='heading_container'>
			<h2 className='assign_heading'>Assign the Feature  for the organization</h2>
				<span className='total_features'>{totalFeatures} Features</span>
			</div>
			<Divider />
			<div className='role_des_container'>
				<div>
					<h2 className='select_heading'>Selected organization</h2>
					<TextField
        size="small"
        id="search-role"
        // label="Search role"
        variant="outlined" 
		// style={selectStyle}
		className='custom-text-field select_role'
		value={selectedOrganizationFeature.organizationName}
        
        //  input label styles
        InputLabelProps={{
          style: { color: 'black' },
        }}
      />
				{/* <FormControl fullWidth>
              <InputLabel id="role-select-label"></InputLabel>
              <Select
                labelId="role-select-label"
                id="role-select"
				style={selectStyle}
				value={selectedRoleData ? selectedRoleData.name : ''}
				onChange={(e) => console.log(e.target.value)}
                // label="Role"
				className='select_role'
              >
                 {getAllRolesData.map((role) => (
                        <MenuItem key={role.id} 
						value={role.name}
						// selected={selectedRoleData && selectedRoleData.id === role.id}
						>
                            {role.name}
                        </MenuItem>
                    ))}
              </Select>
             </FormControl> */}
				</div>
				<div className='description_style'>
					<h2 className='des_heading'>Role Description</h2>
					<span className='description'>{selectedOrganizationFeature?.description}</span>
				</div>

			</div>
		</Box>
	</>
  )
}

export default FeatureAssignmentHeader