import { useState } from "react";
import axios from "axios";

const useAdd = (url) => {
  // const [responseData, responseData =  = useStte(null);
  // const [loading, loading =  = usState(false);
  // const [error, error =  = usState(null);

//   {
//     "name": "Schedule 11",
//     "startTime": "2024-05-02T22:25",
//     "endTime": "2024-05-04T22:25",
//     "repitation": "DAILY",
//     "description": "This is Schedule 11",
//     "route": 1,
//     "organizationId": 1
// }

// { name: "Schedule 11",
// description: "This is Schedule 11",
// startTime: "2024-02-22T08:00:00.000Z",
// endTime: "2024-02-26T17:00:00.000Z",
// repitation: "WEEKEND",
// organizationId: 1,
// routeId: 1,
// }


  const addEntity = async (formData) => {
    console.log("formdata", formData);
    let responseData, error, loading;
    try {
      loading = true;
      const response = await axios.post(url, formData);
      console.log("response123", response);
      if (response.status === 201 || response.status === 200) {
        responseData = response;
      } else {
        error = "error";
      }
      loading = false;
    } catch (err) {
      console.log("error in creating organization", err);
      error = err;
      loading = false;
    }
    return { responseData, loading, error };
  };
  return { addEntity };
};

export default useAdd;
