import React, { useState } from "react";
import { Close, Menu } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import "./DashboardHeader.css";

const DashboardHeader = ({ title }) => {
  const navigate = useNavigate();
  const [isMenuActive, setIsMenuActive] = useState(false);

  const toggleMobileMenu = () => {
    setIsMenuActive(!isMenuActive);
  };

  return (
    <section className="dashboard-header">
      <header className={`dh-container ${isMenuActive ? "active" : ""}`}>
        <div className="dh-menu" onClick={toggleMobileMenu}>
          <Menu className="mobile-icon" name="menu" />
          <Close className="mobile-icon" name="close" />
        </div>

        <div className="dh-page">
          <h1>{title}</h1>
        </div>

        <div className="dh-profile">
          <div className="dh-image">
            <PersonIcon
              style={{
                fontSize: 40,
                color: "rgba(24, 48, 89, 1)",
                background: "rgba(232, 234, 238, 1)",
              }}
            />
          </div>
          {title === "Organizations > Register" ? (
            <button className="dh-logout" onClick={() => navigate("/")}>
              Login
            </button>
          ) : (
            <div style={{ cursor: "pointer" }}>
              <svg
                width="18"
                height="10"
                viewBox="0 0 18 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L9 9L17 1"
                  stroke="#F9E719"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </div>
      </header>
    </section>
  );
};

export default DashboardHeader;
