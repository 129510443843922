import { useState } from "react";
import axios from "axios";

const useUpdate = (url) => {
  // const [responseData, setResponseData] = useState(null);
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);

  const updateEntity = async (formData, id) => {
    let responseData, error, loading;
    try {
      const updateUrl = `${url}/${id}`;
      loading = true;
      const response = await axios.put(updateUrl, formData);
      console.log("response123", response);
      if (response.status === 201 || response.status === 200) {
        responseData = response;
      } else {
        error = "error";
      }
      loading = false;
    } catch (err) {
      console.log("error in updating organization", err);
      error = err;
      loading = false;
    }
    return { responseData, loading, error };
  };
  return { updateEntity };
};

export default useUpdate;
