import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { useLocation } from "react-router-dom";
import { loadOptions } from "./mapConfig";
import DashboardHeader from "../../components/header/DashboardHeader";
import Pagination from "../../components/pagination/Pagination";
import {
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import config from "../../config.json";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(32, 168, 224, 1)",
    color: "white",
    fontSize: 20,
    fontWeight: 600,
    width: "20%",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 24,
  },
}));

const LoadingScreen = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      flexDirection: "column",
      gap: "20px",
    }}
  >
    <CircularProgress size={60} />
    <div>Loading map data...</div>
  </div>
);

const MapPage = () => {
  const location = useLocation();
  const [stopData, setStopData] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [directions, setDirections] = useState([]);
  const [vehicleData, setVehicleData] = useState({});
  const [error, setError] = useState(null);
  const routePathsRef = useRef({});

  const { isLoaded, loadError } = useJsApiLoader({
    ...loadOptions,
    libraries: [...(loadOptions.libraries || []), "geometry"],
  });

  // Initialize stop data
  useEffect(() => {
    const locationStopData = location.state?.selectedStopData;
    if (!locationStopData) {
      setError("No stop data available");
      return;
    }
    setStopData(locationStopData);
    
  }, [location.state]);

  // Fetch directions when stop data is available
  useEffect(() => {
    if (stopData && isLoaded) {
      fetchDirections(stopData.routes);
    }
  }, [stopData, isLoaded]);

  const fetchDirections = async (routes) => {
    if (!routes?.length) {
      
      setError("No routes available");
      return;
    }

    const directionsService = new window.google.maps.DirectionsService();
    const directionsPromises = routes.map((route) => {
      return new Promise((resolve, reject) => {
        const waypoints = route.routeCoordinates.slice(1, -1).map((coord) => ({
          location: new window.google.maps.LatLng(
            parseFloat(coord.latitude),
            parseFloat(coord.longitude)
          ),
          stopover: true,
        }));

        directionsService.route(
          {
            origin: new window.google.maps.LatLng(
              parseFloat(route.routeCoordinates[0].latitude),
              parseFloat(route.routeCoordinates[0].longitude)
            ),
            destination: new window.google.maps.LatLng(
              parseFloat(
                route.routeCoordinates[route.routeCoordinates.length - 1]
                  .latitude
              ),
              parseFloat(
                route.routeCoordinates[route.routeCoordinates.length - 1]
                  .longitude
              )
            ),
            waypoints: waypoints,
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              routePathsRef.current[route.id] = result.routes[0].overview_path;
              resolve(result);
            } else {
              reject(`error fetching directions ${result}`);
            }
          }
        );
      });
    });

    try {
      const results = await Promise.all(directionsPromises);
      setDirections(results);
      setIsInitialized(true);
    } catch (error) {
      console.error("Error fetching directions:", error);
      setError("Failed to fetch route directions");
    }
  };

  const snapToRoute = useCallback((routeId, position) => {
    const routePath = routePathsRef.current[routeId];
    if (!routePath) return position;

    const point = new window.google.maps.LatLng(position.lat, position.lng);
    let closestPoint = point;
    let minDistance = Infinity;

    for (let i = 0; i < routePath.length - 1; i++) {
      const start = routePath[i];
      const end = routePath[i + 1];
      const projectedPoint = window.google.maps.geometry.spherical.interpolate(
        start,
        end,
        0.5
      );
      const distance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          point,
          projectedPoint
        );

      if (distance < minDistance) {
        closestPoint = projectedPoint;
        minDistance = distance;
      }
    }

    return { lat: closestPoint.lat(), lng: closestPoint.lng() };
  }, []);

  const updateVehicleData = useCallback(async () => {
    if (!stopData) return;

    const newData = {};
    for (const route of stopData.routes) {
      for (const vehicle of route.vehicles) {
        try {
          const response = await fetch(
            `${config.baseURL}/location/latest/${vehicle.device_id}`
          );
          const data = await response.json();
          const position = {
            lat: parseFloat(data.latitude),
            lng: parseFloat(data.longitude),
          };
          const disTimeRes = await fetch(
            `${config.baseURL}/distance/time/stop`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                vehicleId: vehicle.id,
                longitude: stopData.longitude,
                latitude: stopData.latitude,
              }),
            }
          );
          const disTimeData = await disTimeRes.json();
          const snappedPosition = snapToRoute(route.id, position);
          newData[vehicle.device_id] = {
            position: snappedPosition,
            routeId: route.routeId,
            speed: parseFloat(data.Speed),
            distance: disTimeData.distance,
            eta: disTimeData.eta,
          };
        } catch (error) {
          console.error(
            `Error fetching data for vehicle ${vehicle.device_id}:`,
            error
          );
        }
      }
    }
    setVehicleData(newData);
  }, [stopData, snapToRoute]);

  useEffect(() => {
    if (stopData && isInitialized) {
      updateVehicleData();
      const intervalId = setInterval(updateVehicleData, 10000);
      return () => clearInterval(intervalId);
    }
  }, [stopData, updateVehicleData, isInitialized]);

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  // Early return checks
  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (error) {
    return (
      <div style={{ padding: "20px" }}>
        <h2>Error</h2>
        <p>{error}</p>
      </div>
    );
  }

  if (!isLoaded || !stopData || !isInitialized) {
    return <LoadingScreen />;
  }

  const renderMap = () => {
    const center = {
      lat: parseFloat(stopData.latitude),
      lng: parseFloat(stopData.longitude),
    };

    return (
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={14}
      >
        <Marker position={center} title={stopData.name} />
        {directions.map((direction, index) => (
          <DirectionsRenderer
            key={index}
            directions={direction}
            options={{
              suppressMarkers: true,
              polylineOptions: {
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 3,
              },
            }}
          />
        ))}
        {Object.entries(vehicleData).map(([deviceId, data]) => (
          <Marker
            key={deviceId}
            position={data.position}
            title={`Vehicle ${deviceId}`}
            icon={{
              path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              scale: 6,
              fillColor: "#0000FF",
              fillOpacity: 0.8,
              strokeWeight: 1,
            }}
          />
        ))}
      </GoogleMap>
    );
  };

  const renderTable = () => (
    <>
      <div className="form_header" style={{ marginBottom: "-20px" }}>
        Vehicles on this Stop
      </div>
      <div>
        <Table
          style={{
            width: "100%",
            marginTop: "20px",
            borderCollapse: "collapse",
          }}
        >
          <TableHead>
            <TableRow style={{ backgroundColor: "#f2f2f2" }}>
              <StyledTableCell
                style={{
                  padding: "8px",
                  textAlign: "left",
                  paddingLeft: "24px",
                }}
              >
                Vehicle ID
              </StyledTableCell>
              <StyledTableCell style={{ padding: "8px", textAlign: "left" }}>
                Route ID
              </StyledTableCell>
              <StyledTableCell style={{ padding: "8px", textAlign: "left" }}>
                ETA
              </StyledTableCell>
              <StyledTableCell style={{ padding: "8px", textAlign: "left" }}>
                Distance
              </StyledTableCell>
              <StyledTableCell
                style={{
                  textAlign: "right",
                  padding: "24px",
                  alignContent: "flex-end",
                }}
              >
                Speed (km/h)
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(vehicleData).map(([deviceId, data]) => (
              <TableRow key={deviceId}>
                <StyledTableCell style={{ padding: "8px" }}>
                  {deviceId}
                </StyledTableCell>
                <StyledTableCell style={{ padding: "8px" }}>
                  {data.routeId}
                </StyledTableCell>
                <StyledTableCell style={{ padding: "8px" }}>
                  {data.eta}
                </StyledTableCell>
                <StyledTableCell style={{ padding: "8px" }}>
                  {data.distance}
                </StyledTableCell>
                <StyledTableCell style={{ padding: "8px", textAlign: "right" }}>
                  {data.speed}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );

  const renderRouteTable = () => (
    <>
      <div style={{ marginLeft: "-20px" }}>
        <DashboardHeader title="Stops" />
      </div>
      <div style={{ paddingTop: "32px", display: "flex" }}>
        <div style={{ flex: 1, marginRight: "20px" }}>
          <div className="form_header form_title" style={{ marginTop: "0px" }}>
            Assigned Routes
          </div>
          <div>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <th
                    style={{
                      padding: "24px",
                      textAlign: "left",
                      background: "rgba(32, 168, 224, 1)",
                      color: "white",
                    }}
                  >
                    Route ID
                  </th>
                  <th
                    style={{
                      padding: "8px",
                      background: "rgba(32, 168, 224, 1)",
                      textAlign: "right",
                      color: "white",
                      paddingRight: "24px",
                    }}
                  >
                    Route Name
                  </th>
                </tr>
              </thead>
              <tbody>
                {stopData.routes.map((route) => (
                  <tr key={route.routeId}>
                    <td style={{ padding: "8px", paddingLeft: "24px" }}>
                      {route.routeId}
                    </td>
                    <td style={{ padding: "8px", textAlign: "right" }}>
                      {route.name}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination />
        </div>
        <div style={{ flex: 1 }}>{renderStopDetails()}</div>
      </div>
    </>
  );

  const renderStopDetails = () => (
    <div>
      <div
        className="customButton_add"
        style={{ background: "rgba(32, 168, 224, 1)", color: "white" }}
      >
        View Stop Schedules
      </div>
      <span style={{ fontWeight: "600" }}>Stop ID: {stopData.stopId}</span>
      <br />
      <span style={{ fontWeight: "600" }}>
        Total Routes: {stopData.routes.length}
      </span>
      <br />
      <span style={{ fontWeight: "600" }}>Stop Name: {stopData.name}</span>
      <br />
      <span style={{ fontWeight: "600" }}>
        Coordinates: {stopData.longitude}, {stopData.latitude}
      </span>
    </div>
  );

  // Main render
  return (
    <div style={{ marginLeft: "20px" }}>
      {renderRouteTable()}
      {renderMap()}
      {renderTable()}
    </div>
  );
};

export default MapPage;
