import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import config from '../../config.json';

async function getSiteById(id) {
  try {
    const response = await axios.get(`${config.baseURL}/site/id?id=${id}`, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.log("Error in fetching site data: ", error);
    throw error;
  }
}

async function createSite(site) {
  try {
    const response = await axios.post(`${config.baseURL}/site`, site, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.log("Error in creating site: ", error);
    throw error;
  }
}

async function updateSite(site) {
  try {
    const response = await axios.put(`${config.baseURL}/site`, site, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.log("Error in updating site: ", error);
    throw error;
  }
}

async function assignVehiclesToSite(id, vehicleIds) {
  try {
    const response = await axios.put(`${config.baseURL}/site/vehicles`, { siteId: id, vehicleIds }, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.log("Error in assigning vehicles to site: ", error);
    throw error;
  }
}

async function getAllTheVehicles() {
  try {
    const response = await axios.get(`${config.baseURL}/vehicle`, {
      withCredentials: true
    });
    return response.data.vehicles;
  } catch (error) {
    console.log("Error in fetching vehicles: ", error);
    throw error;
  }
}

const AddSite = () => {
  const { siteId } = useParams();
  const navigate = useNavigate();
  const [site, setSite] = useState({
    siteId: '',
    name: '',
    description: '',
    organizationId: 1,
    sitCoordinates: [
      { longitude: 0, latitude: 0 }
    ],
    radius: ''
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);

  useEffect(() => {
    if (siteId) {
      setIsUpdating(true);
      getSiteById(siteId)
        .then((data) => setSite(data))
        .catch((err) => console.log(err));
    }
    getAllTheVehicles()
      .then((data) => setVehicles(data))
      .catch((err) => console.log(err));
  }, [siteId]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const action = isUpdating ? updateSite : createSite;
    action(site)
      .then((res) => {
        if (selectedVehicles.length > 0) {
          return assignVehiclesToSite(res.id, selectedVehicles);
        }
        return res;
      })
      .then((res) => {
        console.log(res);
        navigate('/path-to-redirect');  // Change this to the appropriate path
      })
      .catch((err) => console.log(err));
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSite((prevSite) => ({
      ...prevSite,
      [name]: value
    }));
  }

  const handleCoordinatesChange = (index, key, value) => {
    const newCoordinates = [...site.sitCoordinates];
    newCoordinates[index][key] = value;
    setSite((prevSite) => ({
      ...prevSite,
      sitCoordinates: newCoordinates
    }));
  }

  const addCoordinate = () => {
    setSite((prevSite) => ({
      ...prevSite,
      sitCoordinates: [...prevSite.sitCoordinates, { longitude: 0, latitude: 0 }]
    }));
  }

  const handleVehicleSelection = (event) => {
    const { options } = event.target;
    const selected = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }
    setSelectedVehicles(selected);
  }

  return (
    <div>
      <h1>{isUpdating ? 'Update Site' : 'Add Site'}</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Site Id:
          <input type="text" name="siteId" value={site.siteId} onChange={handleInputChange} disabled={isUpdating} />
        </label>
        <br />
        <label>
          Name:
          <input type="text" name="name" value={site.name} onChange={handleInputChange} />
        </label>
        <br />
        <label>
          Description:
          <input type="text" name="description" value={site.description} onChange={handleInputChange} />
        </label>
        <br />
        <label>
          Organization Id:
          <input type="number" name="organizationId" value={site.organizationId} onChange={handleInputChange} />
        </label>
        <br />
        <label>
          Radius:
          <input type="number" name="radius" value={site.radius} onChange={handleInputChange} />
        </label>
        <br />
        <label>
          Coordinates:
          {site.sitCoordinates.map((coordinate, index) => (
            <div key={index}>
              <input
                type="number"
                placeholder="Longitude"
                value={coordinate.longitude}
                onChange={(e) => handleCoordinatesChange(index, 'longitude', e.target.value)}
                disabled={site.radius !== ''}
              />
              <input
                type="number"
                placeholder="Latitude"
                value={coordinate.latitude}
                onChange={(e) => handleCoordinatesChange(index, 'latitude', e.target.value)}
                disabled={site.radius !== ''}
              />
            </div>
          ))}
          {site.radius === '' && (
            <button type="button" onClick={addCoordinate}>Add Coordinate</button>
          )}
        </label>
        <br />
        <label>
          Assign Vehicles:
          <select multiple={true} value={selectedVehicles} onChange={handleVehicleSelection}>
            {vehicles.map((vehicle) => (
              <option key={vehicle.id} value={vehicle.id}>
                {vehicle.name}
              </option>
            ))}
          </select>
        </label>
        <br />
        <input type="submit" value="Submit" />
      </form>
    </div>
  )
}

export default AddSite;
