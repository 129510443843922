import React, { useRef, useState } from 'react';
import axios from 'axios';
import './driver.css'
import config from '../../config.json';
import DashboardHeader from '../../components/header/DashboardHeader';
import { Paper, Button, TextField, Grid, Box, Avatar, InputLabel, Divider, Checkbox, FormControlLabel, Select, FormControl, MenuItem } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

async function addDriver(driver) {
  try {
    const response = await axios.post(`${config.baseURL}/driver`, driver, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.log("Error in adding driver :", error);
    throw error;
  }
}

async function updateDriver(driver) {
  try {
    const response = await axios.put(`${config.baseURL}/driver`, driver, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.log("Error in adding driver :", error);
    throw error;
  }
}


async function getDriverById(id) {
  try {
    const response = await axios.get(`${config.baseURL}/driver/id?id=${id}`, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
  
}


async function uploadDocument(document,associateEntityId) {
  const url = `${config.baseURL}/upload/image?name=photo&associateEntityId=${associateEntityId}&associatedWith=driver`;

  const formData = new FormData();
  formData.append('image', document);

  try {
    const response = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true
    });

    if (response.status !== 200) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    return response.data;
  } catch (error) {
    console.error('Error uploading document:', error);
    throw error;
  }
}


function AddDriver() {
  const [driver, setDriver] = useState({
    driverId: '',
    organizationId: 1, // Default value
    firstName: '',
    lastName: '',
    email: '',
    phone_number: '',
    driving_license_number: '',
    dob: '',
    current_address: '',
    current_city: '',
    current_pincode: '',
    permanent_address: '',
    permanent_city: '',
    permanent_pincode: '',
    description: ''
  });
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  // const [filePreview, setFilePreview] = useState(null);
  const [sameAsCurrent, setSameAsCurrent] = useState(false);
  const [dropdownVisible,setDropdownVisible]=useState(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState('');
  // const fileInputRefs = {
  //   'Photo Id': useRef(null),
  //   'Driving License': useRef(null),
  //   'Aadhar Proof': useRef(null),
  //   'Others': useRef(null),
  // };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDriver({ ...driver, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    console.log("selected file",file)
  };
  // dropdown for documents select 
  const toggleDocumentDropdown=()=>{
    setDropdownVisible(!dropdownVisible)
  }

  const handleMenuItemClick = (event) => {
    const selectedType = event.target.innerText;
    setSelectedDocumentType(selectedType);
    // fileInputRefs[selectedType].current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let response= await addDriver(driver);
      setUploadStatus('Driver added successfully');

      if (file) {
        setUploadStatus('Uploading document...');
        await uploadDocument(file,response.id);
        setUploadStatus('Document uploaded successfully');
      } else {
        setUploadStatus('No document to upload');
      }
    } catch (error) {
      setUploadStatus('Error in adding driver or uploading document');
      console.error(error);
    }
  };

  const gridContainerStyle={
    width:"100%",
    height:"100%",
    paddingLeft:"24px",
    paddingRight:"13px",
    paddingTop:"80px"
  }
  const paperStyles={
    marginTop:"35px",
    marginLeft:"20px",
    marginRight:"20px",
    borderRadius:"20px"

  }

  return (
    <div>
      <DashboardHeader title="Add Driver Details"/>
      <Paper style={paperStyles}>
      <div className='org-heading form_header'><p>Driver Details</p></div>
        <Box className="avatar_container">
        
        <input
            id='photo-uploader'
            type='file'
            hidden
            onChange={handleFileChange}
          />
        <label htmlFor="photo-uploader">
            <Avatar
              sx={{
                marginTop: "24px",
                marginLeft: "482px",
                // marginRight:"470px",
                width: "160px",
                height: "160px",
                cursor: 'pointer'
              }}
            >
              {/* {filePreview ? (
                <img src={filePreview} alt="Selected" style={{ width: '100%', height: '100%' }} />
              ) : (
                <>
                  <PhotoCameraIcon sx={{width:"62px",height:"62px",borderColor:"black",marginLeft:"12px"}}/>
                   <AddCircleOutlineIcon sx={{color:"black",marginTop:"140px"}} />
                </>
              )} */}
            </Avatar>
          </label>
        </Box>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={1} style={gridContainerStyle}>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="First Name " sx={{ fontWeight: "400",size:"24px", paddingBottom: "8px" ,color:"black" }}>First Name<svg width="15" height="12" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 0L10.9098 6.69208L17.6603 5L12.8197 10L17.6603 15L10.9098 13.3079L9 20L7.09017 13.3079L0.339746 15L5.18034 10L0.339746 5L7.09017 6.69208L9 0Z" fill="#EE3B2B"/>
</svg>
</InputLabel>
              <TextField
                name="firstName"
                label=" Type First Name"
                value={driver.firstName}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                   borderRadius:"16px"
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: 'black', // Thicker border
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="last name" sx={{ fontWeight: "400",size:"24px", paddingBottom: "8px" ,color:"black" }}>Last Name <svg width="15" height="12" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 0L10.9098 6.69208L17.6603 5L12.8197 10L17.6603 15L10.9098 13.3079L9 20L7.09017 13.3079L0.339746 15L5.18034 10L0.339746 5L7.09017 6.69208L9 0Z" fill="#EE3B2B"/>
</svg>
            
            </InputLabel>
              <TextField
                name="lastName"
                label="Type Last Name"
                value={driver.lastName}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                   borderRadius:"16px"
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: 'black', // Thicker border
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="driverId " sx={{ fontWeight: "400",size:"24px", paddingTop: "5px" ,color:"black" }}>Driver ID <svg width="15" height="12" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 0L10.9098 6.69208L17.6603 5L12.8197 10L17.6603 15L10.9098 13.3079L9 20L7.09017 13.3079L0.339746 15L5.18034 10L0.339746 5L7.09017 6.69208L9 0Z" fill="#EE3B2B"/>
</svg></InputLabel>
              <TextField
                name="driverId"
                label="Type Driver ID"
                value={driver.driverId}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                   borderRadius:"16px"
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: 'black', // Thicker border
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="driving_license_id" sx={{ fontWeight: "400",size:"24px", paddingBottom: "8px" ,color:"black" }}>Driving License ID<svg width="15" height="12" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 0L10.9098 6.69208L17.6603 5L12.8197 10L17.6603 15L10.9098 13.3079L9 20L7.09017 13.3079L0.339746 15L5.18034 10L0.339746 5L7.09017 6.69208L9 0Z" fill="#EE3B2B"/>
</svg></InputLabel>
              <TextField
                name="driving_license_number"
                label="Type License Number"
                value={driver.driving_license_number}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                   borderRadius:"16px"
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: 'black', // Thicker border
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="Phone" sx={{ fontWeight: "400",size:"24px", paddingBottom: "8px" ,color:"black" }}>Phone <svg width="15" height="12" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 0L10.9098 6.69208L17.6603 5L12.8197 10L17.6603 15L10.9098 13.3079L9 20L7.09017 13.3079L0.339746 15L5.18034 10L0.339746 5L7.09017 6.69208L9 0Z" fill="#EE3B2B"/>
</svg></InputLabel>
              <TextField
                name="phone_number"
                label="Type Phone Number"
                value={driver.phone_number}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                   borderRadius:"16px"
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: 'black', // Thicker border
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="email" sx={{ fontWeight: "400",size:"24px", paddingBottom: "8px" ,color:"black" }}>Email</InputLabel>
              <TextField
                name="email"
                label="Type Email Address"
                value={driver.email}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                   borderRadius:"16px"
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: 'black', // Thicker border
                    },
                  },
                }}
              />
            </Grid>
           
            <Grid item xs={12} sm={7}>
            <InputLabel htmlFor="dob" sx={{ fontWeight: "400",size:"24px", paddingBottom: "8px" ,color:"black" }}>Date Of Birth<svg width="15" height="12" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 0L10.9098 6.69208L17.6603 5L12.8197 10L17.6603 15L10.9098 13.3079L9 20L7.09017 13.3079L0.339746 15L5.18034 10L0.339746 5L7.09017 6.69208L9 0Z" fill="#EE3B2B"/>
</svg></InputLabel>
              <TextField
                name="dob"
                label="Date of Birth"
                type="date"
                value={driver.dob}
                onChange={handleInputChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
                margin="normal"
                InputProps={{
                  style: {
                   borderRadius:"16px"
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: 'black', // Thicker border
                    },
                  },
                }}
              />
            </Grid>
            <Divider/> 
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="current_address" sx={{ fontWeight: "400", size: "24px", paddingBottom: "8px", color: "black" }}>Current Address <svg width="15" height="12" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 0L10.9098 6.69208L17.6603 5L12.8197 10L17.6603 15L10.9098 13.3079L9 20L7.09017 13.3079L0.339746 15L5.18034 10L0.339746 5L7.09017 6.69208L9 0Z" fill="#EE3B2B"/>
</svg></InputLabel>
              <TextField
                name="current_address"
                label="Type Current Address"
                value={driver.current_address}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={4}
                margin="normal"
                InputProps={{
                  style: {
                    borderRadius: "16px"
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: 'black', // Thicker border
                    },
                  },
                }}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
            {/* <FormControlLabel
          control={
            <Checkbox
              // checked={sameAsCurrent}
              // onChange={handleCheckboxChange}
              name="sameAsCurrent"
              color="primary"
            />
          }
          label="Same as Current Address"
        /> */}
              <InputLabel htmlFor="permanent_address" sx={{ fontWeight: "400", size: "24px", paddingBottom: "8px", color: "black" }}>Permanent Address<svg width="15" height="12" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 0L10.9098 6.69208L17.6603 5L12.8197 10L17.6603 15L10.9098 13.3079L9 20L7.09017 13.3079L0.339746 15L5.18034 10L0.339746 5L7.09017 6.69208L9 0Z" fill="#EE3B2B"/>
</svg></InputLabel>
              <TextField
                name="permanent_address"
                label="Type Permanent Address"
                value={driver.permanent_address}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={4}
                margin="normal"
                InputProps={{
                  style: {
                    borderRadius: "16px"
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: 'black', // Thicker border
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="Current City" sx={{ fontWeight: "400",size:"24px", paddingBottom: "8px" ,color:"black" }}>Current City<svg width="15" height="12" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 0L10.9098 6.69208L17.6603 5L12.8197 10L17.6603 15L10.9098 13.3079L9 20L7.09017 13.3079L0.339746 15L5.18034 10L0.339746 5L7.09017 6.69208L9 0Z" fill="#EE3B2B"/>
</svg></InputLabel>
              <TextField
                name="current_city"
                label="Type Current City"
                value={driver.current_city}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                   borderRadius:"16px"
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: 'black', // Thicker border
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="permanent city" sx={{ fontWeight: "400",size:"24px", paddingBottom: "8px" ,color:"black" }}>Permanent City<svg width="15" height="12" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 0L10.9098 6.69208L17.6603 5L12.8197 10L17.6603 15L10.9098 13.3079L9 20L7.09017 13.3079L0.339746 15L5.18034 10L0.339746 5L7.09017 6.69208L9 0Z" fill="#EE3B2B"/>
</svg></InputLabel>
              <TextField
                name="permanent_city"
                label="Type Permanent City"
                value={driver.permanent_city}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                   borderRadius:"16px"
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: 'black', // Thicker border
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="current pincode" sx={{ fontWeight: "400",size:"24px", paddingBottom: "8px" ,color:"black" }}>Current Pincode<svg width="15" height="12" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 0L10.9098 6.69208L17.6603 5L12.8197 10L17.6603 15L10.9098 13.3079L9 20L7.09017 13.3079L0.339746 15L5.18034 10L0.339746 5L7.09017 6.69208L9 0Z" fill="#EE3B2B"/>
</svg></InputLabel>
              <TextField
                name="current_pincode"
                label="Type Current Pincode"
                value={driver.current_pincode}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                   borderRadius:"16px"
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: 'black', // Thicker border
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="permanent pincode" sx={{ fontWeight: "400",size:"24px", paddingBottom: "8px" ,color:"black" }}>Permanent Pincode<svg width="15" height="12" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 0L10.9098 6.69208L17.6603 5L12.8197 10L17.6603 15L10.9098 13.3079L9 20L7.09017 13.3079L0.339746 15L5.18034 10L0.339746 5L7.09017 6.69208L9 0Z" fill="#EE3B2B"/>
</svg></InputLabel>
              <TextField
                name="permanent_pincode"
                label="Type Permanent Pincode"
                value={driver.permanent_pincode}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                InputProps={{
                  style: {
                   borderRadius:"16px"
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: 'black', // Thicker border
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={7}>
            <InputLabel htmlFor="First Name" sx={{ fontWeight: "400",size:"24px", paddingBottom: "8px" ,color:"black" }}>Description</InputLabel>
              <TextField
                name="description"
                label="Write Description"
                value={driver.description}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={4}
                margin="normal"
                InputProps={{
                  style: {
                   borderRadius:"16px",
                   width:'172%'
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: 'black',width:"100%", // Thicker border
                    },
                  },
                }}
              />
                   <div className='org-heading form_header'><p>Documents</p></div>
            </Grid>
            
            <Grid item xs={12}>
            {/* <h5 className='proofs_heading'>Upload Documents (ID Proof, License, Address Proof, Others)</h5> */}
            <Button
            onClick={toggleDocumentDropdown}
            style={{color:"white",marginTop:"24px",background:"rgba(32, 168, 224, 1)"}}
            >
              + Attach Documents
            </Button>
             </Grid>
             {dropdownVisible && (
               <FormControl>
                <InputLabel id="select id proof">select Id proof</InputLabel>
                <Select
                id="select id proof"
                style={{width:"180px"}}
                value={selectedDocumentType}
                onChange={handleMenuItemClick}
                >
                  <MenuItem value="Photo Id" >Photo Id</MenuItem>
                  <MenuItem value="Driving License" >Driving License</MenuItem>
                  <MenuItem value="Aadhar Proof" >Aadhar Proof</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
               </FormControl>
            )}
              {/* {Object.keys(fileInputRefs).map((key) => ( */}
        <input
          // key={key}
          type="file"
          // ref={fileInputRefs[key]}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      {/* ))} */}
              {/* <div style={{paddingTop:"40px",paddingBottom:"50x", display:"flex", flexDirection:"row" ,gap:"24px",paddingLeft:"192px",marginBottom:"24px"}}>
              <Grid item xs={12} sm={6}>
              <Button  variant="contained"
               color="primary"
                style={{textTransform:"none",height:"56px",width:"288px",borderRadius:"16px",fontWeight:"600",fontSize:"32px"}}
               >
              Cancel
              </Button>
            </Grid> 
            <Grid item xs={12} sm={6}>
              <Button type="submit" 
              variant="contained"
               color="primary"
               style={{textTransform:"none",height:"56px",width:"288px",borderRadius:"16px",fontWeight:"600",fontSize:"32px"}}
               >
                Submit
              </Button>
            </Grid> 
              </div> */}
              <div className="addbutton_container" style={{width:"1132px"}}>
  
  <button className='customButton_add'style={{background:"rgba(242, 242, 243, 1)"}} > Cancel </button>
  <button className='customButton_add'>Submit</button>
  </div>
      
          </Grid>

        </form>
        <p>{uploadStatus}</p>
      </Paper>
    </div>
  );
}

export default AddDriver;






