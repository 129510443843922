import { green, grey } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./layout/Layout";
import { UserProvider } from "./pages/login/UserContextPersistent";

const theme = createTheme({
  palette: {
    primary: {
      main: grey[900],
    },
    secondary: {
      main: green[500],
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <Router>
          <Layout />
        </Router>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;
