import {DirectionsCar,Hail, NoCrash, NoTransfer,Person, Route, Notifications, CalendarToday} from '@mui/icons-material'

export const card = [
  {
    title: "Vehicles",
    icon: <DirectionsCar style={{ fontSize: "40px" }} />,
    name: "vehicles",
  },
  {
    title: "Active Vehicles",
    icon: <DirectionsCar style={{ fontSize: "40px"}} />,
    name: "activevehicles",
  },
  {
    title: "Inactive Vehicles",
    icon: <DirectionsCar style={{ fontSize: "40px" }} />,
    name: "inactivevehicles",
  },
  {
    title: "Stops",
    icon: <Hail style={{ fontSize: "40px" }} />,
    name: "stops",
  },
  {
    title: "Routes",
    icon: <Route style={{ fontSize: "40px" }} />,
    name: "routes",
  },
  {
    title: "Schedules",
    icon: <CalendarToday style={{ fontSize: "40px" }} />,
    name: "schedules",
  },
  
  // {
  //   title: "Total Users",
  //   icon: <Person style={{ fontSize: "40px" }} />,
  //   name: "users",
  // },
  
  {
    title: "All Alerts",
    icon: <Notifications style={{ fontSize: "40px" }} />,
    name: "totalalerts",
  },
  
  {
    title: "Vehicle Alerts",
    icon: <Notifications style={{ fontSize: "40px" }} />,
    name: "totalalerts",
  },

  {
    title: "User Alerts",
    icon: <Notifications style={{ fontSize: "40px" }} />,
    name: "totalalerts",
  },

  {
    title: "Due-date Alerts",
    icon: <Notifications style={{ fontSize: "40px" }} />,
    name: "totalalerts",
  },
  
];

