import {
  Box,
  Container,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import "./role.css";

function RoleAccessHeader({
  selectedRoleData,
  getAllRolesData,
  totalFeatures,
}) {
  console.log("Selected Role Data:", selectedRoleData);
  return (
    <>
      <Box className="role_info_container">
        <div className="heading_container">
          <h2 className="assign_heading">
            Assign the Feature Access for the Role
          </h2>
          <span className="total_features">{totalFeatures} Features</span>
        </div>
        <Divider />
        <div className="role_des_container">
          <div>
            <h2 className="select_heading">Selected Role</h2>
            <TextField
              size="small"
              id="search-role"
              variant="outlined"
              className="custom-text-field select_role"
              value={selectedRoleData.name}
              //  input label styles
              InputLabelProps={{
                style: { color: "black" },
              }}
            />
          </div>
          <div className="description_style">
            <h2 className="des_heading">Role Description</h2>
            <span className="description">{selectedRoleData?.description}</span>
          </div>
        </div>
      </Box>
    </>
  );
}

export default RoleAccessHeader;
