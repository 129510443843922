import React, { useEffect, useState } from 'react'
import axios from 'axios'
import config from '../../../config.json' 
import { useParams } from 'react-router'
import DashboardHeader from '../../../components/header/DashboardHeader';
import FeatureAssignmentHeader from './FeatureAssignmentHeader';
import { Paper } from '@mui/material';
import FeatureAssignmentTable from './FeatureAssignmentTable';
import { useLocation } from 'react-router-dom';

let currentRole;
let currentRoleFeatures = [];
let availableFeatures = [];
async function getFeaturesForOrganization(id) {
  try {
    const response = await axios.get(`${config.baseURL}/organization/features/${id}`, {
      withCredentials:true
    })
    console.log(response.data,"this is response data for organization features")
    return response.data;
  } catch (error) {
    console.log("Error fetching the role by id:", error);
    throw error
  }
}



async function getAllTheAvailableFeatures() {
  try {
    const response = await axios.get(`${config.baseURL}/feature/`, {
      withCredentials: true
    });
    return response.data
  } catch (error) {
    console.log("Error fetching All the available features for user:", error);
    throw error;
  }
}


async function assignFeatureToOrganization(organizationId, featureIds) {
  try {
    const response = await axios.put(`${config.baseURL}/organization/features`, {
      organizationId: Number(organizationId),
      featureIds:featureIds
    })
    return response.data
  } catch (error) {
    console.log("Error assigning the features to the role:", error);
    throw error
  }
}

async function getAllCommonFeatures() {
  return currentRoleFeatures.filter(feature =>
    availableFeatures.some(availableFeature => availableFeature.id === feature.id));
}
async function getAllOrganizations() {
  try {
    const response = await axios.get(`${config.baseURL}/organization/`, {
      withCredentials: true
    });
    console.log("this is features",response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching features:', error)
    throw error;
  }
}


const FeatureAssignment = () => {

  const [selectedOrganizationFeatures,setSelectedOrganizationFeature]=useState({})
  const [getAllOrganizationsData,setGetAllOrganizationsData]=useState([])
  const [totalFeatures, setTotalFeatures] = useState([])
    const [loading, setLoading] = useState(true); // New state for loading

  const location = useLocation();
  const { id } = useParams();
  // Now you can use the id as needed in your component
  console.log("Received id:", id);
  
  useEffect(() => {
    console.log("use effect");
    if (id) {
      async function fetchData() {
        console.log("id", id);
        try {
          const response = await getFeaturesForOrganization(id);
          setSelectedOrganizationFeature(response);
          console.log("Fetched features for organization:", response);

          const orgData = await getAllOrganizations();
          console.log('All organizations:', orgData);
          setGetAllOrganizationsData(orgData.organizations);

          const featuresData = await getAllTheAvailableFeatures();
          console.log('All available features:', featuresData);
          setTotalFeatures(featuresData);
        } catch (error) {
          console.log("Error fetching the role by id:", error);
        } finally {
          setLoading(false);
        }
        getAllOrganizations()
        .then(data => {
          console.log('All roles:', data); // Add this line to console log the data
          setGetAllOrganizationsData(data.organizations);
        })
        .catch(error => console.error('Error fetching roles:', error));
      }
      fetchData();
    }
  }, [id]);

   if (loading) {
    return <div>Loading...</div>; // Render a loading indicator while data is being fetched
  }
  return (
    <>
    <DashboardHeader title="Role Access Assignment"/>
    <Paper style={{margin:"20px",height:"100%",borderRadius:"20px"}}>
    <FeatureAssignmentHeader selectedOrganizationFeature={selectedOrganizationFeatures}
      getAllOrganizationsData={getAllOrganizationsData}
      totalFeatures={totalFeatures.length}
      />
    <div style={{marginTop:"30px"}}>
    <FeatureAssignmentTable getAllTheAvailableFeatures={getAllTheAvailableFeatures}
    getAllOrganizations={getAllOrganizations}
    selectedOrganizationFeatures={selectedOrganizationFeatures}
    assignFeatureToOrganization={assignFeatureToOrganization}
     />
    </div>
    </Paper>
    </>
  )
}

export default FeatureAssignment