import { Box, MenuItem, Select } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DashboardHeader from '../../../components/header/DashboardHeader'
import { useLocation, useNavigate } from "react-router-dom";
import {
  StyledFormButton,
  StyledFormControl,
  StyledFormInput,
  StyledFormInputLabel,
  StyledPaper,
} from "../../../components/formComponents";
import {
  contactFormFields,
  getTransformedFormData,
  getTransformedPayload,
  transportFormFields,
  orgFormFields,
  org_base_url,
} from "../utils";
import "./AddOrganization.css";
import useAdd from "../../../hooks/useAdd";
import useUpdate from "../../../hooks/useUpdate";

export default function AddOrganization() {
  const location = useLocation();

  const [formData, setFormData] = useState({ organizationStatus: "INACTIVE" });

  const navigate = useNavigate();
  const { addEntity } = useAdd(org_base_url);
  const { updateEntity } = useUpdate(org_base_url);

  useEffect(() => {
    const fetchOrgDataById = async (id) => {
      try {
        const updateUrl = `${org_base_url}/${id}`;
        console.log("updateUrl", updateUrl);
        const response = await axios.get(updateUrl);
        const transformedFormData = getTransformedFormData(
          response.data.organization
        );
        console.log("trans", transformedFormData)
        setFormData(transformedFormData);
        console.log("response", response.data.organization);
      } catch (error) {
        console.log(error);
      }
    };
    if (location.state) {
      console.log("location", location.state);
      fetchOrgDataById(location.state);
    }
  }, [location.state]);

  const handleInputValue = (e, fieldName) => {
    const clonedFormData = { ...formData };
    const inputValue = e.target.value;
    clonedFormData[fieldName] = inputValue;
    setFormData(clonedFormData);
  };

  const handleSubmit = async () => {
    const transformedPayload = getTransformedPayload(formData);

    if (location.state) {
      const { responseData } = await updateEntity(
        { ...transformedPayload, id: location.state },
        location.state
      );
      

      if (responseData?.data?.organization) {
        console.log("responseDAta.org");
        navigate("/Organization");
      }
    } else {
      const { responseData } = await addEntity(transformedPayload);
      console.log("response----", responseData.data);
      if (responseData) {
        navigate("/admin/add", {
          state: {
            orgName: responseData.data.organization.name,
            orgId: responseData.data.organization.id,
            name: formData.transportInchargeName,
            email: formData.transportInchargeEmail
          }
        });
      }
    }
  };

  const handleCancel = () => {
    navigate("/Organization");
  };

  console.log("formdata", formData);

  return (
    <>
    
<DashboardHeader title="Organizations > Update"/> 
    <StyledPaper>

    <Box className="addbutton_container" sx={{marginRight:"20px"}}>
  <button
    style={{ background: 'rgba(32, 168, 224, 1)', color: 'rgba(255, 255, 255, 1)', }}
    className="customButton_add"
  >
    + Assign Features
  </button>
</Box>



      <div className="add-org-form" style={{marginTop:"-32px"}}>

      <div>
          <div className="form_header" > <div className="org-form-heading form_title">Organization Details </div></div>

          <div className="form-container">
          {orgFormFields.map((item) => { 
            return (
              <FormFieldWrapper 
                item={item}
                handleInputValue={handleInputValue}
                formData={formData}
                key={item.id}
                
              />
            );
          })}
        
        </div>

          <div className="org-form-heading">
          <div className="form_header"> <div className="org-form-heading form_title">Contact Details </div></div>
          </div>
          
          <div className="form-container">
          {contactFormFields.map((item) => {
            return (
              <FormFieldWrapper
                item={item}
                handleInputValue={handleInputValue}
                formData={formData}
                key={item.id}
              />
            );
          })}
          </div>
        </div>
       

<div  className="no-asterisk">
<div className="form_header"> <div className="org-form-heading form_title">Transport In-Charge Details(Optional) </div></div>
<div className="form-container">
{transportFormFields.map((item) => {
            return (
              <FormFieldWrapper
                item={item}
                handleInputValue={handleInputValue}
                formData={formData}
              />
              
            );
            
          })}
</div>
          
</div>
       
      </div>


      <div className="addbutton_container"style={{marginRight:"20px",marginTop:'-20px'}}>
      <button style={{background:'rgba(242, 242, 243, 1)'}} variant="outlined" onClick={handleCancel}   className="customButton_add">
          Cancel
          </button>
        <button variant="contained" onClick={handleSubmit}   className="customButton_add">
          Submit
        </button>
      </div>
    </StyledPaper>
    </>
  );
}

const FormFieldWrapper = ({ handleInputValue, item, formData }) => {
  return (
    <StyledFormControl variant="standard" fullWidth required>
      <StyledFormInputLabel shrink htmlFor={item.id}>
        {item.label}
      </StyledFormInputLabel>
      {item.type === "select" ? (
        <Select
          id={item.id}
          value={formData[item.fieldName]}
          onChange={(e) => handleInputValue(e, item.fieldName)}
          input={<StyledFormInput />}
        >
          <MenuItem value="ACTIVE">Active</MenuItem>
          <MenuItem value="INACTIVE">Inactive</MenuItem>
        </Select>
      ) : (
        <StyledFormInput
          id={item.id}
          onChange={(e) => handleInputValue(e, item.fieldName)}
          value={formData[item.fieldName] || ""}
        />
      )}
    </StyledFormControl>
  );
};
