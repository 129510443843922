 import config from "../../config.json"
 export const stop_base_url = `${config.baseURL}/stop/`;
 export const route_base_url=`${config.baseURL}/route/`
 export const schedule_base_url=`${config.baseURL}/schedule/id`
 export const  vehicle_base_url=`${config.baseURL}/vehicle/`
// /**
//  * this func. will combine add1 and add2 into a single key i.e. address
//  * @param {object} formData data to transform
//  * @returns transformedData
//  */
// export const getTransformedPayload = (formData) => {
//   const clonedFormData = {...formData}
//   clonedFormData.address = formData.address_1 + "/n" + formData.address_2
//   delete clonedFormData.address_1;
//   delete clonedFormData.address_2;
//   return clonedFormData;
// }

// export const getTransformedFormData = (apiData) => {
//   const clonedapiData = {...apiData}
//   const arr = clonedapiData.address.split("/n")

//   clonedapiData.address_1 = arr[0]
//   clonedapiData.address_2 = arr[1]

//   delete clonedapiData.address
 
//   return clonedapiData
// }

// export const orgFormFields = [
//   {
//     id: "org-name",
//     label: "Organization Name",
//     fieldName: "name",
//     type: "text",
//   },
//   {
//     id: "org-domain",
//     label: "Organization Domain",
//     fieldName: "domain",
//     type: "text",
//   },

//   {
//     id: "org-status",
//     label: "Organization Status",
//     fieldName: "organizationStatus",
//     type: "select",
//   },
//   {
//     id: "org-address1",
//     label: "Address Line 1",
//     fieldName: "address_1",
//     type: "text",
//   },
//   {
//     id: "org-address2",
//     label: "Address Line 2",
//     fieldName: "address_2",
//     type: "text",
//   },
//   { id: "org-city", label: "City", fieldName: "city", type: "text" },
//   { id: "org-state", label: "State", fieldName: "state", type: "text" },
//   { id: "org-country", label: "Country", fieldName: "country", type: "text" },
//   { id: "org-pincode", label: "Pincode", fieldName: "pincode", type: "text" },
// ];

// export const contactFormFields = [
//   {
//     id: "org-contactName",
//     label: "Full Name",
//     fieldName: "contactName",
//     type: "text",
//   },
//   {
//     id: "org-contactPhone",
//     label: "Phone Number",
//     fieldName: "contactPhone",
//     type: "text",
//   },
//   {
//     id: "org-contactEmail",
//     label: "Email",
//     fieldName: "contactEmail",
//     type: "text",
//   },
//   {
//     id: "org-contactDepartment",
//     label: "Department",
//     fieldName: "contactDepartment",
//     type: "text",
//   },
//   {
//     id: "org-contactDesignation",
//     label: "Designation",
//     fieldName: "contactDesignation",
//     type: "text",
//   },
// ];

// export const transportFormFields = [
//   {
//     id: "org-transportEmail",
//     label: "Email",
//     fieldName: "transportEmail",
//     type: "text",
//   },
//   {
//     id: "org-transportPhone",
//     label: "Phone Number",
//     fieldName: "transportPhone",
//     type: "text",
//   },
// ];
