import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import Organization from "../organization/Organization";
import config from "../../config.json";

const ScheduleSelectionComponent = ({
  vehicleId,
  onSchedulesSelected,
  closeWindow,
}) => {
  const [schedules, setSchedules] = useState([]);
  const [selectedSchedules, setSelectedSchedules] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [schedulesResponse] = await Promise.all([
          axios.get(`${config.baseURL}/schedule/organization`, {
            withCredentials: true,
          }),
          ...(vehicleId
            ? [
                axios.get(`${config.baseURL}/vehicle/id`, {
                  params: {
                    vehicleId,
                    OrganizationId: 17,
                  },
                  withCredentials: true,
                }),
              ]
            : []),
        ]);
        console.log(schedulesResponse.data,"-------------------");

        setSchedules(schedulesResponse.data);

        if (vehicleId) {
          const vehicleResponse = await axios.get(
            `${config.baseURL}/vehicle/id`,
            {
              params: {
                vehicleId,
                OrganizationId: 17,
              },
              withCredentials: true,
            }
          );
          const preSelectedSchedules = {};
          vehicleResponse.data.vehicle.vehicleSchedule.forEach((vs) => {
            preSelectedSchedules[vs.scheduleId] = true;
          });
          setSelectedSchedules(preSelectedSchedules);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load schedules. Please try again.");
        setLoading(false);
      }
    };

    fetchData();
  }, [vehicleId]);

  const handleCheckboxChange = (scheduleId) => {
    setSelectedSchedules((prev) => ({
      ...prev,
      [scheduleId]: !prev[scheduleId],
    }));
  };

  const handleSubmit = () => {
    const selectedScheduleIds = Object.keys(selectedSchedules)
      .filter((id) => selectedSchedules[id])
      .map((id) => parseInt(id));
    onSchedulesSelected(selectedScheduleIds);
    closeWindow();
  };

  useEffect(() => {
    console.log(selectedSchedules,'selectedSchedules');
  }, [selectedSchedules]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Paper className="container mx-auto p-4">
      <Typography variant="h4" className="mb-4">
        Select Schedules
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>End Time</TableCell>
            <TableCell>Repetition</TableCell>
            <TableCell>Select</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schedules.map((schedule) => (
            <TableRow key={schedule.id}>
              <TableCell>{schedule.name}</TableCell>
              <TableCell>{schedule.description}</TableCell>
              <TableCell>
                {new Date(schedule.startTime).toLocaleString()}
              </TableCell>
              <TableCell>
                {new Date(schedule.endTime).toLocaleString()}
              </TableCell>
              <TableCell>{schedule.repitation}</TableCell>
              <TableCell>
                <Checkbox
                  checked={selectedSchedules[schedule.id] || false}
                  onChange={() => handleCheckboxChange(schedule.id)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button
        variant="contained"
        color="primary"
        className="mt-4"
        onClick={handleSubmit}
      >
        Confirm Selection
      </Button>
    </Paper>
  );
};

export default ScheduleSelectionComponent;
