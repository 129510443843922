import config from "../../config.json";

export const org_base_url = `${config.baseURL}/organization`;
export const vehicle_base_url = `${config.baseURL}/vehicle/organization`;
export const route_base_url = `${config.baseURL}/route/`;

export const orgStatus = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};
export const vehicleStatus = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  DEAD: "DEAD",
};

const sortNumAsc = (a, b, sortBy) => {
  return a[sortBy] < b[sortBy] ? -1 : 1;
};
const sortNumDesc = (a, b, sortBy) => {
  return a[sortBy] > b[sortBy] ? -1 : 1;
};

const sortStringAsc = (a, b, sortBy) => {
  return a[sortBy].toLowerCase() < b[sortBy].toLowerCase() ? -1 : 1;
};
const sortStringDesc = (a, b, sortBy) => {
  return a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? -1 : 1;
};
/**
 *
 * @param {object} a element to sort
 * @param {object} b element to sort
 * @param {string} sortBy key on which sorting will be done
 * @param {string} sortOrder will be asc or desc
 * @return {array} return sorted array ̥
 */
export const getSortedData = (a, b, sortBy, sortOrder) => {
  if (typeof a[sortBy] === "number") {
    if (sortOrder === "asc") {
      return sortNumAsc(a, b, sortBy);
    } else {
      return sortNumDesc(a, b, sortBy);
    }
  } else if (typeof a[sortBy] === "string") {
    if (sortOrder === "asc") {
      return sortStringAsc(a, b, sortBy);
    } else {
      return sortStringDesc(a, b, sortBy);
    }
  }
};

/**
 * this func. will combine add1 and add2 into a single key i.e. address
 * @param {object} formData data to transform
 * @returns transformedData
 */
export const getTransformedPayload = (formData) => {
  const clonedFormData = { ...formData };
  clonedFormData.address = formData.address_1 + "/n" + formData.address_2;
  delete clonedFormData.address_1;
  delete clonedFormData.address_2;
  return clonedFormData;
};

export const getTransformedFormData = (apiData) => {
  const clonedapiData = { ...apiData };
  const arr = clonedapiData.address.split("/n");

  clonedapiData.address_1 = arr[0];
  clonedapiData.address_2 = arr[1];

  delete clonedapiData.address;

  return clonedapiData;
};
export const orgFormFields = [
  {
    id: "org-name",
    label: "Organization Name",
    fieldName: "name",
    type: "text",
  },
  {
    id: "org-address1",
    label: "Address Line 1",
    fieldName: "address_1",
    type: "text",
  },
  {
    id: "org-domain",
    label: "Organization Domain",
    fieldName: "domain",
    type: "text",
  },
  {
    id: "org-address2",
    label: "Address Line 2",
    fieldName: "address_2",
    type: "text",
    isRequired: false,
  },
  {
    id: "org-status",
    label: "Organization Status",
    fieldName: "organizationStatus",
    type: "select",
  },

  { id: "org-city", label: "City", fieldName: "city", type: "text" },
  { id: "org-country", label: "Country", fieldName: "country", type: "text" },
  { id: "org-state", label: "State", fieldName: "state", type: "text" },

  { id: "org-pincode", label: "Pincode", fieldName: "pincode", type: "text" },
];

export const contactFormFields = [
  {
    id: "org-contactName",
    label: "Full Name",
    fieldName: "contactName",
    type: "text",
  },
  {
    id: "org-contactEmployeeId",
    label: "Employee ID",
    fieldName: "contactEmployeeId",
    type: "text",
    isRequired: false,
  },

  {
    id: "org-contactEmail",
    label: "Email",
    fieldName: "contactEmail",
    type: "text",
  },
  {
    id: "org-contactPhone",
    label: "Phone Number",
    fieldName: "contactPhone",
    type: "text",
  },
  {
    id: "org-contactDepartment",
    label: "Department ",
    fieldName: "contactDepartment",
    type: "text",
    isRequired: false,
  },
  {
    id: "org-contactDesignation",
    label: "Designation",
    fieldName: "contactDesignation",
    type: "text",
    isRequired: false,
  },
];

export const transportFormFields = [
  {
    id: "org-transportName",
    label: "Full Name",
    fieldName: "transportInchargeName",
    type: "text",
  },

  {
    id: "org-transportEmployeeId",
    label: "Employee ID",
    fieldName: "transportInchargeEmployeeId",
    type: "text",
  },
  {
    id: "org-transportEmail",
    label: "Email",
    fieldName: "transportInchargeEmail",
    type: "text",
  },
  {
    id: "org-transportPhone",
    label: "Phone Number",
    fieldName: "transportInchargePhone",
    type: "text",
  },
];

export const vehicleFormFields = [
  {
    id: "vehicle-id",
    label: "Vehicle ID",
    fieldName: "device_id",
    type: "text",
    placeholder: "Type Vehicle ID",
  },
  {
    id: "vehicle-name",
    label: "Vehicle Name",
    fieldName: "name",
    type: "text",
    placeholder: "Type Vehicle Name",
  },
  {
    id: "vehicle-number",
    label: "Vehicle Number",
    fieldName: "VehicleNumber",
    type: "number",
    placeholder: "Type Vehicle Number",
  },
  {
    id: "routes",
    label: "Route (Optional)",
    fieldName: "routes",
    type: "select",
    placeholder: "Select Route",
  },
  // {
  //   id: "drivers",
  //   label: "Drivers (Optional)",
  //   fieldName: "drivers",
  //   type: "select",
  //   placeholder : "Select Driver"
  // }
];

export const vehicleSiteFormField = [
  {
    id: "sites",
    label: "Site (Optional)",
    fieldName: "sites",
    type: "select",
    placeholder: "Select Site",
  },
];

export const vehicleFormFieldsLeftLower = [
  {
    id: "make",
    label: "Make",
    fieldName: "Make",
    type: "text",
    placeholder: "Type Make",
  },
  {
    id: "chassis-number",
    label: "Chassis Number",
    fieldName: "chassisNumber",
    type: "text",
    placeholder: "Type Chassis Number",
  },
  {
    id: "insurance-policy-number",
    label: "Insurance Policy Number",
    fieldName: "insurancePolicyNumber",
    type: "text",
    placeholder: "Type Policy Number",
  },
];

export const vehicleFormFieldsRightLower = [
  {
    id: "model",
    label: "Model",
    fieldName: "Model",
    type: "text",
    placeholder: "Type Model",
  },
  {
    id: "year",
    label: "Year Of Manufacture",
    fieldName: "Year",
    type: "text",
    placeholder: "YYYY",
  },
  {
    id: "insurance-policy-expiry",
    label: "Insurance Policy Expiry Date",
    fieldName: "insurancePolicyExpiry",
    type: "text",
    placeholder: "DD/MM/YYYY",
  },
];

export const vehicleFormFieldsDesc = [
  {
    id: "description",
    label: "Description",
    fieldName: "description",
    type: "text",
    placeholder: "Write Description",
  },
];
