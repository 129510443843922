import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config.json";

// Functions to fetch alerts
async function getUserAlerts() {
  const response = await axios.get(`${config.baseURL}/alert/organization/user`, {
    withCredentials: true,
  });
  return response.data;
}

async function getVehicleAlerts() {
  const response = await axios.get(`${config.baseURL}/alert/organization/vehicle`, {
    withCredentials: true,
  });
  return response.data;
}

async function getExpiryAlerts() {
  const response = await axios.get(`${config.baseURL}/alert/organization/expiry`, {
    withCredentials: true,
  });
  return response.data;
}

const Alert = () => {
  const [userAlerts, setUserAlerts] = useState([]);
  const [vehicleAlerts, setVehicleAlerts] = useState([]);
  const [expiryAlerts, setExpiryAlerts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const fetchAlerts = async () => {
      const [user, vehicle, expiry] = await Promise.all([
        getUserAlerts(),
        getVehicleAlerts(),
        getExpiryAlerts()
      ]);
      setUserAlerts(user);
      setVehicleAlerts(vehicle);
      setExpiryAlerts(expiry);
    };
    fetchAlerts();
     const intervalId = setInterval(fetchAlerts, 60000);

  // Clear the interval when the component unmounts
  return () => clearInterval(intervalId);
  }, []);

  const getVisibleAlerts = () => {
    switch (selectedCategory) {
      case "user":
        return userAlerts;
      case "vehicle":
        return vehicleAlerts;
      case "expiry":
        return expiryAlerts;
      case "all":
      default:
        return [...userAlerts, ...vehicleAlerts, ...expiryAlerts];
    }
  };

  const openModal = (alert) => {
    setSelectedAlert(alert);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedAlert(null);
  };

  return (
    <div>
      <h1>Alerts</h1>
      <div>
        <p>Total Alerts: {userAlerts.length + vehicleAlerts.length + expiryAlerts.length}</p>
        <p>User Alerts: {userAlerts.length}</p>
        <p>Vehicle Alerts: {vehicleAlerts.length}</p>
        <p>Expiry Alerts: {expiryAlerts.length}</p>
      </div>
      <div>
        <label>
          <input
            type="radio"
            value="all"
            checked={selectedCategory === "all"}
            onChange={() => setSelectedCategory("all")}
          />
          All
        </label>
        <label>
          <input
            type="radio"
            value="user"
            checked={selectedCategory === "user"}
            onChange={() => setSelectedCategory("user")}
          />
          User
        </label>
        <label>
          <input
            type="radio"
            value="vehicle"
            checked={selectedCategory === "vehicle"}
            onChange={() => setSelectedCategory("vehicle")}
          />
          Vehicle
        </label>
        <label>
          <input
            type="radio"
            value="expiry"
            checked={selectedCategory === "expiry"}
            onChange={() => setSelectedCategory("expiry")}
          />
          Expiry
        </label>
      </div>
      <div>
        {getVisibleAlerts().map((alert) => (
          <div key={alert.id} onClick={() => openModal(alert)} style={{ border: '1px solid black', padding: '10px', margin: '10px', cursor: 'pointer' }}>
            <p><strong>Subject:</strong> {alert.subject}</p>
            <p><strong>Date:</strong> {new Date(alert.createdAt).toLocaleString()}</p>
          </div>
        ))}
      </div>
      {modalIsOpen && selectedAlert && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <h2>{selectedAlert.subject}</h2>
            <p><strong>Body:</strong> {selectedAlert.body}</p>
            <p><strong>Organization ID:</strong> {selectedAlert.organizationId}</p>
            <p><strong>Route ID:</strong> {selectedAlert.routeId}</p>
            <p><strong>Stop ID:</strong> {selectedAlert.stopId}</p>
            <p><strong>Vehicle ID:</strong> {selectedAlert.vehicleId}</p>
            <p><strong>User Name:</strong> {selectedAlert.userName}</p>
            <p><strong>Medium:</strong> {selectedAlert.medium}</p>
            <p><strong>Type:</strong> {selectedAlert.type}</p>
            <p><strong>Is Deleted:</strong> {selectedAlert.isDeleted.toString()}</p>
            <p><strong>Created At:</strong> {new Date(selectedAlert.createdAt).toLocaleString()}</p>
            <p><strong>Updated At:</strong> {new Date(selectedAlert.updatedAt).toLocaleString()}</p>
          </div>
        </div>
      )}
      <style jsx>{`
        .modal {
          display: block;
          position: fixed;
          z-index: 1;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          overflow: auto;
          background-color: rgb(0,0,0);
          background-color: rgba(0,0,0,0.4);
          padding-top: 60px;
        }
        .modal-content {
          background-color: #fefefe;
          margin: 5% auto;
          padding: 20px;
          border: 1px solid #888;
          width: 80%;
        }
        .close {
          color: #aaa;
          float: right;
          font-size: 28px;
          font-weight: bold;
        }
        .close:hover,
        .close:focus {
          color: black;
          text-decoration: none;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};

export default Alert;
