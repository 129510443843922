import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  styled,
  Switch,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import Pagination from "../../components/pagination/Pagination";
import "./Organization.css";
import { getSortedData, org_base_url } from "./utils";
import useFetch from "../../hooks/useFetch";
import axios from "axios";
import useDelete from "../../hooks/useDelete";
import { useNavigate } from "react-router";

function createData(id, name, domain, city, contact, organizationStatus) {
  return { id, name, domain, city, contact, organizationStatus };
}

const columns = [
  { label: "Organization Name", sortable: true, id: "name" },
  { label: "Domain", sortable: false, id: "domain" },
  { label: "City", sortable: true, id: "city" },
  { label: "Contact", sortable: false, id: "contactName" },
  { label: "Status", sortable: true, id: "organizationStatus" },
  { label: "Actions", sortable: false, id: "actions" },
];

const getRows = (rows) =>
  rows.map((item, index) =>
    createData(
      item.id,
      item.name,
      item.domain,
      item.city,
      item.contactName,
      item.organizationStatus
    )
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
  
      backgroundColor:'rgba(32, 168, 224, 1)',
      color:' rgba(255, 255, 255, 1)', fontSize:20,fontWeight:600
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 24,
    },
  }));

const OrganizationTable = ({
  orgData,
  setOrgData,
  filteredData,
  fetchEntities,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setitemPerPage] = useState();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  const { deleteEntity } = useDelete(org_base_url);
  const navigateToUpdateOrg = useNavigate();

  useEffect(() => {
    setitemPerPage(10);
  }, []);

  const handleColumnSort = (id) => {
    const newOrder = order === "asc" ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(id);
  };

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedOrgId(id);
  };
  const openDeleteModal = (action) => {
    if (action === "Delete") {
      setIsDeleteModalOpen(true);
    }
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteConfirm = async () => {
    const { responseData, loading, error } = await deleteEntity(selectedOrgId);
    console.log("resD", responseData);
    if (responseData) {
      const { data: newOrgData } = await fetchEntities();
      console.log("new", newOrgData);
      //  setOrgData(newOrgData.organizations || [])
    }
    setSelectedOrgId(null);
    setIsDeleteModalOpen(false);
  };

  const handleDeleteCancel = () => {
    setSelectedOrgId(null);
    setIsDeleteModalOpen(false);
  };

  const handleStatusUpdate = async (id, newStatus) => {
    try {
      await axios.put(`${org_base_url}/${id}`, {
        organizationStatus: newStatus,
      });

      const updatedOrgData = orgData.map((org) =>
        org.id === id ? { ...org, organizationStatus: newStatus } : org
      );
      setOrgData(updatedOrgData);
      console.log("orgDAta", orgData);
    } catch (error) {
      console.error("Failed to update organization status:", error);
    }
  };

  const handleUpdateOrg = () => {
    navigateToUpdateOrg("../organization/add", { state: selectedOrgId });
  };

  const handleAssignFeatures = () => {
    console.log("selectedOrgId", selectedOrgId);
    navigateToUpdateOrg("../organization/feature/"+selectedOrgId, { state: selectedOrgId });
  };

  const styles = {
    header: { backgroundColor: theme.palette.primary.main, color: "white" },
  };

  //Page Previous and Next
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  //To show the total Pages
  const totalCount = parseInt(filteredData?.length);
  const totalPages = Math.ceil(totalCount / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const sortedData = filteredData.sort((a, b) =>
    getSortedData(a, b, orderBy, order)
  );
  const currentData = sortedData?.slice(startIndex, endIndex);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead >
            <TableRow >
              {columns.map((column) => (
                <StyledTableCell
                // style={{ paddingLeft: "25px" }}
                  align="left"
                  key={column.id}
                  onClick={() => handleColumnSort(column.id)}
                >
                  {column.label}{" "}
                  {column.sortable &&
                    (orderBy === column.id ? (
                      order === "asc" ?<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.7321 15C9.96225 16.3333 8.03775 16.3333 7.26795 15L0.339745 3C-0.430055 1.66667 0.532196 1.78935e-06 2.0718 1.65476e-06L15.9282 4.43391e-07C17.4678 3.08794e-07 18.4301 1.66667 17.6603 3L10.7321 15Z" fill="#183059"/>
                      </svg>: <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path d="M10.7321 15C9.96225 16.3333 8.03775 16.3333 7.26795 15L0.339745 3C-0.430055 1.66667 0.532196 1.78935e-06 2.0718 1.65476e-06L15.9282 4.43391e-07C17.4678 3.08794e-07 18.4301 1.66667 17.6603 3L10.7321 15Z" fill="#183059" transform="rotate(180, 9, 8)"/>
</svg>
): (
                      <ArrowDropUp
                        style={{ verticalAlign: "middle", cursor: "pointer", color:"rgba(24, 48, 89, 1)",width:"24px",height:"24px"}}
                      />
                    ))}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getRows(currentData).map((row) => (
              <TableRow 
              
                key={row.id}
              >
                
                <TableCell style={{fontWeight: 600}}> {row.name}</TableCell>
                <TableCell >{row.domain}</TableCell>
                <TableCell >{row.city}</TableCell>
                <TableCell>{row.contact}</TableCell>
                <TableCell>

                <Box className="radio_btn" sx={{backgroundColor: row.organizationStatus === "ACTIVE" ? "rgba(175, 224, 77, 1)" : "rgba(255, 201, 77, 1)"
  }}
>
<FormControlLabel
  control={
    <Switch
      checked={row.organizationStatus === "ACTIVE"} 
      onChange={() =>
        handleStatusUpdate(
          row.id, 
          row.organizationStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE"
        )
      }
      inputProps={{ "aria-label": "controlled" }}
      sx={{
        width: 42,              
        height: 26,           
        padding: 0,             
        "& .MuiSwitch-switchBase": {
          padding: 1,            
          "&.Mui-checked": {
            transform: "translateX(16px)", 
            color: "#fff",                 
            "& + .MuiSwitch-track": {
              backgroundColor: "#65C466",  
              opacity: 1,
              background:"none"
            },
          },
        },
        "& .MuiSwitch-thumb": {
          width: 24,          
          height: 24,
          boxShadow: "none",  
          marginTop:'-7px',   
        },
        "& .MuiSwitch-track": {
          backgroundColor: "#E9E9EA",  
          opacity: 1,
          background:"none",
        },
      }}
    />
  }
  label={row.organizationStatus === "ACTIVE" ? "Active" : "Inactive"} 
  labelPlacement={row.organizationStatus === "ACTIVE" ? "start" : "end"} 
/>

              </Box>
                </TableCell>
                <TableCell   align="justify">
                  <IconButton
                    aria-label="action-btn"
                    id="action-btn"
                    onClick={(event) => handleOpenMenu(event, row.id)}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                  {anchorEl && (
                    <Menu
                      id="action-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleCloseMenu}
                      MenuListProps={{
                        "aria-labelledby": "action-btn",
                      }}
                    >
                      <MenuItem
                        onClick={() => handleUpdateOrg("Update", row.id)}sx={{
                          "&:hover": {
                            backgroundColor: "rgba(32, 168, 224, 1)", color: "rgba(255, 255, 255, 1)" 
                             // Light blue hover effect
                          },
                        }}
                      >
                         <ListItemIcon > 
                         <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.15659 3.18652H2.59035C2.16857 3.18652 1.76405 3.35408 1.4658 3.65233C1.16755 3.95058 1 4.35509 1 4.77688V15.9093C1 16.3311 1.16755 16.7356 1.4658 17.0339C1.76405 17.3321 2.16857 17.4997 2.59035 17.4997H13.7228C14.1446 17.4997 14.5491 17.3321 14.8474 17.0339C15.1456 16.7356 15.3132 16.3311 15.3132 15.9093V10.3431" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.1189 1.99406C14.4352 1.67772 14.8643 1.5 15.3116 1.5C15.759 1.5 16.1881 1.67772 16.5044 1.99406C16.8208 2.3104 16.9985 2.73945 16.9985 3.18682C16.9985 3.6342 16.8208 4.06325 16.5044 4.37959L8.95024 11.9338L5.76953 12.7289L6.56471 9.54824L14.1189 1.99406Z" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                         </ListItemIcon>
                        Update
                      </MenuItem>
                      <MenuItem
                        onClick={() => openDeleteModal("Delete", row.id)}sx={{
                          "&:hover": {
                            backgroundColor: "rgba(32, 168, 224, 1)", color: "rgba(255, 255, 255, 1)"  // Light blue hover effect
                          },
                        }}
                      >
                        <ListItemIcon > 
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 4.40039H2.77778H17" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.2218 4.4V16.3C15.2218 16.7509 15.0345 17.1833 14.7011 17.5021C14.3677 17.8209 13.9155 18 13.444 18H4.55512C4.08363 18 3.63144 17.8209 3.29804 17.5021C2.96464 17.1833 2.77734 16.7509 2.77734 16.3V4.4M5.44401 4.4V2.7C5.44401 2.24913 5.63131 1.81673 5.96471 1.49792C6.29811 1.17911 6.75029 1 7.22179 1H10.7773C11.2488 1 11.701 1.17911 12.0344 1.49792C12.3678 1.81673 12.5551 2.24913 12.5551 2.7V4.4" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.22266 8.65039V13.7504" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.7773 8.65039V13.7504" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</ListItemIcon>
                        Delete
                      </MenuItem>

                      {/* <MenuItem
                        onClick={() => handleAssignFeatures("Delete", row.id)}sx={{
                          "&:hover": {
                            backgroundColor: "rgba(32, 168, 224, 1)", color: "rgba(255, 255, 255, 1)" // Light blue hover effect
                          },
                        }}
                      >
                        <ListItemIcon > 

</ListItemIcon>
                        Assign Feature
                
                      </MenuItem> */}

                    </Menu>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />

      <Dialog
        open={isDeleteModalOpen}
        onClose={handleDeleteCancel}
        PaperProps={{
          style: {
            backgroundColor: "white",
            width: "594px",
            maxWidth: "600px",
            height:"294px"
            
          },
        }}
      >
        <DialogTitle  style={{ fontWeight:"bolder", height:"80px"}}>
        <span style={{ color: "#EE3B2B",width:"514px",fontSize:"32px",fontWeight:"600" }}>  Delete Organization!</span> 
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: "rgba(58, 58, 58, 1)",width:"514px",fontSize:"24px",fontWeight:"400" ,marginTop:"-6px"}} >Do you want to proceed?</DialogContentText>
        </DialogContent>
        <div style={{marginLeft:"100px",marginRight:"108px",marginBottom:"40px"}}>
        <DialogActions >
          <button className='customButton_add'
            onClick={handleDeleteCancel}
            color="primary"
            style={{  padding:"10px 40px",fontWeight:"bold",textTransform:"none",background: "#F2F2F3",marginRight:"50px"
            }}
          >
            Cancel
          </button>
          <button className='customButton_add'
            onClick={handleDeleteConfirm}
            color="primary"
            variant="contained"
            autoFocus
            style={{ padding:"10px 40px",fontWeight:"bold" ,textTransform:"none",background: "#EE3B2B",color:"white"}}
          >
            Delete
          </button>
        </DialogActions>
        </div>
        
      </Dialog>
    </> 
  );
};

export default OrganizationTable;
