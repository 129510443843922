import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useLocation } from "react-router-dom";
import config from "../../config.json";
import axios from "axios";

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const DeviceLocationMap = () => {
  const [position, setPosition] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [route, setRoute] = useState(null);
  const [stops, setStops] = useState([]);
  const location = useLocation();

  const getRouteById = async (id) => {
    try {
      const resp = await axios.get(`${config.baseURL}/route/id`, {
        params: {
          id: id,
        },
        withCredentials: true,
      });
      return resp.data;
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const fetchStopsForRoute = async (route) => {
    const stops = [];
    for (const coordinate of route.routeCoordinates) {
      try {
        const stopsResponse = await axios.get(
          `${config.baseURL}/stop/organization/location`,
          {
            params: {
              organizationId: 17,
              longitude: coordinate.longitude,
              latitude: coordinate.latitude,
            },
            withCredentials: true,
          }
        );
        console.log("Stops fetched for coordinate:", stopsResponse.data);
        stops.push(stopsResponse.data);
      } catch (error) {
        console.log("Error in fetching stops by coordinates", error);
      }
    }
    return stops;
  };

  useEffect(() => {
    const fetchVehicleDataById = async (vehicleId, organizationId) => {
      console.log("id", vehicleId);
      try {
        const updateVehicleUrl = `${config.baseURL}/vehicle/id`;
        const response = await axios.get(
          updateVehicleUrl,
          {
            params: {
              vehicleId,
              organizationId,
            },
          },
          { withCredentials: true }
        );
        const routeId = response.data.vehicle.routeId;
        const route = await getRouteById(routeId);
        console.log("route", route);
        const stops = await fetchStopsForRoute(route);
        console.log("stops", stops);
        setVehicle(response.data.vehicle);
        setRoute(route);
        setStops(stops);
        console.log("response", response.data.vehicle);
      } catch (error) {
        console.log(error);
      }
    };
    // if (location.state) {
    console.log("location", location.state);
    fetchVehicleDataById(location.state, 1);
  }, [location.state]);

  useEffect(() => {
    console.log(location.state, "vehicle id");
    const fetchLocation = async () => {
      try {
        const response = await fetch(
          `${config.baseURL}/location/latest/${vehicle.device_id}`,
        );
        const data = await response.json();
        setPosition({
          lat: parseFloat(data.latitude),
          lng: parseFloat(data.longitude),
        });
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    };

    // Fetch immediately on component mount
    fetchLocation();

    // Set up interval to fetch every 10 seconds
    const intervalId = setInterval(fetchLocation, 10000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [vehicle]);

  if (!position || !vehicle || !route || !stops) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <LoadScript googleMapsApiKey="AIzaSyD7rqUpTzUpEbxm-Xc7ikltFJGzOhd92Qk">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={position}
          zoom={15}
        >
          <Marker position={position} />
        </GoogleMap>
      </LoadScript>
      <RouteDetails details={{ route, vehicle }} />
      <StopsTable stops={stops} />
    </div>
  );
};

const RouteDetails = ({ details }) => {
  const { route, vehicle } = details;
  return (
    <div>
      <p>Route Name: {route.name}</p>
      <p>Route ID: {route.routeId}</p>
      <p>Estimated Speed: NA</p>
      <p>Status: {vehicle.status}</p>
    </div>
  );
};

const StopsTable = ({ stops }) => {
  return (
    <table
      style={{ width: "100%", marginTop: "20px", borderCollapse: "collapse" }}
    >
      <thead>
        <tr>
          <th style={tableHeaderStyle}>Stop Name</th>
          <th style={tableHeaderStyle}>Stop ID</th>
          <th style={tableHeaderStyle}>Distance</th>
          <th style={tableHeaderStyle}>ETA</th>
        </tr>
      </thead>
      <tbody>
        {stops.map((stop, index) => (
          <tr key={stop.id}>
            <td style={tableCellStyle}>{stop.name}</td>
            <td style={tableCellStyle}>{stop.stopId}</td>
            <td style={tableCellStyle}>N/A</td>
            <td style={tableCellStyle}>N/A</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const tableHeaderStyle = {
  backgroundColor: "#f2f2f2",
  padding: "12px",
  textAlign: "left",
  borderBottom: "1px solid #ddd",
};

const tableCellStyle = {
  padding: "12px",
  borderBottom: "1px solid #ddd",
};

export default DeviceLocationMap;
