import React from 'react';
const StarIcon = ({ width = 15, height = 12, fill = "#EE3B2B", ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 0L10.9098 6.69208L17.6603 5L12.8197 10L17.6603 15L10.9098 13.3079L9 20L7.09017 13.3079L0.339746 15L5.18034 10L0.339746 5L7.09017 6.69208L9 0Z"
        fill={fill}
      />
    </svg>
  );
};

export default StarIcon;
