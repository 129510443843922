import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config.json';
import { useNavigate, useParams } from 'react-router';
import DashboardHeader from '../../components/header/DashboardHeader';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Paper, Select, TextField } from '@mui/material';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { Navigate } from 'react-router';
// import { makeStyles } from '@mui/styles';

let currentUser;
let id;

/**
 * Fetches user data by user ID.
 *
 * This function sends an HTTP GET request to retrieve user information
 * from the specified API endpoint using the provided user ID. The request
 * includes credentials (cookies, authorization headers, etc.) and uses
 * Axios for making the HTTP request.
 *
 * @async
 * @function getUserById
 * @param {number|string} id - The ID of the user to fetch.
 * @returns {Promise<Object>} A promise that resolves to the user data object.
 * @throws Will throw an error if the request fails.
 *
 * @example
 * // Example usage of getUserById function
 * getUserById(1)
 *   .then(userData => {
 *     console.log(userData);
 *   })
 *   .catch(error => {
 *     console.error("Error fetching user data:", error);
 *   });
 */
async function getUserById(id) {
  try {
    const response = await axios.get(`${config.baseURL}/user/${id}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.log("Error in fetching user data with id", error);
    throw error;
  }
}

/**
 * Adds a new user.
 *
 * This function sends an HTTP POST request to create a new user
 * with the provided user data. The request includes credentials
 * (cookies, authorization headers, etc.) and uses Axios for making
 * the HTTP request.
 *
 * @async
 * @function addUser
 * @param {Object} user - The user data to create a new user.
 * @param {string} user.name - The name of the user.
 * @param {string} user.email - The email of the user.
 * @param {string} user.password - The password of the user.
 * @param {string} user.userName - The username of the user.
 * @param {number} user.organizationId - The ID of the organization the user belongs to.
 * @param {number} user.roleId - The ID of the role assigned to the user.
 * @returns {Promise<Object>} A promise that resolves to the newly created user data object.
 * @throws Will throw an error if the request fails.
 *
 * @example
 * // Example usage of addUser function
 * addUser({
 *   name: "champu",
 *   email: "champu@gmail.com",
 *   password: "123",
 *   userName: "champu",
 *   organizationId: 1,
 *   roleId: 1
 * }).then(response => {
 *   console.log(response);
 * }).catch(err => console.log(err));
 */
async function addUser(user) {
  try {
    const response = await axios.post(`${config.baseURL}/user/`, user, {
      withCredentials: true
    });
    return  response.data;
  } catch (error) {
    console.error("error creating the user: ", error);
    throw error;
  }
}
/**
 * Updates user information.
 *
 * This function sends an HTTP PUT request to update user information
 * with the provided user data. The request includes credentials
 * (cookies, authorization headers, etc.) and uses Axios for making
 * the HTTP request.
 *
 * @async
 * @function updateUser
 * @param {Object} user - The user data to update.
 * @param {number} user.userId - The ID of the user to update.
 * @param {string} [user.name] - The updated name of the user (optional).
 * @param {string} [user.email] - The updated email of the user (optional).
 * @param {number} [user.roleId] - The updated ID of the role assigned to the user (optional).
 * @returns {Promise<Object>} A promise that resolves to the updated user data object.
 * @throws Will throw an error if the request fails.
 *
 * @example
 * // Example usage of updateUser function
 * updateUser({
 *   userId: 4,
 *   name: "allu",
 *   email: "allu@gmail.com",
 *   roleId: 1
 * }).then(response => {
 *   console.log(response);
 * }).catch(err => console.log(err));
 */
async function updateUser(user) {
  try {
    const response = await axios.put(`${config.baseURL}/user/`, user,{
      withCredentials: true
    });
    return response.data
  } catch (error) {
    console.error("Error in updating user", error);
    throw error;
  }
}

async function createRole(role) {
  try {
    const response = await axios.post(`${config.baseURL}/role`, role, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.error('Error Creating features:', error);
    throw error;
  }
}

async function updatePassword(id, pass, confirmPass) {
  try {
    const response = await axios.patch(`${config.baseURL}/user/password`, {
      
      userId: id,
      oldPassword: pass,
      newPassword: confirmPass
    }, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
const AddAdmin = () => {
  // const params = useParams();
  // console.log('Params:', params); // Add this line
  const location = useLocation();
  const { name,email,orgId,orgName } = location.state || {};
  const Navigate=useNavigate();

  // const { Id } = params;
  const [userData, setUserData] = useState([]);
  const [allRoles,setAllRoles]=useState([]);
   // State variables to track visibility of password fields
   const [showPasswordFields, setShowPasswordFields] = useState(false);
   const [updateMode, setUpdateMode] = useState(false);
   const [errors,setErrors]=useState({})

  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    password: "",
    userName: "",
    lastName:"",
    roleId: ""
  });
  // useEffect(() => {
  //   console.log('ID:', Id); // Add this line
  //   if (Id) {
  //     getUserById(Id)
  //       .then((response) => {
  //         console.log(response);
  //         currentUser = response.user;
  //         setUserData(currentUser);
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // }, [Id]);
  // addUser({
  //   "name": "champu",
  //   "email": "champu@gmail.com",
  //   "password": "123",
  //   "userName": "champu",
  //   "organizationId": 1,
  //   "roleId": 1
  // }).then(response => {
  //   console.log(response)
  // }).catch(err => console.log(err));

  // updateUser({
  //   userId: 4,
  //   name:"allu",
  //   email:"allu@gmail.com",
  //   roleId:1
  // }).then(response => {
  //   console.log(response);
  // }).catch(err => console.log(err))

  const gridContainerStyle={
    width:"70%",
    paddingTop:"20px",
    marginLeft:"5%",
    // marginTop:"30px"

    
  }
  const selectStyles={
    borderRadius:"16px",
    height:"60px",
    marginTop:"5px",
    paddingTop:"15px"
    // border:"2px solid black",
  }
    const togglePasswordFields = () => {
    setShowPasswordFields(!showPasswordFields);
  };
  useEffect(() => {
    if (id) {
      console.log("user id",id)
    setUpdateMode(true)
      getUserById(id)
        .then((response) => {
          if (response && response.user) {
            setUserData(response.user);
            console.log(response)
            console.log("userData",userData)
             // Split name into first name and last name
          const nameParts = response.user.name.split(" ");
            setFormData({
              firstName: nameParts[0] || "", // First name
              lastName: nameParts.slice(1).join(" ") || "", // Last name
              email: response.user.email,
              userName: response.user.userName,
              roleId: response.user.roleId
            });
            
          }
        })
        .catch((error) => console.error("Error fetching user data:", error));
    }

    console.log("orgName",orgName,"orgId",orgId)
    createRole({
      name: orgName + "_admin",
      organizationId:orgId,
      description: "This role is used for admin",
    }).then((response) => {
      console.log("create role response", response);
      let roles = []
      roles.push(response)
      setAllRoles(roles)
      setFormData({
      firstName: name,
      userName: "admin",
        email: email,
        
      roleId: response.role.id
    })
    })
    
    


  }, []);
  
  // useEffect(()=>{
  //   axios.get('http://localhost:3000/api/role')
  //   .then(res=>{
  //     console.log("all roles data",res.data.roles)
  //     setAllRoles(res.data.roles);
  //   }).catch(error=>{console.log("errorin fetching roles",error)})
  // },[])
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleRoleChange = (e) => {
    const selectedRoleName = e.target.value;
    console.log("Selected Role Name:", selectedRoleName);
    const selectedRoleObject = allRoles.find(role => role.name === selectedRoleName);
    console.log("Selected Role Object:", selectedRoleObject);
    if (selectedRoleObject) {
      setFormData((prevData) => ({
        ...prevData,
        roleId: selectedRoleObject.id
      }));
      console.log("Selected Role ID:", selectedRoleObject.id);
    } else {
      console.error("Role not found for name:", selectedRoleName);
    }
  };
  // Concatenate first name and last name here
  const fullName = formData.firstName + (formData.lastName ? " " + formData.lastName : "");


  const submitHandler= async (e)=>{
    e.preventDefault();
    console.log(id)
    const errors={}
     if(id) {
          // setFormData({
          //   firstName: userData.name,
          //   email: userData.email,
          //   userName: userData.userName,
          //   roleId: userData.roleId // Assuming roleId is also available in the user data
          // });
        
      
        const updateUserData={
          userId:userData.id,
          name:fullName,
          email:formData.email,
          roleId:formData.roleId
        }     
      const updateUserResponse= await updateUser(updateUserData)
      console.log("user has been updated successfully",updateUserResponse)
      setFormData({
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        password: "",
        roleId: "", // Reset roleId if necessary
      });
      Navigate('/Users')
     } else {
       console.log("form data", formData)
      const newUser={
        name:fullName,
        email:formData.email,
        password:formData.password,
        userName:formData.userName,
        organizationId:orgId,
        roleId:formData.roleId
       }
       console.log("new user",newUser)
      try{
        const userResponse= await addUser(newUser)
        console.log( 'added user Data',userResponse)
        console.log("form data", formData)
        console.log("user created usccessfully")
        // Clear the form fields after successfully adding the user
      setFormData({
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        password: "",
        roleId: "", // Reset roleId if necessary
      });
      Navigate('/Users')
      }catch(error){
        console.log("error in adding the user",error)
      }
    }

  }
  const handleCancel = () => {
    // Clear form fields
   
    setFormData({
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      roleId: "",
    });
    Navigate('/Users')
    
  };
  const findRoleNameById = (roleId) => {
    const role = allRoles.find(role => role.id === roleId);
    return role ? role.name : "";
  };
  // const selectLabel = userData && userData.roleId ? findRoleNameById(userData.roleId) : "Select Role";

  // const selectLabel = updateMode ? userData.user.role : "Select Role";
  return (
    <>
    <DashboardHeader title="Add Users"/>
    <Paper style={{ margin: "20px", borderRadius: "20px", padding: "20px" }}>
        <Box component="form"   autoComplete="off">
        {updateMode ? <h1 className='add_user_heading'>Update User Details : {userData.userName}</h1>   : <h1 className='add_user_heading'>Add User Details</h1>}
          <Grid container spacing={2} style={gridContainerStyle}>
            {/* <div> */}
            <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="First Name" sx={{ fontWeight: "400",size:"24px", paddingBottom: "8px" ,color:"black" }}>First Name</InputLabel>
              <TextField
                label="Type First Name Here"
                name="firstName"
                type="text"
                value={formData.firstName}
                onChange={handleChange}
                className='user_input_fields'
                fullWidth
                InputProps={{
                  style: {
                   borderRadius:"16px"
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: 'black', 
                    },
                  },
                }}
              />
            </Grid>
            {/* </div> */}
            <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="lastName" sx={{ fontWeight: "400",size:"24px", paddingBottom: "8px" ,color:"black" }}>Last Name</InputLabel>
              <TextField
                label="Type Last Name Here"
                name="lastName"
                type="text"
                value={formData.lastName}
              onChange={handleChange}
               
                fullWidth
                InputProps={{
                  style: {
                   borderRadius:"16px"
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: '#183059', 
                      
                    },
                  },
                }}
              />
            </Grid>
           
           {!updateMode&&(
             <>
             <Grid item xs={12} sm={6}>
             <InputLabel htmlFor="lastName" sx={{ fontWeight: "400",size:"24px", paddingBottom: "8px" ,color:"black" }}>User Name</InputLabel>
               <TextField
                 label="Type Username Here"
                 type="text"
                 name="userName"
                 // value={userData.userName}
                 // onChange={handleChange}
                 value={formData.userName}
               onChange={handleChange}
                 fullWidth
                 InputProps={{
                   style: {
                    borderRadius:"16px"
                   }
                 }}
                 sx={{
                   '& .MuiOutlinedInput-root': {
                     '& fieldset': {
                       borderWidth: '2px',
                       borderColor: '#183059', // Thicker border
                     },
                   },
                 }}
               />
             </Grid>
              {/* <Grid item xs={12} sm={6}>
                
              <InputLabel htmlFor="roles" sx={{ fontWeight: "400", size: "24px", paddingBottom: "8px", color: "black" }}>Select Role</InputLabel>
              <FormControl fullWidth>
                <Select
                  value={findRoleNameById(formData.roleId)}
                  onChange={handleRoleChange}
                  displayEmpty
                  style={selectStyles}
                  inputLabelProps={{
                    style: {
                      borderRadius: "16px",
                      // paddingBottom:"10px",
                      borderWidth: '2px',
                      
                      borderColor: '#183059',
                    }
                  }}
                >
                  <MenuItem value="" disabled >
                    Select a role
                  </MenuItem>
                  {allRoles.map(role => (
                    <MenuItem key={role.id} value={role.name}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
            </>
             
           )}
            {updateMode ? (
              <>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="email" sx={{ fontWeight: "400", size: "24px", paddingBottom: "8px", color: "black" }}>Email</InputLabel>
                  <TextField
                    label="Type Your Email Address "
                    type="text"
                    name="email"
                    autoComplete='new-email'
                    value={formData.email}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: "16px"
                      }
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderWidth: '2px',
                          borderColor: '#183059',
                        },
                      },
                    }}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="roles" sx={{ fontWeight: "400", size: "24px", }}>Select Role</InputLabel>
                  <FormControl fullWidth sx={{height:"20px",}}>
                    <Select
                      value={findRoleNameById(formData.roleId)}
                      onChange={handleRoleChange}
                      displayEmpty
                      style={selectStyles}
                      sx={{
                        borderRadius: "16px",
                        borderColor: "#183059",
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: "#183059"
                        }
                      }}
                      
                    >
                      <MenuItem value="" disabled>
                        Select a role
                      </MenuItem>
                      {allRoles.map(role => (
                        <MenuItem key={role.id} value={role.name}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}
              </>
            ) : (
              <Grid item xs={12}>
                <InputLabel htmlFor="email" sx={{ fontWeight: "400", size: "24px", paddingBottom: "8px", color: "black" }}>Email</InputLabel>
                <TextField
                  label="Type Your Email Address "
                  type="text"
                  name="email"
                  autoComplete='new-email'
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: "16px"
                    }
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderWidth: '2px',
                        borderColor: '#183059',
                      },
                    },
                  }}
                />
              </Grid>
            )}
            {/* <Grid item xs={12}>
            <InputLabel htmlFor="email" sx={{ fontWeight: "400",size:"24px", paddingBottom: "8px" ,color:"black" }}>Email</InputLabel>
              <TextField
                label="Type Your Email Address "
                type="text"
                name="email"
                autoComplete='new-email'
                
                value={formData.email}
              onChange={handleChange}
                fullWidth
                InputProps={{
                  style: {
                   borderRadius:"16px"
                  }
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: 'black', // Thicker border
                    },
                  },
                }}
              />
            </Grid> */}
            <Grid item xs={12}>
  {updateMode ? null : (
    <>
      <InputLabel htmlFor="password" sx={{ fontWeight: "400",size:"24px", paddingBottom: "8px" ,color:"black" }}>Password</InputLabel>
      <TextField
        label="Type Your Password"
        name="password"
        type="password"
        autoComplete="new-password"
        value={formData.password}
        onChange={handleChange}
        fullWidth
        InputProps={{
          style: {
            borderRadius: "16px"
          }
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderWidth: '2px',
              borderColor: '#183059',
            },
          },
        }}
      />
    </>
  )}
</Grid>
           
            {id && (
              <>
                <Grid item xs={12}>
                  <Button 
                  style={{textTransform:"none",fontWeight:"400",fontSize:"24px",marginLeft:"72",marginTop:"-20px",color: "#20A8E0",
                  }}
                  onClick={togglePasswordFields}>
                    Reset Password
                    {showPasswordFields ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />}
                    {/* <KeyboardDoubleArrowDownIcon /> */}
                  </Button>
                </Grid>
                {showPasswordFields && (
                  <>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="new-password" sx={{ fontWeight: "400",size:"24px", paddingBottom: "8px" ,color:"black" }}>New Password</InputLabel>
                      <TextField
                        label="Type Your New Password Here"
                        name="password"
                        type="password"
                        autoComplete='new-password'
                        // value={formData.password}
                        // onChange={handleChange}
                        fullWidth
                        InputProps={{
                          style: {
                            borderRadius: "16px"
                          }
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderWidth: '2px',
                              borderColor: '#183059',
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="confirmPassword" sx={{ fontWeight: "400",size:"24px", paddingBottom: "8px" ,color:"black" }}>Confirm Password</InputLabel>
                      <TextField
                        label="Confirm Your Password Here"
                        name="confirmPassword"
                        type="password"
                        autoComplete='new-password'
                        fullWidth
                        InputProps={{
                          style: {
                            borderRadius: "16px"
                          }
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderWidth: '2px',
                              borderColor: '#183059',
                            },
                          },
                        }}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}

          
            <div style={{display:"flex",flexDirection:"row",gap:"50px",marginLeft:"90px",marginTop:"70px",marginBottom:"30px",}}>
            <Grid item xs={12} sm={6} >
              <Button type="submit" variant="contained"
              onClick={handleCancel}
              style={{fontWeight:"500",fontSize:"32px",
              color:"white",width:"250px", 
              // border:"2px solid black",
              height:"56px",
              width:"280px",
             fontsize:"32px",
              weight:"700",
              background:"white",
              color:"#1F242E",
              background: "#F2F2F3",
              padding:"24px 96px 24px 96px",
              fontFamily:"Roboto",
              textTransform:"none",
              }}
               color="primary">
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button type="submit" variant="contained" color="primary"
              style={{fontWeight:"500",fontSize:"32px",
              color:"#1F242E",
              height:"56px",
              width:"280px",
              textTransform:"none",
              background: "#F9E719",
              padding:"24px 96px 24px 96px"

                   
            }}
               onClick={submitHandler}>
                Submit
              </Button>
            </Grid>
            </div>
          </Grid>
        </Box>
      </Paper>
    </>
  );
};

export default AddAdmin;