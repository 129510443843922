import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import sortByParam from "../../hooks/SortByParam";
import searchInTheTable from "../../hooks/SarchInTable";
import filterBy from "../../hooks/FilterBy";
import config from "../../config.json";
import Pagination from "../../components/pagination/Pagination";
import DashboardHeader from "../../components/header/DashboardHeader";
import {
  Box,
  Grid,
  ListItemIcon,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import "./schedule.css";

async function getAllSchedules() {
  try {
    const response = await axios.get(
      `${config.baseURL}/schedule/organization?organizationId=1  3`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error in fetching schedule data: ", error);
    throw error;
  }
}

async function deleteSchedule(id) {
  try {
    const response = await axios.delete(`${config.baseURL}/schedule`, {
      data: { id },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.log("Error in deleting schedule data: ", error);
    throw error;
  }
}

const Schedule = () => {
  const [scheduleList, setScheduleList] = useState([]);
  const [sortedTableData, setSortedTableData] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [filterStartTime, setFilterStartTime] = useState("");
  const [filterRepeatation, setFilterRepeatation] = useState("");
  const [filterRouteName, setFilterRouteName] = useState("");
  const [sortParam, setSortParam] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const [isMenuOpen, setIsMenuOpen] = useState(null);
  const toggleMenu = (index) => {
    if (isMenuOpen === index) {
      setIsMenuOpen(null);
    } else {
      setIsMenuOpen(index);
    }
  };

  useEffect(() => {
    const fetchSchedules = async () => {
      try {
        const schedules = await getAllSchedules();
        setScheduleList(schedules);
        setSortedTableData(sortByParam(sortParam, schedules));
        console.log(schedules);
      } catch (error) {
        console.log(error);
      }
    };

    fetchSchedules();
  }, []);

  useEffect(() => {
    let filteredData = searchInTheTable(searchStr, scheduleList);
    if (filterStartTime) {
      filteredData = filterBy("startTime", filterStartTime, filteredData);
    }
    if (filterRepeatation) {
      filteredData = filterBy("repitation", filterRepeatation, filteredData);
    }
    if (filterRouteName) {
      filteredData = filterBy("routeName", filterRouteName, filteredData);
    }
    setSortedTableData(sortByParam(sortParam, filteredData, sortDirection));
  }, [
    searchStr,
    filterStartTime,
    filterRepeatation,
    filterRouteName,
    sortParam,
    sortDirection,
    scheduleList,
  ]);

  const tableHeaders = [
    "Name",
    "Description",
    "Start Time",
    "End Time",
    "Repeatation",
    "Route",
    "Vehicle",
    "Action",
  ];

  const handleSortChange = (param) => {
    const newSortDirection =
      sortParam === param ? (sortDirection === "asc" ? "desc" : "asc") : "asc";
    setSortDirection(newSortDirection);
    setSortParam(param);
  };

  const handleSearchChange = (event) => {
    setSearchStr(event.target.value);
  };

  const handleFilterStartTimeChange = (event) => {
    setFilterStartTime(event.target.value);
  };

  const handleFilterRepeatationChange = (event) => {
    setFilterRepeatation(event.target.value);
  };

  const handleFilterRouteNameChange = (event) => {
    setFilterRouteName(event.target.value);
  };

  const handleUpdate = (scheduleId) => {
    navigate(`/schedule/${scheduleId}`);
  };

  const handleDelete = async (scheduleId) => {
    try {
      await deleteSchedule(scheduleId);
      setScheduleList(
        scheduleList.filter((schedule) => schedule.id !== scheduleId)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(32, 168, 224, 1)",
      color: " rgba(255, 255, 255, 1)",
      fontSize: 20,
      fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 24,
    },
  }));

  return (
    <>
      <DashboardHeader title="Schedules" />
      <Paper style={{ margin: "20px", height: "100%", borderRadius: "20px",marginTop:'32px' }}>
        <Box className="addbutton_container">
          <button
            className="customButton_add"
            onClick={() => navigate("/schedule/add")}
            variant="contained"
          >
            + Add Schedule
          </button>
        </Box>

        <Box className="Headercard">
          <Grid container spacing={3}>
            <Grid item xs>
              <Box className="Headercard_Total">
                <div className="Headercard_Total_text">
                  <div className="org-para">
                    Total <br />
                    Schedules
                  </div>
                  <div>
                    <span className="org-count">260</span>
                  </div>
                </div>
              </Box>
            </Grid>

            <Grid item xs>
              <Box className="Headercard_Active">
                <div className="Headercard_Total_text">
                  <div className="org-para">
                    Scheduled Trips in Next <br />1 Hour
                  </div>
                  <div>
                    <span className="org-count">20</span>
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="search-filter-wrapper">
          <div className="search_container ">
            <div className="search_text">
              <label htmlFor="search">
                <input
                  type="text"
                  id="search"
                  placeholder="Search"
                  value={searchStr}
                  onChange={handleSearchChange}
                  className="search_text"
                />
              </label>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-search"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </div>
          </div>
          <div className="filter2">
            <div className="filter_repeatition">
              <label htmlFor="filterStartTime"></label>
              <select
                id="filterStartTime"
                value={filterStartTime}
                onChange={handleFilterStartTimeChange}
              >
                <option value="">Repetition</option>
                {scheduleList.map((schedule) => (
                  <option key={schedule.id} value={schedule.startTime}>
                    {schedule.startTime}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter_repeatition">
              <label htmlFor="filterRepeatation"></label>
              <select
                id="filterRepeatation"
                value={filterRepeatation}
                onChange={handleFilterRepeatationChange}
              >
                <option value="">Start Time</option>
                <option value="DAILY">Daily</option>
                <option value="WEEKLY">Weekly</option>
                <option value="MONTHLY">Monthly</option>
              </select>
            </div>
            <div className="filter_repeatition">
              <label htmlFor="filterRouteName"></label>
              <select
                id="filterRouteName"
                value={filterRouteName}
                onChange={handleFilterRouteNameChange}
              >
                <option value=""> Routes</option>
                {scheduleList.map((schedule) => (
                  <option key={schedule.id} value={schedule.route.name}>
                    {schedule.route.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders.map((header) => (
                  <StyledTableCell
                    align="justify"
                    key={header}
                    onClick={() =>
                      handleSortChange(header.toLowerCase().replace(" ", ""))
                    }
                  >
                    {header}{" "}
                    {sortParam === header.toLowerCase().replace(" ", "") ? (
                      sortDirection === "asc" ? (
                        <svg
                          width="18"
                          height="16"
                          viewBox="0 0 18 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.7321 15C9.96225 16.3333 8.03775 16.3333 7.26795 15L0.339745 3C-0.430055 1.66667 0.532196 1.78935e-06 2.0718 1.65476e-06L15.9282 4.43391e-07C17.4678 3.08794e-07 18.4301 1.66667 17.6603 3L10.7321 15Z"
                            fill="#183059"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="18"
                          height="16"
                          viewBox="0 0 18 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.7321 15C9.96225 16.3333 8.03775 16.3333 7.26795 15L0.339745 3C-0.430055 1.66667 0.532196 1.78935e-06 2.0718 1.65476e-06L15.9282 4.43391e-07C17.4678 3.08794e-07 18.4301 1.66667 17.6603 3L10.7321 15Z"
                            fill="#183059"
                            transform="rotate(180, 9, 8)"
                          />
                        </svg>
                      )
                    ) : (
                      ""
                    )}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedTableData.length > 0 ? (
                sortedTableData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="justify"
                      style={{ fontWeight: "600", fontSize: "16px" }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.route.name}</TableCell>
                    <TableCell>{row.startTime}</TableCell>
                    <TableCell>{row.endTime}</TableCell>
                    <TableCell>
                      {row.vehicleSchedule
                        .map((vehicleSchedule) => vehicleSchedule.vehicle.name)
                        .join(", ")}
                    </TableCell>
                    <TableCell>{row.repitation}</TableCell>
                    
                    <TableCell>
                    <IconButton onClick={handleOpenMenu}>
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem onClick={() => handleUpdate(row.id)}sx={{
                          "&:hover": {
                            backgroundColor: "rgba(32, 168, 224, 1)", color: "rgba(255, 255, 255, 1)" 
                             // Light blue hover effect
                          },
                        }}>
                          <ListItemIcon > 
                         <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.15659 3.18652H2.59035C2.16857 3.18652 1.76405 3.35408 1.4658 3.65233C1.16755 3.95058 1 4.35509 1 4.77688V15.9093C1 16.3311 1.16755 16.7356 1.4658 17.0339C1.76405 17.3321 2.16857 17.4997 2.59035 17.4997H13.7228C14.1446 17.4997 14.5491 17.3321 14.8474 17.0339C15.1456 16.7356 15.3132 16.3311 15.3132 15.9093V10.3431" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.1189 1.99406C14.4352 1.67772 14.8643 1.5 15.3116 1.5C15.759 1.5 16.1881 1.67772 16.5044 1.99406C16.8208 2.3104 16.9985 2.73945 16.9985 3.18682C16.9985 3.6342 16.8208 4.06325 16.5044 4.37959L8.95024 11.9338L5.76953 12.7289L6.56471 9.54824L14.1189 1.99406Z" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                         </ListItemIcon>
                        Update
                      </MenuItem>
                      <MenuItem onClick={() => handleDelete(row.id)}sx={{
                          "&:hover": {
                            backgroundColor: "rgba(32, 168, 224, 1)", color: "rgba(255, 255, 255, 1)"  // Light blue hover effect
                          },
                        }}>
                            <ListItemIcon > 
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 4.40039H2.77778H17" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.2218 4.4V16.3C15.2218 16.7509 15.0345 17.1833 14.7011 17.5021C14.3677 17.8209 13.9155 18 13.444 18H4.55512C4.08363 18 3.63144 17.8209 3.29804 17.5021C2.96464 17.1833 2.77734 16.7509 2.77734 16.3V4.4M5.44401 4.4V2.7C5.44401 2.24913 5.63131 1.81673 5.96471 1.49792C6.29811 1.17911 6.75029 1 7.22179 1H10.7773C11.2488 1 11.701 1.17911 12.0344 1.49792C12.3678 1.81673 12.5551 2.24913 12.5551 2.7V4.4" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.22266 8.65039V13.7504" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.7773 8.65039V13.7504" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</ListItemIcon>
                        Delete
                      </MenuItem>
                    </Menu>
                  </TableCell>

                  </TableRow>
                ))
              ) : (
                <tr>
                  <td colSpan={tableHeaders.length}>No data available</td>
                </tr>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div>
          {""}
          <Pagination
        totalItems={sortedTableData.length}
        itemsPerPage={10}
        currentPage={1}
        onPageChange={(page) => console.log(page)}
      />

        </div>
      </Paper>
    </>
  );
};

export default Schedule;
