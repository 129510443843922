import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import "./User.css";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import Pagination from "../../components/pagination/Pagination";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CircleIcon from "@mui/icons-material/Circle";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useParams } from "react-router";
import config from "../../config.json";
import { usePermission } from "../../hooks/usePermission";

// import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router";
import { styled } from "@material-ui/core";
import { useUser } from "../login/UserContextPersistent";

// Function to create a new row data object
function createData(id, name, email, userName, role, lastName) {
  let fullName = name;
  if (lastName) {
    fullName += ` ${lastName}`;
  }
  return { id, name: fullName, email, userName, role };
}

// Define columns for the table
const columns = [
  { label: "Name", sortable: true, id: "name" },
  { label: "Email", id: "email" },
  { label: "User Name", id: "userName" },
  { label: "Roles", sortable: true, id: "role" },
  { label: "Actions", id: "Actions" },
];
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(32, 168, 224, 1)",
    color: " white",
    fontSize: 20,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 20,
  },
}));
// Convert rows to the required format
const getRows = (rows) =>
  rows.map((item, index) =>
    createData(
      item.id,
      `${item.name}`,
      item.email,
      item.userName,
      item.role,
      item.lastName // Pass lastName to createData function
    )
  );
async function updatePassword(id, confirmPass) {
  try {
    const response = await axios.patch(
      `${config.baseURL}/user/password`,
      {
        userId: id,
        newPassword: confirmPass,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

function UsersTable({
  currentUserListToDisplay,
  deleteUser,
  onSortChange,
  setCurrentUserListToDisplay,
  sortParam,
  sortDirection,
}) {
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setitemPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [pass, setPass] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();
  const { hasPermission } = usePermission();

  // Handle sorting of table columns

  const open = Boolean(anchorEl);

  // handling icon button open
  const handleOpenMenu = (event, id) => {
    event.preventDefault();
    setSelectedUserId(id);
    // console.log("selected user id ",selectedUserId)
    setAnchorEl(event.currentTarget);
  };
  // handling delete modal open
  const openDeleteModal = (id) => {
    id = selectedUserId;
    console.log("id to delete the user", id);
    setIsDeleteModalOpen(true);
    setAnchorEl(null);
  };

  // handling delete modal dailog close
  const handleDeleteDialogClose = () => {
    setIsDeleteModalOpen(false);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleDelete = async (id) => {
    try {
      // Show loading indicator while the delete operation is in progress
      setIsDeleting(true);
      id = selectedUserId;

      // Call the deleteUser function to delete the user
      await deleteUser(id);

      // Remove the deleted user from the currentUserListToDisplay state
      setCurrentUserListToDisplay((prevUsers) =>
        prevUsers.filter((user) => user.id !== id)
      );

      // Close the delete modal
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.log("Error deleting user: ", error);
      // Handle error gracefully
    } finally {
      // Hide loading indicator after the operation is complete (whether successful or not)
      setIsDeleting(false);
    }
  };

  const handleUpdateUser = (id) => {
    id = selectedUserId;
    navigate("/AddUser", { state: { id } });
  };
  // handling reset password modal dialog close
  const handleResetPasswordDialogClose = () => {
    setIsResetPasswordModalOpen(false);
  };
  const handlePassChange = (e) => {
    const { name, value } = e.target;
    setPass((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // handling reset password modal open
  const openResetPasswordModal = (id) => {
    setIsResetPasswordModalOpen(true);
    setAnchorEl(null);
  };

  const handelPassConfirm = async () => {
    const { newPassword, confirmPassword } = pass;
    if (
      newPassword !== "" &&
      confirmPassword !== "" &&
      newPassword === confirmPassword
    ) {
      try {
        const response = await updatePassword(selectedUserId, newPassword);
        console.log("Password has been updated successfully", response);
        setIsResetPasswordModalOpen(false);
        setPass({
          newPassword: "",
          confirmPassword: "",
        });
      } catch (error) {
        console.error(error);
      }
    }
    setIsResetPasswordModalOpen(false);
  };
  const styles = {
    header: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      fontSize: "18px",
      background: "#20A8E0",
      width: "1076px",
      height: "60px",
      border: "0px 0px 1px 0px",
      padding: "0px 4px 0px 4px",

      nameColumn: {
        paddingLeft: "45px",
      },
      ActionsColumn: {
        paddingLeft: "5px",
      },
    },
  };

  //   // calculating pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  const totalCount = parseInt(currentUserListToDisplay?.length);
  const totalPages = Math.ceil(totalCount / itemsPerPage) || 1;

  // handle pagination page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const rows = currentUserListToDisplay?.slice(startIndex, endIndex);
  return (
    <>
      <TableContainer component={Paper}>
        {isDeleting && (
          // Render a loading indicator while the delete operation is in progress
          <div>Loading...</div>
        )}
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  align="justify"
                  style={{
                    ...styles.header,
                    ...(column.id === "name" && styles.header.nameColumn), // Apply specific styles for the 'Name' column
                    ...(column.id === "Actions" && styles.header.ActionsColumn),
                  }}
                  key={column.id}
                  onClick={() => onSortChange(column.id)}
                >
                  {column.label}
                  {column.sortable && (
                    <>
                      {sortParam === column.id && sortDirection === "asc" && (
                        <ArrowDropUp
                          style={{ verticalAlign: "middle", cursor: "pointer" ,color:'rgba(24, 48, 89, 1)'}}
                        />
                      )}
                      {sortParam === column.id && sortDirection === "desc" && (
                        <ArrowDropDown
                          style={{ verticalAlign: "middle", cursor: "pointer" ,color:'rgba(24, 48, 89, 1)'}}
                        />
                      )}
                      {sortParam !== column.id && <ArrowDropDown   style={{ verticalAlign: "middle", cursor: "pointer" ,color:'rgba(24, 48, 89, 1)'}}/>}
                    </>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            style={{ padding: "0px", background: "#C8CBD0" }}
            className="TableBody-root"
          >
            {getRows(rows).map(
              (row) => (
                console.log(row),
                (
                  <TableRow
                    key={row.id}
                    className="MuiTableRow-root"
                    style={{
                      color: "black",
                      borderBbottom: "1px solid #C8CBD0",
                    }}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      className="MuiTableCell-root userName_column"
                      style={{
                        fontWeight: "600",
                        fontSize: "16px",
                        paddingLeft: "43px",
                      }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      align="justify"
                      className="MuiTableCell-root"
                      style={{ fontSize: "16px" }}
                    >
                      {row.email}
                    </TableCell>
                    <TableCell
                      align="justify"
                      className="MuiTableCell-root"
                      style={{ fontSize: "16px" }}
                    >
                      {row.userName}
                    </TableCell>
                    <TableCell
                      align="justify"
                      className="MuiTableCell-root"
                      style={{ fontSize: "16px" }}
                    >
                      {row.role}
                    </TableCell>

                    <TableCell align="justify" style={{ paddingLeft: "33px" }}>
                      <IconButton
                        aria-label="action-btn"
                        id="action-btn"
                        onClick={(event) => handleOpenMenu(event, row.id)}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                      {anchorEl && (
                        <Menu
                          id="action-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleCloseMenu}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          MenuListProps={{
                            "aria-labelledby": "action-btn",
                          }}
                          PaperProps={{
                            style: {
                              backgroundColor: "rgba(255, 255, 255, 1)",

                              borderRadius: "8px",
                              boxShadow: "none",
                            },
                          }}
                        >
                  {hasPermission("/api/user", "PUT") &&
                          <MenuItem onClick={() => handleUpdateUser(row.id)}sx={{
                          "&:hover": {
                            backgroundColor: "rgba(32, 168, 224, 1)", color: "rgba(255, 255, 255, 1)" 
                             // Light blue hover effect
                          },
                        }}>
                            <ListItemIcon>
                            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.15659 3.18652H2.59035C2.16857 3.18652 1.76405 3.35408 1.4658 3.65233C1.16755 3.95058 1 4.35509 1 4.77688V15.9093C1 16.3311 1.16755 16.7356 1.4658 17.0339C1.76405 17.3321 2.16857 17.4997 2.59035 17.4997H13.7228C14.1446 17.4997 14.5491 17.3321 14.8474 17.0339C15.1456 16.7356 15.3132 16.3311 15.3132 15.9093V10.3431" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.1189 1.99406C14.4352 1.67772 14.8643 1.5 15.3116 1.5C15.759 1.5 16.1881 1.67772 16.5044 1.99406C16.8208 2.3104 16.9985 2.73945 16.9985 3.18682C16.9985 3.6342 16.8208 4.06325 16.5044 4.37959L8.95024 11.9338L5.76953 12.7289L6.56471 9.54824L14.1189 1.99406Z" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                            </ListItemIcon>
                            Update
                          </MenuItem>}
                          <MenuItem
                            onClick={() => openResetPasswordModal(row.id)}
                            sx={{
                              "&:hover": {
                                backgroundColor: "rgba(32, 168, 224, 1)", color: "rgba(255, 255, 255, 1)" 
                                 // Light blue hover effect
                              },
                            }}
                          >
                            <ListItemIcon>
                            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 2.27637V6.94426H5.36364" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.0004 14.7236V10.0557H12.6367" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.1745 6.16605C14.8057 5.05104 14.1788 4.05414 13.3524 3.26839C12.526 2.48264 11.5269 1.93365 10.4485 1.67263C9.3701 1.41161 8.24745 1.44708 7.18532 1.77572C6.12318 2.10437 5.15618 2.71548 4.37455 3.55203L1 6.94404M17 10.056L13.6255 13.448C12.8438 14.2845 11.8768 14.8956 10.8147 15.2243C9.75255 15.5529 8.6299 15.5884 7.55148 15.3274C6.47307 15.0664 5.47404 14.5174 4.64761 13.7316C3.82119 12.9459 3.1943 11.949 2.82545 10.8339" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                            </ListItemIcon>
                            Reset Password
                          </MenuItem>
{hasPermission("/api/user", "DELETE") &&
                          <MenuItem onClick={() => openDeleteModal(row.id)}
                          sx={{
                            "&:hover": {
                              backgroundColor: "rgba(32, 168, 224, 1)", color: "rgba(255, 255, 255, 1)" 
                               // Light blue hover effect
                            },
                          }}>
                            <ListItemIcon>
                            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 4.40039H2.77778H17" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.2218 4.4V16.3C15.2218 16.7509 15.0345 17.1833 14.7011 17.5021C14.3677 17.8209 13.9155 18 13.444 18H4.55512C4.08363 18 3.63144 17.8209 3.29804 17.5021C2.96464 17.1833 2.77734 16.7509 2.77734 16.3V4.4M5.44401 4.4V2.7C5.44401 2.24913 5.63131 1.81673 5.96471 1.49792C6.29811 1.17911 6.75029 1 7.22179 1H10.7773C11.2488 1 11.701 1.17911 12.0344 1.49792C12.3678 1.81673 12.5551 2.24913 12.5551 2.7V4.4" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.22266 8.65039V13.7504" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.7773 8.65039V13.7504" stroke="#1F242E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                            </ListItemIcon>
                            Delete
                          </MenuItem>
}

                        </Menu>
                      )}
                    </TableCell>
                  </TableRow>
                )
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      <Dialog
        open={isDeleteModalOpen}
        onClose={handleDeleteDialogClose}
        PaperProps={{
          style: {
            backgroundColor: "white",
            width: "594px",
            maxWidth: "600px",
            height:"294px"

          },
        }}
      >
        <DialogTitle
          style={{ fontWeight: "bolder", height: "80px" }}
        >
          <span style={{ color: "#EE3B2B",width:"514px",fontSize:"32px",fontWeight:"600" }}>
            Delete User!
          </span>
          <DialogContentText style={{ color: "rgba(58, 58, 58, 1)",width:"514px",fontSize:"24px",fontWeight:"400" ,marginTop:"-6px"}}>
            Do You Want to Proceed?
          </DialogContentText>
        </DialogTitle>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "75px",
            paddingBottom: "40px",
            justifyContent: "space-evenly",
          }}
        >
          <button
            className='customButton_add'

            onClick={handleDeleteDialogClose}
            color="primary"
            style={{
              padding: "10px 40px",
              fontWeight: "bold",
              textTransform: "none",
              background: "#F2F2F3",
            }}
          >
            Cancel
          </button>
          <button
            className='customButton_add'

            color="primary"
            variant="contained"
            autoFocus
            onClick={handleDelete}
            style={{ padding:"10px 40px",fontWeight:"bold" ,textTransform:"none",background: "#EE3B2B",color:"white"}}
          >
            Delete
          </button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isResetPasswordModalOpen}
        onClose={handleResetPasswordDialogClose}
        PaperProps={{
          style: {
            backgroundColor: "#F3F3F3",
            width: "594",
            height: "60vh",
            borderRadius: "20px",
            maxWidth: "600px",
            background: "#FFFFFF",

            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          },
        }}
      >
        <DialogTitle
          style={{
            fontWeight: "600",
            fontSize: "32px",
            height: "30px",
            marginLeft: "120px",
          }}
        >
          Reset Password
        </DialogTitle>
        <DialogContent style={{ marginLeft: "24px", marginTop: "50px" }}>
          <h3
            style={{
              fontWeight: "500",
              fontSize: "24",
              marginBottom: "-12px",
              color: "black",
            }}
          >
            New Password
          </h3>
          <TextField
            fullWidth
            label="Enter new password"
            type="password"
            autoComplete="new-password"
            margin="normal"
            name="newPassword"
            value={pass.newPassword}
            onChange={handlePassChange}
            // style={{width:"450px"}}
            sx={{ borderRadius: "16px", border: "2px solid #183059" }}
          />
          <h3
            style={{
              fontWeight: "500",
              fontSize: "24",
              marginBottom: "-12px",
              color: "black",
            }}
          >
            Confirm New Password
          </h3>
          <TextField
            fullWidth
            label="Confirm your new password"
            type="password"
            autoComplete="new-password"
            margin="normal"
            name="confirmPassword"
            value={pass.confirmPassword}
            onChange={handlePassChange}
            // style={{width:"450px"}}
            sx={{
              width: "450px",
              borderRadius: "16px",
              border: "2px solid #183059",
            }}
          />
        </DialogContent>
        <DialogActions
          style={{
            paddingRight: "55px",
            paddingBottom: "23px",
            gap: "15px",
            paddingLeft: "10px",
          }}
        >
          <button
            onClick={handleResetPasswordDialogClose}
            className='customButton_add'
            // color="primary"
            // variant='contained'
            autoFocus
            style={{
              background: "#F2F2F3",
              padding: "10px 55px",
              fontSize: "24px",
              fontWeight: "600",
              textTransform: "none",
            }}
          >
            Cancel
          </button>
          <button
          className='customButton_add'
            color="primary"
            variant="contained"
            autoFocus
            onClick={handelPassConfirm}
            style={{
              padding: "10px 55px",
              fontWeight: "600",
              textTransform: "none",
              fontSize: "24px",
              background: "#F9E719",
              color: "black",
            }}
          >
            Confirm
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UsersTable;
