import { Box, Button, Divider, Grid, InputAdornment, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/Circle";
import './driver.css'
import { useNavigate } from "react-router";

const DriverHeader = ({totalDrivers,handleSearch}) => {
const Navigate=useNavigate();

  const handleAddDriver=()=>{
    Navigate('/AddDriver')
  }
	return (
    <>
    <Box>
     <Box className="addbutton_container ">
     <button
       variant="contained"
       className="customButton_add"
       onClick={handleAddDriver}
     >
       + Add Driver
     </button>
     </Box>
 
     <Box className="Headercard">
    <Grid container spacing={3}>
      <Grid item xs>
      <Box className="Headercard_Total">
         <div className="Headercard_Total_text">
         <div className="org-para">
       Total<br/>Drivers
       </div>
       <div>
       <span className="org-count">
          {totalDrivers} 
       </span>
       </div>
     </div>
        </Box>
      </Grid>
 
      <Grid item xs>
         <Box className="Headercard_Active">
         <div className="Headercard_Total_text">
         <div className="org-para">
       Assigned<br/>Vehicles
       </div>
       <div>
       <span className="org-count">
          {totalDrivers} 
       </span>
       </div>
     </div>
        </Box>
      </Grid>
 
      <Grid item xs>
        <Box className="Headercard_inactive">
         <div className="Headercard_Total_text">
         <div className="org-para">
       Not Assigned<br/>Vehicles
       </div>
       <div>
       <span className="org-count">
          {totalDrivers} 
       </span>
       </div>
     </div>
        </Box>
      </Grid>
    </Grid>
  </Box>
     {/* <Divider style={{marginTop:"5px"}}/> */}
     <Box className="search-filter-wrapper">
        {/* TextField component for input */}
       <TextField style={{background:"rgba(242, 242, 243, 1)",color:"rgba(31, 36, 46, 1)"}}
         size="small"
         id="search_drivers"
         label="Search"
     onChange={handleSearch}
         variant="outlined"  // Set variant to outlined
         // Add an icon to the end of the input field
         InputProps={{
           endAdornment: (
             <InputAdornment position="end">
               <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path d="M9 17.5C13.4183 17.5 17 13.9183 17 9.5C17 5.08172 13.4183 1.5 9 1.5C4.58172 1.5 1 5.08172 1 9.5C1 13.9183 4.58172 17.5 9 17.5Z" stroke="#183059" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
 <path d="M18.9984 19.4999L14.6484 15.1499" stroke="#183059" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
 </svg> 
             </InputAdornment>
           ),
         }}
         //  input label styles
         InputLabelProps={{

         }}
       />
     </Box>
   
  </Box>
    </>
    
   );
  };
   
  export default DriverHeader ;