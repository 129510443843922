import React, { memo } from 'react'
import Routes from '../Routes/Routes'


const Layout = () => {
  return (
     <Routes/>
  )
}

export default memo(Layout)
