function sortByParam(param, data) {
  return [...data].sort((a, b) => {
    // Handle null or undefined values
    if (!a[param] && !b[param]) return 0;
    if (!a[param]) return -1;
    if (!b[param]) return 1;

    // Convert to lowercase strings for case-insensitive comparison
    const valueA = String(a[param]).toLowerCase();
    const valueB = String(b[param]).toLowerCase();

    // Handle numeric values
    if (!isNaN(valueA) && !isNaN(valueB)) {
      return Number(valueA) - Number(valueB);
    }

    // String comparison
    return valueA.localeCompare(valueB);
  });
}

export default sortByParam;
