import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import config from "../../config.json";
import DashboardHeader from "../../components/header/DashboardHeader";
import { Grid, Paper, Typography, IconButton } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CalendarToday, Hail } from "@mui/icons-material";

const styles = {
  container: {
    padding: "32px",
  },
  cardSection: {
    marginBottom: "32px",
  },
  card: {
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "12px",
    color: "white",
  },
  vehicleCard: {
    backgroundColor: "rgba(88, 0, 191, 1)",
  },
  stopCard: {
    backgroundColor: "rgba(0, 178, 191, 1)",
  },
  scheduleCard: {
    backgroundColor: "rgba(191, 80, 0, 1)",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
  },
  icon: {
    fontSize: "40px",
    marginTop: "10px",
  },
  mapContainer: {
    width: "100%",
    height: "400px",
    marginBottom: "32px",
  },
  tableContainer: {
    marginTop: "32px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "-7px",
  },
  tableHeader: {
    padding: "8px",
    textAlign: "left",
    backgroundColor: "#f5f5f5",
  },
  tableCell: {
    border: "1px solid #ddd",
    padding: "8px",
  },
  noData: {
    textAlign: "center",
    padding: "16px",
  },
  formHeader: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    marginBottom: "16px",
  },
};

const RouteTrackingComponent = () => {
  const location = useLocation();
  const routeData = location.state?.selectedRouteData;
  const [vehicleData, setVehicleData] = useState({});
  const [mapLoaded, setMapLoaded] = useState(false);
  const [directions, setDirections] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const intervalRef = useRef(null);
  const routeBoundsRef = useRef(null);
  const routePathRef = useRef(null);
  

  const center = routeData
    ? {
        lat: parseFloat(routeData.routeCoordinates[0].latitude),
        lng: parseFloat(routeData.routeCoordinates[0].longitude),
      }
    : { lat: 0, lng: 0 };

  const stopMarkers = routeData
    ? routeData.stops.map((stop) => ({
        position: {
          lat: parseFloat(stop.latitude),
          lng: parseFloat(stop.longitude),
        },
        label: stop.name,
      }))
    : [];

  const snapToRoute = (position) => {
    if (!routePathRef.current) return position;

    const point = new window.google.maps.LatLng(position.lat, position.lng);
    let closestPoint = point;
    let minDistance = Infinity;

    for (let i = 0; i < routePathRef.current.length - 1; i++) {
      const start = routePathRef.current[i];
      const end = routePathRef.current[i + 1];
      const projectedPoint = window.google.maps.geometry.spherical.interpolate(
        start,
        end,
        0.5
      );
      const distance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          point,
          projectedPoint
        );

      if (distance < minDistance) {
        closestPoint = projectedPoint;
        minDistance = distance;
      }
    }

    return { lat: closestPoint.lat(), lng: closestPoint.lng() };
  };

  const calculateRouteDirections = useCallback(() => {
    if (!window.google || !stopMarkers.length) return;

    const directionsService = new window.google.maps.DirectionsService();
    const origin = stopMarkers[0].position;
    const destination = stopMarkers[stopMarkers.length - 1].position;
    const waypoints = stopMarkers.slice(1, -1).map((marker) => ({
      location: new window.google.maps.LatLng(
        marker.position.lat,
        marker.position.lng
      ),
      stopover: true,
    }));

    const request = {
      origin: new window.google.maps.LatLng(origin.lat, origin.lng),
      destination: new window.google.maps.LatLng(
        destination.lat,
        destination.lng
      ),
      waypoints: waypoints,
      travelMode: window.google.maps.TravelMode.DRIVING,
      optimizeWaypoints: false,
    };

    directionsService.route(request, (result, status) => {
      if (status === window.google.maps.DirectionsStatus.OK) {
        console.log("Directions received:", result); // Debug log
        setDirections(result);
        routePathRef.current = result.routes[0].overview_path;

        // Set bounds after getting directions
        if (mapInstance && !routeBoundsRef.current) {
          const bounds = new window.google.maps.LatLngBounds();
          result.routes[0].overview_path.forEach((point) =>
            bounds.extend(point)
          );
          stopMarkers.forEach((marker) => bounds.extend(marker.position));
          routeBoundsRef.current = bounds;
          mapInstance.fitBounds(bounds);
        }
      } else {
        console.error("Directions request failed:", status); // Debug log
        setError(`Error fetching directions: ${status}`);
      }
    });
  }, [stopMarkers, mapInstance]);

  const fetchVehicleData = useCallback(async () => {
    if (!routeData?.vehicles?.length) return;

    console.log("Fetching vehicle data at:", new Date().toLocaleTimeString());
    const destination = stopMarkers[stopMarkers.length - 1].position;
    const newData = {};

    try {
      await Promise.all(
        routeData.vehicles.map(async (vehicle) => {
          try {
            const locationResponse = await fetch(
              `${config.baseURL}/location/latest/${vehicle.device_id}`
            );

            if (!locationResponse.ok) {
              throw new Error(
                `Location API returned ${locationResponse.status}`
              );
            }

            const locationData = await locationResponse.json();

            if (!locationData?.latitude || !locationData?.longitude) {
              console.warn(
                `Invalid location data for vehicle ${vehicle.device_id}`
              );
              return;
            }

            const position = {
              lat: parseFloat(locationData.latitude),
              lng: parseFloat(locationData.longitude),
            };

            const snappedPosition = snapToRoute(position);

            const disTimeResponse = await fetch(
              `${config.baseURL}/distance/time/stop`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  vehicleId: vehicle.id,
                  longitude: destination.lng,
                  latitude: destination.lat,
                }),
              }
            );

            if (!disTimeResponse.ok) {
              throw new Error(
                `Distance/Time API returned ${disTimeResponse.status}`
              );
            }

            const disTimeData = await disTimeResponse.json();
            const currentTime = new Date().toLocaleTimeString();

            newData[vehicle.device_id] = {
              position: snappedPosition,
              speed: locationData.Speed
                ? parseFloat(locationData.Speed).toFixed(1)
                : 0,
              distance: disTimeData.distance || "N/A",
              eta: disTimeData.eta || "N/A",
              lastUpdate: currentTime,
            };
          } catch (error) {
            console.error(
              `Error processing vehicle ${vehicle.device_id}:`,
              error
            );
          }
        })
      );

      setVehicleData(newData);
      setLastUpdateTime(new Date().toLocaleTimeString());
    } catch (error) {
      console.error("Error fetching vehicle data:", error);
      setError("Failed to fetch vehicle data");
    } finally {
      setLoading(false);
    }
  }, [routeData?.vehicles, stopMarkers]);

   useEffect(() => {
    if (mapLoaded && routeData && mapInstance) {
      console.log("Calculating directions..."); // Debug log
      calculateRouteDirections();
    }
  }, [mapLoaded, routeData, mapInstance]);

useEffect(() => {
  // Clear any existing interval
  if (intervalRef.current) {
    clearInterval(intervalRef.current);
  }

  // Only start interval if we have route data
  if (routeData) {
    intervalRef.current = setInterval(() => {
      console.log("Interval triggered at:", new Date().toLocaleTimeString());
      fetchVehicleData();
    }, 10000); // 10 seconds
  }

  // Cleanup function
  return () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };
}, [routeData, fetchVehicleData]);

  const onLoad = useCallback((map) => {
    setMapLoaded(true);
    setMapInstance(map);
  }, []);

  if (!routeData) {
    return (
      <div style={styles.noData}>
        <Typography variant="h6">No route data available</Typography>
      </div>
    );
  }

  if (error) {
    return (
      <div style={styles.noData}>
        <Typography color="error">{error}</Typography>
      </div>
    );
  }

  return (
    <>
      <DashboardHeader title={`Routes > Monitor: ${routeData.name}`} />
      <div style={styles.container}>
        {/* Dashboard Cards */}
        <Grid container spacing={2} style={styles.cardSection}>
          {/* Vehicles Card */}
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} sx={{ ...styles.card, ...styles.vehicleCard }}>
              <div style={styles.cardContent}>
                <Typography variant="h6">Vehicles</Typography>
                <DirectionsCarIcon sx={styles.icon} />
                <Typography variant="h5">
                  {routeData.vehicles.length}
                </Typography>
              </div>
              <IconButton>
                <ArrowForwardIosIcon sx={{ color: "white" }} />
              </IconButton>
            </Paper>
          </Grid>

          {/* Stops Card */}
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} sx={{ ...styles.card, ...styles.stopCard }}>
              <div style={styles.cardContent}>
                <Typography variant="h6">Stops</Typography>
                <Hail sx={styles.icon} />
                <Typography variant="h5">{routeData.stops.length}</Typography>
              </div>
              <IconButton>
                <ArrowForwardIosIcon sx={{ color: "white" }} />
              </IconButton>
            </Paper>
          </Grid>

          {/* Schedule Card */}
          <Grid item xs={12} sm={4}>
            <Paper
              elevation={3}
              sx={{ ...styles.card, ...styles.scheduleCard }}
            >
              <div style={styles.cardContent}>
                <Typography variant="h6">Schedules</Typography>
                <CalendarToday sx={styles.icon} />
                <Typography variant="h5">
                  {routeData.schedules?.length || 0}
                </Typography>
              </div>
              <IconButton>
                <ArrowForwardIosIcon sx={{ color: "white" }} />
              </IconButton>
            </Paper>
          </Grid>
        </Grid>

        {/* Google Map */}
        <LoadScript
          googleMapsApiKey="AIzaSyD7rqUpTzUpEbxm-Xc7ikltFJGzOhd92Qk"
          libraries={["geometry"]}
        >
          <GoogleMap
            mapContainerStyle={styles.mapContainer}
            center={center}
            zoom={14}
            onLoad={onLoad}
          >
            {mapLoaded && (
              <>
                {directions && (
                  <DirectionsRenderer
                    directions={directions}
                    options={{
                      suppressMarkers: true,
                      preserveViewport: true, // Prevents automatic viewport adjustments
                      polylineOptions: {
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 3,
                      },
                    }}
                  />
                )}
                {stopMarkers.map((marker, index) => (
                  <Marker
                    key={index}
                    position={marker.position}
                    label={marker.label}
                    icon={
                      index === 0 || index === stopMarkers.length - 1
                        ? {
                            url:
                              index === 0
                                ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
                                : "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                            scaledSize: new window.google.maps.Size(40, 40),
                          }
                        : undefined
                    }
                  />
                ))}
                {Object.entries(vehicleData).map(([deviceId, data]) => (
                  <Marker
                    key={deviceId}
                    position={data.position}
                    icon={{
                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                      scale: 6,
                      fillColor: "#4285F4",
                      fillOpacity: 1,
                      strokeWeight: 2,
                      rotation: 0,
                    }}
                  />
                ))}
              </>
            )}
          </GoogleMap>
        </LoadScript>

        {/* Vehicle Status Table */}
        <div style={styles.tableContainer}>
          <Typography variant="h6" style={styles.formHeader}>
            Vehicle Status
          </Typography>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={{ ...styles.tableHeader, paddingLeft: "30px" }}>
                  Vehicle ID
                </th>
                <th style={styles.tableHeader}>Distance</th>
                <th style={styles.tableHeader}>ETA</th>
                <th style={styles.tableHeader}>Speed (km/h)</th>
                <th style={styles.tableHeader}>Last Update</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5" style={styles.noData}>
                    Loading vehicle data...
                  </td>
                </tr>
              ) : Object.keys(vehicleData).length > 0 ? (
                Object.entries(vehicleData).map(([deviceId, data]) => (
                  <tr key={deviceId}>
                    <td style={styles.tableCell}>{deviceId}</td>
                    <td style={styles.tableCell}>{data.distance}</td>
                    <td style={styles.tableCell}>{data.eta}</td>
                    <td style={styles.tableCell}>{data.speed}</td>
                    <td style={styles.tableCell}>{data.lastUpdate}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" style={styles.noData}>
                    No vehicle data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default RouteTrackingComponent;
