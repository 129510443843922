import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/Circle";
import "./User.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import "../Schedule/schedule.css";
import "../../pages/organization/Organization.css";
import "../Schedule/schedule.css";
import "../../pages/organization/Organization.css";
import { usePermission } from "../../hooks/usePermission";

const UserHeader = ({
  handleSearch,
  handleFilter,
  currentUserListToDisplay,
  displayUserList,
}) => {
  const Navigate = useNavigate();

  const { hasPermission } = usePermission();

  const selectStyles = {
    fontWeight: "400",
    paddingRight: "24px",
    background: "rgba(242, 242, 243, 1)",
  };
  const handleAdd = () => {
    Navigate("/AddUser");
  };
  useEffect(() => {
    console.log(hasPermission("/api/user", "POST"), "hasPermission");
  });
  return (
    <>
      <Box className="addbutton_container">
        {hasPermission("/api/role", "") && (
          <button
            style={{
              background: "rgba(32, 168, 224, 1)",
              color: "rgba(255, 255, 255, 1)",
            }}
            onClick={() => {
              Navigate("/roles");
            }}
            variant="contained"
            className="customButton_add"
          >
            Manage User Roles
          </button>
        )}
        {hasPermission("/api/user", "POST") && (
          <button
            variant="contained"
            className="customButton_add"
            onClick={handleAdd}
          >
            + Add User
          </button>
        )}
      </Box>
      {/* <Divider/> */}

      <Box className="Headercard">
        <Grid container spacing={3}>
          <Grid item xs>
            <Box className="Headercard_Total">
              <div className="Headercard_Total_text">
                <div className="org-para">
                  Total
                  <br /> User
                </div>
                <div>
                  <span className="org-count">{displayUserList}</span>
                </div>
              </div>
            </Box>
          </Grid>

          <Grid item xs>
            <Box className="Headercard_Active">
              <div className="Headercard_Total_text">
                <div className="org-para">
                  Active <br />
                  Users
                </div>
                <div>
                  <span className="org-count">{displayUserList}</span>
                </div>
              </div>
            </Box>
          </Grid>
          <Grid item xs>
            <Box className="Headercard_inactive">
              <div className="Headercard_Total_text">
                <div className="org-para">
                  Inactive
                  <br /> Users
                </div>
                <div>
                  <span className="org-count">{displayUserList}</span>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="search-filter-wrapper">
        <div>
          <TextField
            size="small"
            id="search_users"
            placeholder="Search"
            style={{ background: "rgba(242, 242, 243, 1)" }}
            onChange={handleSearch}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 17.5C13.4183 17.5 17 13.9183 17 9.5C17 5.08172 13.4183 1.5 9 1.5C4.58172 1.5 1 5.08172 1 9.5C1 13.9183 4.58172 17.5 9 17.5Z"
                      stroke="#183059"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.9984 19.4999L14.6484 15.1499"
                      stroke="#183059"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </InputAdornment>
              ),
            }}
            //  input label styles
            // InputLabelProps={{
            //   style: { color: 'white', fontWeight: '400',fontSize:"18px",paddingLeft:"24px",marginTop:"16px",
            //   },
            // }}
          />
        </div>

        <div>
          <FormControl
            fullWidth
            size="small"
            style={{
              width: "250px",
              borderRadius: "5px",
              paddingRight: "24px",
            }}
          >
            <InputLabel
              id="roles-dropdown"
              // style={{fontSize:"18px",fontWeight:"400",color:"white",paddingTop:"16px",}}
            >
              Roles
            </InputLabel>
            <Select
              labelId="roles-dropdown"
              id="roles-dropdown"
              placeholder="Roles"
              style={selectStyles}
              IconComponent={ExpandMoreIcon}
              onChange={handleFilter}
            >
              <MenuItem value="All">All</MenuItem>
              {currentUserListToDisplay.map((user) => (
                <MenuItem key={user.id} value={user.role}>
                  {user.role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Box>
    </>
  );
};

export default UserHeader;
