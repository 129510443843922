import React from "react";
import { Routes, Route } from "react-router-dom";
import Vehicle from "../pages/vehicle/Vehicle";
import Users from "../pages/Users/Users";
import Role from "../pages/role/Role";
import Feature from "../pages/feature/Feature";
import Routess from "../pages/Routes/Routess";
import Stops from "../pages/stops/Stops";
import Tracking from "../pages/tracking/Tracking";
import RoleAccess from "../pages/role/RoleAccess";
import AddUser from "../pages/Users/AddUser";
import Driver from "../pages/driver/Driver";
import AddDriver from "../pages/driver/AddDriver";
import Organization from "../pages/organization/Organization";
import AddOrganization from "../pages/organization/addOrganization/AddOrganization";
import Overview from "../pages/dashboard/dashboarddata/Overview";
import AddRoute from "../pages/Routes/addRoute/AddRoute";
import AddVehicle from "../pages/vehicle/addVehicle/AddVehicle";
import AddAdmin from "../pages/organization/OrgAdmin";
import Alert from "../pages/alerts/Alert";
import FeatureAssignment from "../pages/organization/featureAssign/FeatureAssignment";
import Schedule from "../pages/Schedule/Schedule";
import StopAddUpdatePage from "../pages/stops/AddUpdateStops/StopAddUpdate";
import RouteTrackingComponent from "../pages/Routes/RouteTracking";
import MapPage from "../pages/stops/mapPage";
import AllStopsMapPage from "../pages/stops/AllStops";
import RouteSchedules from "../pages/Routes/routesSchedule.js/RouteSchedules";
import StopScheduleDetails from "../pages/stops/StopsScheduleDetails/StopScheduleDetails";
import DeviceLocationMap from "../pages/vehicle/VehicleTracker";
import AddSchedule from "../pages/Schedule/AddSchedule";


const Content = () => {
  return (
    <Routes>
      <Route path="/Users" element={<Users />} />
      <Route path="/Vehicle" element={<Vehicle />} />
      <Route path="/vehicle/add" element={<AddVehicle />} />
      <Route path="/Roles" element={<Role />} />
      <Route path="/Feature" element={<Feature />} />
      <Route path="/Route" element={<Routess />} />
      <Route path="/route/add" element={<AddRoute />} />
      <Route path="/Stops" element={<Stops />} />
      <Route path="/Tracking" element={<Tracking />} />
      <Route path="/AddUser" element={<AddUser />} />
      <Route path="/Driver" element={<Driver />} />
      <Route path="/AddDriver" element={<AddDriver />} />
      <Route path="/organization" element={<Organization />} />
      <Route path="/organization/register" element={<Organization />} />
      <Route path="/organization/add" element={<AddOrganization />} />
      <Route path="/organization/feature/:id" element={<FeatureAssignment />} />
      <Route path="/schedule" element={<Schedule />} />
      <Route path="/overview" element={<Overview />} />
      <Route path="/admin/add" element={<AddAdmin />} />
      <Route path="/alerts" element={<Alert />} />
      <Route path="/stop/add" element={<StopAddUpdatePage />} />
      <Route path="/stop/:stopId" element={<StopAddUpdatePage />} />
      <Route path="/route/track" element={<RouteTrackingComponent />} />
      <Route path="/stop/track" element={<MapPage />} />
      <Route path="/stop/all/map" element={<AllStopsMapPage />} />
      <Route path="/route/schedules/" element={<RouteSchedules />} />
      <Route path="/Stop/Schedule/Details" element={<StopScheduleDetails />} />
      <Route path="/device/track" element={<DeviceLocationMap />} />
      <Route path="/role/:Id" element={<RoleAccess />} />
      <Route path="/schedule/add" element={<AddSchedule />} />
      <Route path="/schedule/:scheduleId" element={<AddSchedule />} />
    </Routes>
  );
};

export default Content;
