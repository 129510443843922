import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import './stops.css';
import "./stopScheduleDetails.css";


const repitations=[
  {id:1,repitation:"daily"},
  {id:2,repitation:"weekly"},
  {id:3,repitation:"monthly"}
]

const StopScheduleFilter = ({
  handleRepetitionChange,
  handleVehicleChange,
  routesData,
  vehiclesData,
  handleRouteChange,
  filterSchedules}) => {

  return (
    <Box
      sx={{ minWidth: 700 }}   // Set minimum width for the container
      display="flex"
      gap={2}
      justifyContent="space-around"
    >

      {/* Dropdown for filtering by repetition */}
      <FormControl fullWidth size="small">
        <InputLabel id="routes-dropdown">Repitation</InputLabel>
        <Select
          labelId="routes-dropdown"
          id="routes-dropdown"
          label="Routes"
          value={filterSchedules.repitation}
          onChange={handleRepetitionChange}
        >
          <MenuItem value={'All'}>All</MenuItem> 
          {repitations.map((item) => (
            <MenuItem key={item.id} value={item.repitation}>
              {item.repitation}
            </MenuItem>
          ))}    
          
        </Select>
      </FormControl>
      <FormControl fullWidth size="small">
        <InputLabel id="stops-dropdown">Vehicles</InputLabel>
        <Select
          labelId="stops-dropdown"
          id="name"
          label="stops"
          value={filterSchedules.Vehicle}
          onChange={handleVehicleChange}
        >
          <MenuItem value={'All'}>All</MenuItem>
          {vehiclesData.map((vehicle)=>(
            <MenuItem key={vehicle.id} value={vehicle.name}>
              {vehicle.name}
            </MenuItem>
          ))}
        
        </Select>
      </FormControl>
      <FormControl fullWidth size="small">
        <InputLabel id="stops-dropdown">Routes</InputLabel>
        <Select
          labelId="stops-dropdown"
          id="name"
          label="stops"
          value={filterSchedules.routes}
          onChange={handleRouteChange}
        >
          <MenuItem value={'All'}>All</MenuItem>
          {routesData.map((route) => (
            <MenuItem key={route.id} value={route.name}>
              {route.name}
            </MenuItem>
          ))}
        
        </Select>
      </FormControl>
    </Box>
  );
};

export default StopScheduleFilter;
