// import { Logout, Menu } from '@mui/icons-material';
import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { Close, Logout, Menu } from "@mui/icons-material";
import { useLocation } from "react-router";
// import "./HomeHeaderDashboard.css";
import { useNavigate } from "react-router-dom";

const HomeHeaderDashboard = ({ title }) => {
  const navigate = useNavigate();

  return (
    <section className="dashboard-header">
      <header className="dh-container">
        <div
          className="dh-menu"
          onClick={() => {
            const nav_header = document.querySelector(".navbar");
            const toggleMobile = () => {
              nav_header.classList.toggle("active");
            };
            toggleMobile();
          }}
        >
          <Menu className="mobile-icon" name="menu" />
          <Close className="mobile-icon" name="close" />

          <div className="dh-page">
            <h1 style={{ wordSpacing: "1px", color: "white",fontSize:'32px',fontWeight:'600' }}>{title}</h1>
          </div>
        </div>
        <div className="dh-profile">

          <div className="dh-image">
            <PersonIcon
              style={{
                color: 'rgba(24, 48, 89, 1)',
                background: 'rgba(232, 234, 238, 1)', 
              }}
            />
            <img src="" alt="Profile" />
          </div>
          <div>
            {title === "Organizations > Register" ? (
              <button
                className="dh-logout"
                onClick={() => {
                  navigate("/");
                }}
              >
                <div style={{fontSize:'32px',fontWeight:'600'}}>
                  <span>Have an account?</span>
&nbsp;
<span style={{ color: 'rgba(249, 231, 25, 1)' }}>Login</span>

                </div>
              
              </button>
            ) : (
              <svg
                width="18"
                height="10"
                viewBox="0 0 18 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L9 9L17 1"
                  stroke="#F9E719"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        </div>
      </header>
    </section>
  );
};

export default HomeHeaderDashboard;
