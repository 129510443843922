import React from "react";
import { Link } from "react-router-dom";
import "./homeHeader.css";
import { AddComponent } from "../../../config/Pageitems";
import { Add } from "@mui/icons-material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Navigate, useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";

const HomeHeader = () => {
  const navigate = useNavigate();

  // const HomeNav = [
  //     { lable: 'Features', path: '/' },
  //     { lable: 'Pricing', path: '/' },
  //     { lable: 'Support', path: '/' },
  //     { lable: 'Resources', path: '/' },
  //     { lable: 'Contact Us', path: '/' },
  // ]

    return (
        <Box sx={{width:"100%"}}>
        <section className='home-header'>
            <div className="hh-container">
                <div className="hh-navlink">
                <div className="hh-logo">
                <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" /> 
                <h1 style={{marginLeft:"12px",fontWeight:"600",marginTop:"7px"}}>RouteEye</h1>
                </div>
                    {/* <ul className="hh-navdata">
  return (
    <Box sx={{ width: "100%" }}>
      <section className="home-header">
        <div className="hh-container">
          <div className="hh-navlink">
            <div className="hh-logo">
              <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" />

              <h1
                style={{
                  marginLeft: "12px",
                  fontWeight: "600",
                  marginTop: "7px",
                }}
              >
                RouteEye
              </h1>
            </div>
            {/* <ul className="hh-navdata">
                        {
                            HomeNav?.map((item, index) => {
                                return (
                                    <li key={index}><Link to={item.path} className="hh-link">{item.lable}</Link></li>
                                )
                            })
                        }
                    </ul> */}

          </div>
          <div className="hh-buttons">
            <QuestionMarkIcon
              style={{
                background: "rgba(24, 48, 89, 1)",
                borderRadius: "50px",
                color: "white",
              }}
            />
            <button
              variant="contained"
              className="customButton_add"
              style={{
                background: "rgba(32, 168, 224, 1)",
                color: "rgba(255, 255, 255, 1)",
                fontSize: "20px",
                fontWeight: "400",
                marginRight: "40px",
              }}
              onClick={() => {
                navigate("/register");
              }}
            >

              Register Organization
            </button>
          </div>
        </div>
      </section>
    </Box>
  );
};

export default HomeHeader;
