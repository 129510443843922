import React, { useState } from "react";
import "./pagination.css";
import { Button } from "@mui/material";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const [page, setPage] = useState(currentPage);

  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setPage(pageNumber);
      onPageChange(pageNumber);
    }
  };

  const goToPreviousPage = () => {
    goToPage(page - 1);
  };

  const goToNextPage = () => {
    goToPage(page + 1);
  };

  return (
    <>
      <div className="pagination">
        <span className="page">
          <select
            name="rows"
            id="rows"
            style={{
              border: "none",
              fontSize: "20px",
              background: "none",
              marginTop: "9px",
            }}
          >
            <option value="10 rows">10 Rows</option>
            <option value="20 rows">20 Rows</option>
            <option value="30 rows">30 Rows</option>
            <option value="40 rows">40 Rows</option>
            <option value="50 rows">50 Rows</option>
            <option value="60 rows">60 Rows</option>
          </select>
        </span>

        <div className="pagination_rows_container" style={{ border: "none" }}>
          {totalPages === 1 ? (
            <button style={{ background: "rgba(183, 191, 204, 1)" }}>{page}</button>
          ) : (
            <>
              <button style={{ background: "rgba(183, 191, 204, 1)" }}>{page}</button>
              <button>{totalPages}</button>
            </>
          )}
        </div>

        <div className="pagination-btn">
          <Button
            variant="outlined"
            style={{
              border:"none",
              color: "rgba(31, 36, 46, 1)",
              background: page === 1 ? "rgba(242, 242, 243, 1)" : "rgba(32, 168, 224, 1)", // Change color when disabled
              textTransform: "none",
            }}
            onClick={goToPreviousPage}
            disabled={page === 1}
          >
            &lt;Previous
          </Button>

          <Button
            variant="outlined"
            style={{
              border:"none",
              color: "rgba(255, 255, 255, 1)",
              background: page === totalPages ? "rgba(183, 191, 204, 1)" : "rgba(32, 168, 224, 1)", // Change color when disabled
              textTransform: "none",
            }}
            onClick={goToNextPage}
            disabled={page === totalPages}
          >
            Next&gt;
          </Button>
        </div>
      </div>
    </>
  );
};

export default Pagination;
