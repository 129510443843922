import AddIcon from "@mui/icons-material/Add";
import { Button, Paper, Divider, Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import config from "../../config.json";
import OrganizationFilter from "./OrganizationFilter";
import OrganizationTable from "./OrganizationTable";
import SearchOrganization from "./SearchOrganization";
import DashboardHeader from "../../components/header/DashboardHeader";

import "./Organization.css";

const Organization = () => {
  const [orgData, setOrgData] = useState([]);
  const [filters, setFilters] = useState({
    name: "",
    city: "",
    status: "",
  });

 

  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
   
      const currentPath = location.pathname;
      console.log("currentPath", currentPath);
      try {
        const resource = currentPath==="/organization"?"organization":"register"
        const response = await axios.get(`${config.baseURL}/${resource}`);
        const data = response.data;
        if (isMounted && data && Array.isArray(data.organizations)) {
          setOrgData(data.organizations);
        } else {
          console.error("Failed to fetch data:");
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching organizations:", error);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  const filteredData = orgData.filter((org) => {
    return (
      (filters.name === "" || org.name === filters.name) &&
      (filters.city === "" || org.city === filters.city) &&
      (filters.status === "" || org.organizationStatus === filters.status) &&
      (searchQuery === "" ||
        org.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        org.domain.toLowerCase().includes(searchQuery.toLowerCase()) ||
        org.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
        org.contactName.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  });

  return (
    <>
    <DashboardHeader title="Organizations"/>
    <Paper style={{ margin: "20px", height: "100%" }}>
      <OrgHeader orgData={orgData} navigate={navigate} />
    
      <div className="search-filter-wrapper">
        <SearchOrganization handleSearch={handleSearch} />
        <OrganizationFilter
          orgData={orgData}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
      <OrganizationTable
        orgData={orgData}
        setOrgData={setOrgData}
        filteredData={filteredData}
      />
    </Paper>
    </> 
  );
};

const OrgHeader = ({ orgData, navigate }) => {
  return (
    <div style={{marginTop:'32px'}}>
      <Box className="addbutton_container">

    
      {/* <div>
        <div className="org-para">
          Keep track of All Organizations in One Place.
        </div>
        <div>
          <span className="org-count">
            {orgData.length}{" "}
            {orgData.length > 1 ? "Organizations" : "Organization"}
          </span>
        </div>
      </div> */}

<button style={{background:'rgba(32, 168, 224, 1)',color:"rgba(255, 255, 255, 1)"}}
        variant="contained"
       className="customButton_add"
        onClick={() => navigate("/AddOrganization")}
    
      >
        + Assign Features
      </button>

      <button 
        variant="contained"
        className="customButton_add"
        onClick={() => navigate("/AddOrganization")}
    
      >
        + Add Organization
      </button>
      </Box>
    
    
    
    <Box className="Headercard">
    <Grid container spacing={3}>
      <Grid item xs>
        <Box className="Headercard_Total">
        <div className="Headercard_Total_text">
        <div className="org-para">
          Total <br/>Organization
        </div>
        <div >
          <span className="org-count">
            {orgData.length}{" "}
            {orgData.length > 1 ? "" : ""}
          </span>
        </div>
      </div>
        </Box>
      </Grid>


      <Grid item xs>
        <Box className="Headercard_Active">
        <div className="Headercard_Total_text">
        <div className="org-para">
          Active<br/>Organizations
        </div>
        <div>
          <span className="org-count">
            {orgData.length}{" "}
            {orgData.length > 1 ? "" : ""}
          </span>
        </div>
      </div>
        </Box>
      </Grid>


      <Grid item xs>
        <Box className="Headercard_inactive">
        <div className="Headercard_Total_text">
        <div className="org-para">
          Inactive <br/>Organizations
        </div>
        <div>
          <span className="org-count">
            {orgData.length}{" "}
            {orgData.length > 1 ? "" : ""}
          </span>
        </div>
      </div>
        </Box>
      </Grid>
      <Grid item xs>
        <Box className="Headercard_dead">
        <div className="Headercard_Total_text">
        <div className="org-para">
          New <br/>Request
        </div>
        <div>
          <span className="org-count">
            {orgData.length}{" "}
            {orgData.length > 1 ? "" : ""}
          </span>
        </div>
      </div>
        </Box>
      </Grid>
    </Grid>
  </Box>
  </div>
  );
};

export default Organization;
