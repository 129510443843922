import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import './stops.css';

const StopsFilter = ({
  stopsData,
  selectedRouteFilter,
  onRouteSelectChange,
  onStopNameSelectChange,
  selectedStopNameFilter,
  routesData,
}) => {

  return (
    <Box
      sx={{ minWidth: 500 }}   // Set minimum width for the container
      display="flex"
      gap={2}
      justifyContent="space-around"
    >

      {/* Dropdown for filtering by routes */}
      <FormControl fullWidth size="small">
        <InputLabel id="routes-dropdown">Routes</InputLabel>
        <Select style={{background:"rgba(242, 242, 243, 1)"}}
          labelId="routes-dropdown"
          id="routes-dropdown"
          label="Routes"
          value={selectedRouteFilter.name}
          onChange={onRouteSelectChange}
        >
          <MenuItem value={'All'}>All</MenuItem>     
          {routesData.map((item) => (
            <MenuItem key={item.id} value={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth size="small">
        <InputLabel id="stops-dropdown">stops</InputLabel>
        <Select style={{background:"rgba(242, 242, 243, 1)"}}
          labelId="stops-dropdown"
          id="name"
          label="stops"
          value={selectedStopNameFilter.name}
          onChange={onStopNameSelectChange}
        >
          <MenuItem value={'All'}>All</MenuItem>
          {[...new Set(stopsData.map(stop => stop.name))].map((stopName) => (
            <MenuItem key={stopName} value={stopName}>
              {stopName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default StopsFilter;
