import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config.json";
import { useParams, useNavigate } from "react-router-dom";
import DashboardHeader from "../../components/header/DashboardHeader";

async function createSchedule(schedule) {
  const response = await axios.post(`${config.baseURL}/schedule`, schedule, {
    withCredentials: true,
  });
  return response.data;
}

async function createStopSchedules(scheduleId, stopSchedules) {
  const response = await axios.post(
    `${config.baseURL}/schedule/stop`,
    {
      scheduleId,
      stopSchedules,
    },
    {
      withCredentials: true,
    }
  );
  return response.data;
}

async function updateSchedule(schedule) {
  try {
    console.log("this is schedule", schedule);
    const response = await axios.put(`${config.baseURL}/schedule`, schedule, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to update schedule", error);
  }
}

async function updateStopSchedules(stopSchedules) {
  try {
    console.log("this is stopSchedules", stopSchedules);
    const response = await axios.put(
      `${config.baseURL}/schedule/stop`,
      stopSchedules,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to update stop schedules", error);
  }
}

async function getScheduleById(id) {
  try {
    const response = await axios.get(
      `${config.baseURL}/schedule/id?scheduleId=${id}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to fetch schedules", error);
  }
}

async function getAllRoutes() {
  try {
    const response = await axios.get(`${config.baseURL}/route/organization`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch routes", error);
  }
}

async function getAllVehicles() {
  try {
    const response = await axios.get(`${config.baseURL}/vehicle/organization`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch vehicles", error);
  }
}

async function getStopByRouteCoordinate(longitude, latitude, organizationId) {
  try {
    const response = await axios.get(
      `${config.baseURL}/stop/organization/location`,
      {
        params: { longitude, latitude, organizationId },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch organization locations", error);
  }
}

async function getStopById(id) {
  try {
    const response = await axios.get(`${config.baseURL}/stop/id?id=${id}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch organization locations", error);
  }
}

function getFormattedDate(stopSchedules) {
  let formattedStopSchedulesPromises = stopSchedules.map((stopSchedule) => {
    // Return a promise for each stopSchedule
    return getStopById(stopSchedule.stopId).then((stop) => {
      return {
        ...stopSchedule,
        stopName: stop.name,
      };
    });
  });

  // Use Promise.all to wait for all promises to resolve and return a promise
  return Promise.all(formattedStopSchedulesPromises)
    .then((formattedStopSchedules) => {
      console.log(formattedStopSchedules, "formattedStopSchedules");
      return formattedStopSchedules;
    })
    .catch((err) => {
      console.error("Error in getting formatted stop schedules:", err);
      return [];
    });
}

const AddSchedule = () => {
  const { scheduleId } = useParams();
  const navigate = useNavigate();
  const [schedule, setSchedule] = useState({
    name: "",
    description: "",
    startTime: "",
    endTime: "",
    repitation: "",
    organizationId: 13,
    vehicleIds: [],
    routeId: "",
  });
  const [stopSchedules, setStopSchedules] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [routesData, vehiclesData] = await Promise.all([
          getAllRoutes(),
          getAllVehicles(),
        ]);
        setRoutes(routesData);
        setVehicles(vehiclesData);

        if (scheduleId) {
          const scheduleData = await getScheduleById(scheduleId);
          setSchedule({
            ...scheduleData,
            vehicleIds: scheduleData.vehicleSchedule.map((vs) => vs.vehicle.id),
          });
          const formattedStopSchedules = await getFormattedDate(
            scheduleData.stopSchedules
          );
          setStopSchedules(formattedStopSchedules);
          setSelectedRoute(
            routesData.find((r) => r.id === scheduleData.routeId)
          );
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, [scheduleId]);
  useEffect(() => {
    if (routes.length > 0 && schedule) {
      const foundRoute = routes.find((r) => r.id === schedule.routeId);
      setSelectedRoute(foundRoute);
    }
  }, [routes, schedule]);

  const handleChange = async (e) => {
    let { name, value } = e.target;
    if (name === "startTime" || name === "endTime") {
      value += ":00.00Z";
    }
    setSchedule({ ...schedule, [name]: value });

    if (name === "routeId") {
      const route = routes.find((r) => r.id === parseInt(value));
      setSelectedRoute(route);
      if (route) {
        const stopPromises = route.routeCoordinates.map(
          async (coord, index) => {
            const stopInfo = await getStopByRouteCoordinate(
              coord.longitude,
              coord.latitude,
              route.organizationId
            );
            if (!stopInfo) {
              console.log("Stop not found", stopInfo);
              return;
            }
            return {
              order: index + 1,
              longitude: coord.longitude,
              latitude: coord.latitude,
              arrivalTime: "",
              departureTime: "",
              stopId: stopInfo?.id,
              stopName: stopInfo?.name,
            };
          }
        );
        const initialStopSchedules = await Promise.all(stopPromises);
        setStopSchedules(initialStopSchedules.filter((stop) => !!stop));
        setSelectedRoute(route);
      } else {
        setStopSchedules([]);
      }
    }
  };
  const handleVehicleChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) =>
      parseInt(option.value)
    );
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      vehicleIds: selectedOptions,
    }));
    setSelectedRoute(
      schedule.routeId ? routes.find((r) => r.id === schedule.routeId) : null
    );
  };

  const handleStopChange = (index, e) => {
    const { name, value } = e.target;
    const updatedStops = [...stopSchedules];
    updatedStops[index] = { ...updatedStops[index], [name]: value };
    setStopSchedules(updatedStops);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let createdSchedule;
      if (scheduleId) {
        console.log(schedule, "schedule");
        createdSchedule = await updateSchedule(schedule);
      } else {
        createdSchedule = await createSchedule(schedule);
      }

      if (stopSchedules.length > 0 && stopSchedules[0].id) {
        console.log(stopSchedules, "stopSchedules");
        const formattedStopSchedules = stopSchedules.map((stopSchedule) => ({
          id: stopSchedule.id,
          arrivalTime: stopSchedule.arrivalTime,
          departureTime: stopSchedule.departureTime,
          stopId: stopSchedule.stopId,
        }));
        await updateStopSchedules(formattedStopSchedules);
      } else if (stopSchedules.length > 0) {
        const formattedStopSchedules = stopSchedules.map((stopSchedule) => ({
          arrivalTime: stopSchedule.arrivalTime,
          departureTime: stopSchedule.departureTime,
          stopId: stopSchedule.stopId,
        }));
        await createStopSchedules(createdSchedule.id, formattedStopSchedules);
      }

      navigate("/schedule");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <DashboardHeader title={`Schedule ${scheduleId ? "Update" : "Add"}`} />
      <div style={{ background: "white", padding: "32px" }}>
        <form onSubmit={handleSubmit}>
          <div className="org-heading form_header">
            <p>Schedule Details</p>
          </div>

          <div className="mandatory_field_container">
            <div className="mandatory_box_text">
              <label>Schedule Name</label>
              <div className="mandatory_box">
                <input
                  type="text"
                  name="name"
                  value={schedule.name}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="mandatory_box_text">
              <label>Repetition</label>
              <div className="mandatory_box">
                <select
                  name="repitation"
                  value={schedule.repitation}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Repetition</option>
                  <option value="DAILY">Daily</option>
                  <option value="WEEKLY">Weekly</option>
                  <option value="MONTHLY">Monthly</option>
                </select>
              </div>
            </div>
          </div>

          <div className="mandatory_field_container">
            <div className="mandatory_box_text">
              <label>Schedule Start Time</label>
              <div className="mandatory_box">
                <input
                  type="text"
                  name="startTime"
                  value={schedule.startTime.slice(0, -1)}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="mandatory_box_text">
              <label>Schedule End Time</label>
              <div className="mandatory_box">
                <input
                  type="text"
                  name="endTime"
                  value={schedule.endTime.slice(0, -1)}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>

          <div className="mandatory_field_container">
            <div className="mandatory_box_text">
              <label>Assign Route</label>
              <div className="mandatory_box">
                <select
                  name="routeId"
                  value={schedule.routeId}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Route</option>
                  {routes?.map((route) => (
                    <option key={route.id} value={route.id}>
                      {route.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mandatory_box_text">
              <label>Assign Vehicle:</label>
              <div className="mandatory_box">
                <select
                  name="vehicleId"
                  value={schedule.vehicleId}
                  onChange={handleChange}
                >
                  <option value="">Select Vehicle</option>
                  {vehicles?.map((vehicle) => (
                    <option key={vehicle.id} value={vehicle.id}>
                      {vehicle.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div>
            <div className="mandatory_box_text" style={{ display: "grid" }}>
              <label>Schedule Description</label>
              <div className="description_input_box">
                <textarea
                  name="description"
                  value={schedule.description}
                  onChange={handleChange}
                  required
                  placeholder="Enter description here"
                ></textarea>
              </div>
            </div>
          </div>

          <div className="org-heading form_header">
            <p>Route Schedule</p>
          </div>

          <div className="stop_strip"></div>

          {selectedRoute && (
            <div>
              <h2>Stop Schedules</h2>
              {stopSchedules.map((stop, index) => (
                <div key={index}>
                  <h3>
                    Stop {stop.order}: {stop.stopName}
                  </h3>

                  <div>
                    <label>Arrival Time</label>{" "}
                    <div
                      className="mandatory_box"
                      style={{ marginTop: "16px" }}
                    >
                      <input
                        type="time"
                        name="arrivalTime"
                        value={stop.arrivalTime.slice(0, -1)}
                        onChange={(e) => handleStopChange(index, e)}
                        required
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "174px" }}>
                      <label>Departure Time</label>
                      <div
                        className="mandatory_box"
                        style={{ marginTop: "16px" }}
                      >
                        <input
                          type="time"
                          name="departureTime"
                          value={stop.departureTime.slice(0, -1)}
                          onChange={(e) => handleStopChange(index, e)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="stop_strip"></div>
          <button type="submit">{scheduleId ? "Update" : "Add"}</button>
        </form>
      </div>
    </>
  );
};

export default AddSchedule;
