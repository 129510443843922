import { Divider, Grid, InputAdornment, TextField } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select"
import './feature.css'
import React from 'react'

function FeatureSearch({featuresList,
  handleSearchChange,
  handleApiPathChange,
  handleOperationChange,
  totalFeatures
}) {

  return (
	<>

<Box className="Headercard" sx={{width:'33%'}}>
    <Grid container spacing={3}>
      <Grid item xs>
        <Box className="Headercard_Total">
        <div className="Headercard_Total_text">
        <div className="org-para">
          Total <br/>Features
        </div>
        <div >
          <span className="org-count">
          {totalFeatures} 
          </span>
        </div>
      </div>
        </Box>
      </Grid>
      </Grid>
      </Box>

<div>
		<div className="search-filter-wrapper">
			
       {/* TextField component for input */}
      <TextField
        size="small"
        id="search-access"
        label="Search"
        onChange={handleSearchChange}
        style={{background:'rgba(242, 242, 243, 1)'}}
        variant="outlined"  // Set variant to outlined
        // Add an icon to the end of the input field
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" >
             <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-search"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </InputAdornment>
          ),
        }}
        //  input label styles
        InputLabelProps={{
          style: { color: 'black' }
        }}
      />
 
	<div >
	  <Box
      sx={{ minWidth: 500 ,}} 
      display="flex"
      gap={2}
      justifyContent="space-around"
    
    >

      {/* Dropdown for filtering by routes */}
      <FormControl fullWidth size="small" style={{background:'rgba(242, 242, 243, 1)'}}>
        <InputLabel id="api-path-dropdown" style={{color:"black"}}>API Path</InputLabel>
        <Select
          labelId="api-path-dropdown"
          id="api-path-dropdown"
          label="ApI Path"
          onChange={handleApiPathChange}
         
        >  
          <MenuItem value='All'>All</MenuItem>
                  {featuresList.map((path) => (
                    <MenuItem key={path.id} value={path.api_path}>{path.api_path}</MenuItem>
                  ))}
        </Select>
      </FormControl>
      <FormControl fullWidth size="small"style={{background:'rgba(242, 242, 243, 1)'}}>
        <InputLabel id="operations-dropdown" style={{color:"black"}}>Operations</InputLabel>
        <Select
          labelId="operations-dropdown"
          id="operations"
          label="operations"
          onChange={handleOperationChange}

        >
          <MenuItem value='All'>All</MenuItem> 
            <MenuItem value="GET">GET</MenuItem>
            <MenuItem value="POST">POST</MenuItem>
            <MenuItem value="PUT">PUT</MenuItem>
            <MenuItem value="DELETE">DELETE</MenuItem> 
            <MenuItem value="PATCH">PATCH</MenuItem>   
         
        </Select>
      </FormControl>
    </Box>
	  </div>
			
		
		</div>

	</div>
	</>
  )
}

export default FeatureSearch