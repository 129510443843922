import {
  AssignmentInd,
  CorporateFare,
  DirectionsBus,
  FormatListBulleted,
  Hail,
  //Help,
  Person,
  Route,
  //Settings,
  Window,
  NotificationsNone,
  ImportExport,
  TextSnippet,
} from "@mui/icons-material";
import AirlineSeatReclineExtraIcon from "@mui/icons-material/AirlineSeatReclineExtra";

import { useUser } from "../../pages/login/UserContextPersistent";

export const navData = [
  {
    label: "Dashboard",
    path: "/overview/",
    icon: <Window />,
    requiredFeature: "",
  },
  {
    label: "Organizations",
    path: "/Organization",
    icon: <CorporateFare />,
    requiredFeature: "/api/organization",
  },
  {
    label: "Users",
    path: "/Users",
    icon: <Person />,
    requiredFeature: "/api/user",
  },

  // {
  //   label: "User Roles",
  //   path: "/Roles",
  //   icon: <AssignmentInd />,
  //   requiredFeature: "/api/role",
  // },
  {
    label: "Features",
    path: "/Feature",
    icon: <FormatListBulleted />,
    requiredFeature: "/api/feature",
  },
  {
    label: "Stops",
    path: "/Stops",
    icon: <Hail />,
    requiredFeature: "/api/stop",
  },
  {
    label: "Routes",
    path: "/Route",
    icon: <Route />,
    requiredFeature: "/api/route",
  },
  {
    label: "Drivers",
    path: "/Driver",
    icon: <AirlineSeatReclineExtraIcon />,
    requiredFeature: "/api/driver",
  },
  {
    label: "Vehicles",
    path: "/Vehicle",
    icon: <DirectionsBus />,
    requiredFeature: "/api/vehicle",
  },
  {
    label: "schedule",
    path: "/Schedule",
    icon: <ImportExport />,
    requiredFeature: "/api/schedule",
  },
  {
    label: "Alerts",
    path: "/Alerts",
    icon: <NotificationsNone />,
    requiredFeature: "/api/alert",
  },
  {
    label: "Import/Export",
    path: "/Import/Export",
    icon: <ImportExport />,
    requiredFeature: "",
  },
  {
    label: "Manage Data",
    path: "/Manage data",
    icon: <TextSnippet />,
    requiredFeature: "",
  },
];

// export const setting = [
//   { lable: "Setting", path: "/setting", icon: <Settings /> },
//   { lable: "Help", path: "/help", icon: <Help /> },
// ];


export const useFilteredNavData = (pathname) => {
  const { state } = useUser();

  const hasPermission = (requiredFeature) => {
    return state.features.some(
      (feature) =>
        feature.api_path.includes(requiredFeature) || requiredFeature === ""
    );
  };

  const filterNavData = () => {
    if (
      pathname === "/organization/add" ||
      pathname === "/AddOrganization" ||
      pathname === "/Organization"
    ) {
      return navData.filter(
        (item) =>
          [
            "Dashboard",
            "Organizations",
            "Users",
            "User Roles",
            "Features",
            "Alerts",
            "Manage Data",
          ].includes(item.label) && hasPermission(item.requiredFeature)
      );
    } else {
      return navData.filter(
        (item) =>
          !["Organizations", "Features"].includes(item.label) &&
          hasPermission(item.requiredFeature)
      );
    }
  };

  return filterNavData();

};
