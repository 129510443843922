import { useUser } from "../pages/login/UserContextPersistent";

export const usePermission = () => {
  const { state } = useUser();

  const hasPermission = (api_path, action) => {
    if (!state) {
      console.log("no state");
      return false;
    }
    console.log("state", state);
    console.log(
      state.features.some(
        (feature) =>
          (feature.api_path === "/api/user") &&
          (feature.action === "POST" )
      ),
      "post"
    );
    return state.features.some(
      (feature) =>
        (feature.api_path === api_path || api_path === "") &&
        (feature.action === action || action === "")
    );
  };

  return { hasPermission };
};
