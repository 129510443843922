import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Pagination from "../../components/pagination/Pagination";
import useDelete from "../../hooks/useDelete";
import { getSortedData, vehicle_base_url } from "../organization/utils";
import {
  fetchDrivers,
  fetchRoutes,
  fetchSites,
  deleteVehicle,
  getAllVehicles,
} from "./VehicleAPI";
import { width } from "@fortawesome/free-brands-svg-icons/fa42Group";

function createData(id, device_id, name, siteId, routes, drivers, status) {
  return { id, device_id, name, siteId, routes, drivers, status };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(32, 168, 224, 1)",
    color: "white",
    fontSize: 20,
    fontWeight: 600,
   
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 24,
  },
}));
const columns = [
  { label: "Vehicle ID", sortable: false, id: "device_id" },
  { label: "Vehicle Name", sortable: false, id: "name" },
  // { label: "Site ID", sortable: false, id: "siteId" },
  { label: "Routes", sortable: true, id: "routes" },
  // { label: "Drivers", sortable: false, id: "drivers" },
  { label: "Status", sortable: true, id: "status" },
  { label: "Actions", sortable: false, id: "actions" },
];

const getRows = (
  rows,
  getSiteIdFromList,
  getRoutesFromList,
  getDriverNameFromList
) =>
  (rows || [])?.map((item, index) =>
    createData(
      item.id,
      item.device_id,
      item.name,
      getSiteIdFromList(item.siteId),
      getRoutesFromList(item.routeId),
      getDriverNameFromList(item.driver?.id),
      item.status,
      item.actions
    )
  );

const VehicleTable = ({
  vehicleData,
  setVehicleData,
  filteredVehicleData,
  fetchEntities,
  setSiteList,
  siteList,
  setRouteList,
  routeList,
  setDriverList,
  driverList,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [slectedSchedules, setSelectedSchedules] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setitemPerPage] = useState();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { deleteEntity } = useDelete(vehicle_base_url);
  const navigateToUpdateVehicle = useNavigate();

  useEffect(() => {
    setitemPerPage(10);
  }, []);

  useEffect(() => {
    console.log("vehicleData:", vehicleData);
  }, [vehicleData]);

  useEffect(() => {
    fetchSites(setSiteList, 1);
    fetchRoutes(setRouteList, 1);
    fetchDrivers(setDriverList, 1);
  }, []);

  const getSiteIdFromList = (id) => {
    const vehicleSite = siteList.find((site) => site.id === id);
    return vehicleSite?.siteId || "";
  };
  const getRoutesFromList = (id) => {
    const vehicleRoute = routeList.find((route) => route.id === id);
    return vehicleRoute?.name || "";
  };
  const getDriverNameFromList = (id) => {
    console.log("id", id);
    const vehicleDriver = driverList.find((driver) => driver.id === id);
    console.log("vehicleDriver", vehicleDriver);
    return vehicleDriver
      ? `${vehicleDriver.first_name} ${vehicleDriver.last_name}`
      : "";
  };

  const handleColumnSort = (id) => {
    const newOrder = order === "asc" ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(id);
  };

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedVehicleId(id);
  };
  const openDeleteModal = (action) => {
    if (action === "Delete") {
      setIsDeleteModalOpen(true);
    }
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteConfirm = async () => {
    console.log("selectedVehicleId", selectedVehicleId);
    const resp = await deleteVehicle(selectedVehicleId);
    if (resp) {
      const newVehicleData = await getAllVehicles();
      console.log("newVehicleData", newVehicleData);
      setVehicleData(newVehicleData || []);
    }

    setSelectedVehicleId(null);
    setIsDeleteModalOpen(false);
  };

  const handleDeleteCancel = () => {
    setSelectedVehicleId(null);
    setIsDeleteModalOpen(false);
  };

  const handleUpdateVehicle = () => {
    navigateToUpdateVehicle("../vehicle/add", { state: selectedVehicleId });
  };

  const handleTrackVehicle = () => {
    navigateToUpdateVehicle("/device/track",{state:selectedVehicleId})
  };

  const styles = {
    header: { backgroundColor: theme.palette.primary.main, color: "white" },
  };

  //Page Previous and Next
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  //To show the total Pages
  const totalCount = parseInt(filteredVehicleData?.length);
  const totalPages = Math.ceil(totalCount / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const sortedData =
    filteredVehicleData && filteredVehicleData.length > 0
      ? filteredVehicleData.sort((a, b) => getSortedData(a, b, orderBy, order))
      : [];
  const currentData = sortedData?.slice(startIndex, endIndex);
  console.log("currentData", currentData);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                   align="justify"
                  key={column.id}
                  onClick={() => handleColumnSort(column.id)}
                >
                  {column.label}{" "}
                  {column.sortable &&
                    (orderBy === column.id ? (
                      order === "asc" ? (
                        <ArrowDropUp
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "rgba(24, 48, 89, 1)",
                          }}
                        />
                      ) : (
                        <ArrowDropDown
                          style={{
                            verticalAlign: "middle",
                            cursor: "pointer",
                            color: "rgba(24, 48, 89, 1)",
                          }}
                        />
                      )
                    ) : (
                      <ArrowDropUp
                        style={{
                          verticalAlign: "middle",
                          cursor: "pointer",
                          color: "rgba(24, 48, 89, 1)",
                        }}
                      />
                    ))}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData && currentData.length > 0 ? (
              getRows(
                currentData,
                getSiteIdFromList,
                getRoutesFromList,
                getDriverNameFromList
              )?.map(
                (row) => (
                  console.log("row", row),
                  (
                    <TableRow
                      align="justify"
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        style={{ fontSize: "16px", fontWeight: "600" }}
                      >
                        {row.device_id}
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      {/* <TableCell>{row.siteId}</TableCell> */}
                      <TableCell>{row.routes}</TableCell>
                      {/* <TableCell>{row.drivers}</TableCell> */}
                      <TableCell>
                        {row.status === "ACTIVE" && "Active"}
                        {row.status === "INACTIVE" && "Inactive"}
                        {row.status === "DEAD" && "Dead"}
                      </TableCell>
                      <TableCell >
                        <IconButton
                          aria-label="action-btn"
                          id="action-btn"
                          onClick={(event) => handleOpenMenu(event, row.id)}
                          sx={{
                            "&:hover": {
                              backgroundColor: "rgba(32, 168, 224, 1)",
                              color: "rgba(255, 255, 255, 1)", // Light blue hover effect
                            },
                          }}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                        {anchorEl && (
                          <Menu
                            id="action-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleCloseMenu}
                            MenuListProps={{
                              "aria-labelledby": "action-btn",
                            }}
                          >
                            <MenuItem
                              onClick={() =>
                                handleUpdateVehicle("Update", row.id)
                              }
                              sx={{
                                "&:hover": {
                                  backgroundColor: "rgba(32, 168, 224, 1)",
                                  color: "rgba(255, 255, 255, 1)", // Light blue hover effect
                                },
                              }}
                            >
                              <ListItemIcon>
                                <svg
                                  width="18"
                                  height="19"
                                  viewBox="0 0 18 19"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.15659 3.18652H2.59035C2.16857 3.18652 1.76405 3.35408 1.4658 3.65233C1.16755 3.95058 1 4.35509 1 4.77688V15.9093C1 16.3311 1.16755 16.7356 1.4658 17.0339C1.76405 17.3321 2.16857 17.4997 2.59035 17.4997H13.7228C14.1446 17.4997 14.5491 17.3321 14.8474 17.0339C15.1456 16.7356 15.3132 16.3311 15.3132 15.9093V10.3431"
                                    stroke="#1F242E"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M14.1189 1.99406C14.4352 1.67772 14.8643 1.5 15.3116 1.5C15.759 1.5 16.1881 1.67772 16.5044 1.99406C16.8208 2.3104 16.9985 2.73945 16.9985 3.18682C16.9985 3.6342 16.8208 4.06325 16.5044 4.37959L8.95024 11.9338L5.76953 12.7289L6.56471 9.54824L14.1189 1.99406Z"
                                    stroke="#1F242E"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </ListItemIcon>
                              Update
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                handleTrackVehicle("Track", row.id)
                              }
                              sx={{
                                "&:hover": {
                                  backgroundColor: "rgba(32, 168, 224, 1)",
                                  color: "rgba(255, 255, 255, 1)", // Light blue hover effect
                                },
                              }}
                            >
                              <ListItemIcon>
                                {" "}
                                <svg
                                  width="16"
                                  height="17"
                                  viewBox="0 0 16 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clip-path="url(#clip0_1408_4150)">
                                    <path
                                      d="M8 16.5C8 16.5 14 10.814 14 6.5C14 4.9087 13.3679 3.38258 12.2426 2.25736C11.1174 1.13214 9.5913 0.5 8 0.5C6.4087 0.5 4.88258 1.13214 3.75736 2.25736C2.63214 3.38258 2 4.9087 2 6.5C2 10.814 8 16.5 8 16.5ZM8 9.5C7.20435 9.5 6.44129 9.18393 5.87868 8.62132C5.31607 8.05871 5 7.29565 5 6.5C5 5.70435 5.31607 4.94129 5.87868 4.37868C6.44129 3.81607 7.20435 3.5 8 3.5C8.79565 3.5 9.55871 3.81607 10.1213 4.37868C10.6839 4.94129 11 5.70435 11 6.5C11 7.29565 10.6839 8.05871 10.1213 8.62132C9.55871 9.18393 8.79565 9.5 8 9.5Z"
                                      fill="#1F242E"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1408_4150">
                                      <rect
                                        width="16"
                                        height="16"
                                        fill="white"
                                        transform="translate(0 0.5)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </ListItemIcon>
                              Track
                            </MenuItem>
                            <MenuItem 
                              onClick={() => openDeleteModal("Delete", row.id)}
                              sx={{
                                "&:hover": {
                                  backgroundColor: "rgba(32, 168, 224, 1)",color:'white', // Light blue hover effect
                                },
                              }}
                            >
                              <ListItemIcon>
                                <svg
                                  width="18"
                                  height="19"
                                  viewBox="0 0 18 19"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1 4.40039H2.77778H17"
                                    stroke="#1F242E"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M15.2218 4.4V16.3C15.2218 16.7509 15.0345 17.1833 14.7011 17.5021C14.3677 17.8209 13.9155 18 13.444 18H4.55512C4.08363 18 3.63144 17.8209 3.29804 17.5021C2.96464 17.1833 2.77734 16.7509 2.77734 16.3V4.4M5.44401 4.4V2.7C5.44401 2.24913 5.63131 1.81673 5.96471 1.49792C6.29811 1.17911 6.75029 1 7.22179 1H10.7773C11.2488 1 11.701 1.17911 12.0344 1.49792C12.3678 1.81673 12.5551 2.24913 12.5551 2.7V4.4"
                                    stroke="#1F242E"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M7.22266 8.65039V13.7504"
                                    stroke="#1F242E"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M10.7773 8.65039V13.7504"
                                    stroke="#1F242E"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </ListItemIcon>
                              Delete
                            </MenuItem >
                          </Menu>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                )
              )
            ) : (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={columns.length}
                  style={styles.header}
                >
                  No records found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />

      <Dialog
        open={isDeleteModalOpen}
        onClose={handleDeleteCancel}
        PaperProps={{
          style: {
            backgroundColor: "rgba(255, 255, 255, 1)",
            width: "594px",
            height: "294px",
            maxWidth: "600px",
          },
        }}
      >
        <DialogTitle
          style={{ fontWeight: "bolder", height: "80px", marginTop: "5px" }}
        >
          <span
            style={{
              color: "#EE3B2B",
              width: "514px",
              fontSize: "32px",
              fontWeight: "600",
            }}
          >
            Delete Vehicle!
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              color: "#1F242E",
              fontSize: "24px",
              fontWeight: "400",
              marginTop: "-6px",
            }}
          >
            Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <div style={{ marginRight: "115px", marginBottom: "40px" }}>
          <DialogActions>
            <button
              onClick={handleDeleteCancel}
              color="primary"
              style={{
                padding: "10px 40px",
                fontWeight: "bold",
                textTransform: "none",
                background: "#F2F2F3",
              }}
              className="customButton_add"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteConfirm}
              color="primary"
              variant="contained"
              autoFocus
              className="customButton_add"
              style={{
                padding: "10px 40px",
                fontWeight: "bold",
                textTransform: "none",
                background: "#EE3B2B",
                color: "white",
              }}
            >
              Delete
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default VehicleTable;
